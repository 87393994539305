import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

class PublicRoute extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            auth: false,
        }
    }

    render() {
        const {
            component: Component,
            auth,
            getUserInfo,
            restricted,
            ...rest
        } = this.props
        return (
            <Route
                {...rest}
                render={(props) =>
                    auth && restricted ? (
                        <Redirect to="/" />
                    ) : (
                        <Component getUserInfo={getUserInfo} {...props} />
                    )
                }
            />
        )
    }
}

export default PublicRoute
