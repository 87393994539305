import Timer from "react-compound-timer";
import { Col } from "react-bootstrap";
import React from "react";

const TimeCountDown = (props) => {
  const { col, time } = props;
  return (
    <Col lg={col} xs={12} className={"text-center mb-4"}>
      <h4
        className={
          (props.timerStart
            ? "border-success text-success"
            : "border-danger text-danger") +
          " border rounded d-inline-block w-100 p-2"
        }
      >
        <Timer
          checkpoints={[
            {
              time: 0,
              callback: () => props.timerEnded,
            },
          ]}
          initialTime={time * 1000}
          direction="backward"
        >
          <Timer.Days />:
          <Timer.Hours />:
          <Timer.Minutes />:
          <Timer.Seconds />
        </Timer>
      </h4>
    </Col>
  );
};
export default TimeCountDown;
