import { Button, Col, Row } from "react-bootstrap";
import React from "react";
import TabBody from "./tabDetails";
class Tabs extends React.Component {
  state = {
    active: 1,
  };

  render() {
    const { active } = this.state;
    return (
      <div>
        <Row className={"mt-4 tab-btn"}>
          <Button
            onClick={() => this.setState({ active: 1 })}
            className={"btn-dark border-0 " + (active === 1 ? "bg-dark-3" : "")}
          >
            User Reports
          </Button>
          <Button
            onClick={() => this.setState({ active: 2 })}
            className={"btn-dark border-0 " + (active === 2 ? "bg-dark-3" : "")}
          >
            User Wallet
          </Button>
          <Button
            onClick={() => this.setState({ active: 3 })}
            className={"btn-dark border-0 " + (active === 3 ? "bg-dark-3" : "")}
          >
            User Sessions
          </Button>
          <Button
            onClick={() => this.setState({ active: 4 })}
            className={"btn-dark border-0 " + (active === 4 ? "bg-dark-3" : "")}
          >
            Invited Users
          </Button>
        </Row>
        <Row>
          <TabBody active={active} id={this.props.id} />
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </div>
    );
  }
}
export default Tabs;
