import React, { Component } from 'react'
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
export default class ResetForm extends Component {
    render() {
        return (
            <div className={'reset  bg-light'}>
                <Form>
                    <Form.Label>Enter Email</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={'bg-dark txt-light rounded-0'}
                            >
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            type={'Email'}
                            placeholder={'Enter Email'}
                            className={'bg-light txt-light rounded-0'}
                            aria-label="Amount (to the nearest dollar)"
                        />
                    </InputGroup>

                    <Button
                        size="sm"
                        className={'bg-light-2 border mt-3 btn-shaped'}
                        block="block"
                    >
                        Reset
                    </Button>
                    <Button
                        onClick={this.props.loginHandler}
                        size="sm"
                        className={'bg-light border mt-3 btn-shaped'}
                        block="block"
                    >
                        Login
                    </Button>
                </Form>
            </div>
        )
    }
}
