import React, { Component } from "react";
import {
  Row,
  Container,
  Col,
  Form,
  Spinner,
  FormControl,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import DataTableBonusReport from "./dataTableBonusReport";
import { bonusCSVApi, bonusReportApi } from "../../../utils";

class BonusReport extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    )
      .add(1, "days")
      .subtract(1, "seconds");
    this.state = {
      start: start,
      end: end,
      loading: true,
      page: 1,
      maxPerPage: 25,
      totalPages: 1,
      totalItems: 1,
      data: [],
      user: "",
    };
  }
  applyCallback = (startDate, endDate) => {
    this.setState(
      {
        start: startDate,
        end: endDate,
      },
      () => {
        this.getData();
      }
    );
    this.setState(
      {
        start: startDate,
        end: endDate,
      },
      () => {
        this.getData();
      }
    );
  };
  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      this.getData();
    });
  };
  handleMaxPerPageChange = (event) => {
    this.setState({ maxPerPage: event.target.value, page: 1 }, () => {
      this.getData();
    });
  };
  componentDidMount() {
    this.getData();
  }
  csvDownload = () => {
    const { start, user, end } = this.state;
    bonusCSVApi(
      moment(start).format("YYYY-MM-DD HH:mm:ss"),
      moment(end).format("YYYY-MM-DD HH:mm:ss"),
      user
    ).then();
  };
  getData = () => {
    const { start, end, page, maxPerPage, user } = this.state;
    this.setState({ loading: true });
    bonusReportApi(
      page,
      maxPerPage,
      moment(start).format("YYYY-MM-DD HH:mm:ss"),
      moment(end).format("YYYY-MM-DD HH:mm:ss"),
      user
    ).then((res) => {
      this.setState({
        loading: false,
        data: res.reports,
        maxPerPage: res.items_per_page,
        page: res.page,
        totalPages: res.total_pages,
        totalItems: res.total_items,
      });
    });
  };
  render() {
    const {
      data,
      loading,
      start,
      end,
      page,
      maxPerPage,
      totalItems,
      user,
    } = this.state;
    let ranges = {
      Daily: [moment(Date.now()), moment(end)],
      Weekly: [moment(Date.now()).subtract(1, "weeks"), moment(end)],
      Monthly: [moment(Date.now()).subtract(1, "months"), moment(end)],
      Yearly: [moment(Date.now()).subtract(1, "years"), moment(end)],
    };
    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };

    return (
      <Container fluid>
        <Row className={"panel-rows"}>
          <h5>Bonus Report</h5>
        </Row>
        <div>
          <Row>
            <Col className={"mt-2"} lg={3} xs={6}>
              <DateTimeRangeContainer
                ranges={ranges}
                start={start}
                end={end}
                local={local}
                applyCallback={this.applyCallback}
              >
                <FormControl
                  className={"text-center"}
                  id="formControlsTextB"
                  type="text"
                  label="Text"
                  value={
                    moment(start).format("YYYY-MM-DD") +
                    " -> " +
                    moment(end).format("YYYY-MM-DD")
                  }
                />
              </DateTimeRangeContainer>
            </Col>
            <Col className={"mt-2"} lg={3} xs={6}>
              <FormControl
                id="UserEmail"
                type="text"
                label="User Email"
                placeholder={"User Email"}
                value={user}
                onChange={(e) => {
                  this.setState({ user: e.target.value }, () => this.getData());
                }}
              />
            </Col>
            <Col className={"mt-2"} lg={3} xs={6}>
              <Form.Group>
                <Form.Control
                  value={maxPerPage}
                  onChange={this.handleMaxPerPageChange}
                  as="select"
                >
                  <option value={25}>View 25 Per Page</option>
                  <option value={50}>View 50 Per Page</option>
                  <option value={100}>View 100 Per Page</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <FontAwesomeIcon
                style={{
                  fontSize: "2.4em",
                  color: "green",
                  cursor: "pointer",
                }}
                className={"mt-2"}
                onClick={this.csvDownload}
                icon={faFileCsv}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {!loading ? (
                <DataTableBonusReport
                  headers={[
                    "U-ID",
                    "User",
                    "Invited User",
                    "Bonus Amount (Coin)",
                    "Bonus Amount ($)",
                    "Time Stamp",
                  ]}
                  data={data}
                />
              ) : (
                <div
                  className={
                    "my-5 py-5 d-flex justify-content-center align-content-center"
                  }
                >
                  <Spinner size={"lg"} animation="grow" variant="light" />
                </div>
              )}
            </Col>
          </Row>
          <Row className={"d-flex justify-content-center align-items-center"}>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              onChange={this.handlePageChange}
              activePage={page}
              itemsCountPerPage={maxPerPage}
              totalItemsCount={totalItems}
              pageRangeDisplayed={10}
            />
          </Row>
        </div>
      </Container>
    );
  }
}

export default BonusReport;
