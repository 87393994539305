import React, { Component } from 'react'
import { Row, Col, Container, Button, Form } from 'react-bootstrap'
import { faDotCircle, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import Barcode from '../../assets/png/barcde.jpg'
import GoolgeAuth from '../../assets/png/google.png'
import Sms from '../../assets/png/sms.png'
import Id from '../../assets/png/Identify.png'
import PhoneInput from 'react-phone-input-2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Security extends Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: '',
        }
    }
    render() {
        return (
            <Container className={'p-5'}>
                <Col xs={12} className={'seciurty-panel purple-border'}>
                    <h6 className={'text-light mt-2'}>Change Password</h6>
                    <Row className={'purple-border m-1 pt-3'}>
                        <Col xs={2}></Col>
                        <Col xs={2}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    Old Password
                                </Form.Label>
                                <Form.Control
                                    className={'p-0 rounded-0'}
                                    type="password"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    New Password
                                </Form.Label>
                                <Form.Control
                                    className={'p-0 rounded-0'}
                                    type="password"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    Confirm Password
                                </Form.Label>
                                <Form.Control
                                    className={'p-0 rounded-0'}
                                    type="password"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}></Col>
                        <Col xs={2}>
                            <Button
                                size="sm"
                                className={'bg-light border mt-3'}
                                block
                            >
                                Update Settings
                            </Button>
                        </Col>
                    </Row>
                    <Row className={'purple-border m-1 pt-3 mb-2 pb-2'}>
                        <Col xs={1} className={'p-1'}>
                            <img
                                alt="GoolgeAuth"
                                className={'img-fluid'}
                                src={GoolgeAuth}
                            />
                        </Col>
                        <Col xs={1} className={'p-1'}>
                            <img
                                alt="Barcode"
                                className={'img-fluid'}
                                src={Barcode}
                            />
                        </Col>
                        <Col xs={6}>
                            <h6 className={'text-light text-center'}>
                                Scan QR code or put your security code in Google
                                Authenticator
                            </h6>
                            <div className={'text-center'}>
                                <Button
                                    size="sm"
                                    className={'bg-light border mt-1'}
                                >
                                    HD537382DH
                                </Button>
                            </div>
                        </Col>
                        <Col xs={2}>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label
                                    className={'text-center text-light d-block'}
                                >
                                    Enter GA Code
                                </Form.Label>
                                <Form.Control
                                    className={'p-0 rounded-0'}
                                    type="password"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Button
                                size="sm"
                                className={'bg-light border mt-4'}
                                block
                            >
                                Verify Code
                            </Button>
                        </Col>
                    </Row>
                    <Row className={'purple-border m-1 pt-3 mb-2 pb-2'}>
                        <Col xs={1} className={'p-1'}>
                            <img
                                alt="GoolgeAuth"
                                className={'img-fluid'}
                                src={GoolgeAuth}
                            />
                        </Col>

                        <Col xs={9} className={'d-flex align-items-center'}>
                            <h6 className={'text-light text-center ml-2'}>
                                Used for Login, Send, Exchange and Withdrawals
                            </h6>
                        </Col>

                        <Col xs={2}>
                            <Button
                                size="sm"
                                className={'bg-light border mt-4'}
                                block
                            >
                                Disable
                            </Button>
                        </Col>
                    </Row>
                    <Row className={'purple-border m-1 pt-3 mb-2 pb-2'}>
                        <Col xs={1} className={'p-1 d-flex align-items-center'}>
                            <img alt="Sms" className={'img-fluid'} src={Sms} />
                        </Col>

                        <Col xs={6} className={'d-flex align-items-center'}>
                            <h6 className={'text-light text-center ml-2'}>
                                Used for Login, Send, Exchange and Withdrawals
                            </h6>
                        </Col>
                        <Col xs={5}>
                            <Row>
                                <Col xs={8}>
                                    <label
                                        className={
                                            'text-center text-light d-block'
                                        }
                                    >
                                        Put Your Number
                                    </label>
                                    <PhoneInput
                                        inputStyle={{
                                            width: 'auto',
                                            fontSize: 'inherit',
                                            left: 32,
                                        }}
                                        inputClass={'p-0 rounded-0'}
                                        buttonStyle={{ left: -10 }}
                                        buttonClass={'rounded-0 bg-light'}
                                        country={'ir'}
                                        value={this.state.phone}
                                        onChange={(phone) =>
                                            this.setState({ phone })
                                        }
                                    />
                                </Col>
                                <Col xs={4}>
                                    <Button
                                        size="sm"
                                        className={'bg-light border mt-4'}
                                        block
                                    >
                                        send
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={1} />
                                <Col xs={7} className={'pr-4'}>
                                    <Form.Group controlId="formGroup">
                                        <Form.Label
                                            className={
                                                'text-center text-light d-block'
                                            }
                                        >
                                            Enter Sms Code
                                        </Form.Label>
                                        <Form.Control
                                            className={'p-0 rounded-0'}
                                            type="text"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={4}>
                                    <Button
                                        size="sm"
                                        className={'bg-light border mt-4'}
                                        block
                                    >
                                        Verify Code
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={'purple-border m-1 pt-3 mb-2 pb-2'}>
                        <Col xs={1} className={'p-1'}>
                            <img
                                alt={'Sms'}
                                className={'img-fluid'}
                                src={Sms}
                            />
                        </Col>

                        <Col xs={9} className={'d-flex align-items-center'}>
                            <h6 className={'text-light text-center ml-2'}>
                                Used for Login, Send, Exchange and Withdrawals
                            </h6>
                        </Col>

                        <Col xs={2}>
                            <Button
                                size="sm"
                                className={'bg-light border mt-4'}
                                block
                            >
                                Disable
                            </Button>
                        </Col>
                    </Row>
                    <Row className={'purple-border m-1 pt-3 mb-2 pb-2'}>
                        <Col xs={1} className={'p-1'}>
                            <img alt={'Sms'} className={'img-fluid'} src={Id} />
                        </Col>

                        <Col xs={6} className={'align-items-center'}>
                            <h6 className={'text-light ml-2'}>
                                <FontAwesomeIcon
                                    icon={faDotCircle}
                                    className={'mr-1'}
                                />
                                Proof of Identity (Government issued ID or
                                Passport)
                            </h6>
                            <h6 className={'text-light ml-2'}>
                                <FontAwesomeIcon
                                    icon={faDotCircle}
                                    className={'mr-1'}
                                />
                                Selfie of holding clearly your identity document
                                in your hands
                            </h6>
                        </Col>

                        <Col xs={5}>
                            <Row>
                                <Col xs={4}>
                                    <div
                                        className={
                                            'bordered file-upload text-center'
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faFileUpload}
                                            className={'mr-1 text-light'}
                                        />
                                    </div>
                                </Col>
                                <Col xs={8}>
                                    <Button
                                        size="sm"
                                        className={'bg-light border mt-4'}
                                        block
                                    >
                                        Disable
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Container>
        )
    }
}
