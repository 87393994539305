import React, { Component } from "react";
import { Row, Col, Container, Button, Form, Spinner } from "react-bootstrap";
import Rodal from "rodal";
import { rolesListApi, createUserApi } from "../../utils";
export default class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userRoles: [],
      createUser: {},
      rePassword: "",
      loading: false,
      err: "",
      msg: "",
    };
    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleRePasswordChange = this.handleRePasswordChange.bind(this);
  }
  componentDidMount() {
    rolesListApi(1, 100).then((res) => {
      this.setState({ userRoles: res.roles });
    });
  }
  handleFullNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let createUser = Object.assign({}, prevState.createUser);
      createUser.full_name = value;
      return { createUser };
    });
  }
  handleEmailChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let createUser = Object.assign({}, prevState.createUser);
      createUser.email = value;
      createUser.roles = this.props.user ? [] : ["ROLE_ADMIN"];
      return { createUser };
    });
  }
  handleStatusChange(event) {
    const { value } = event.target;

    this.setState((prevState) => {
      let createUser = Object.assign({}, prevState.createUser);
      createUser.enabled = value;
      return { createUser };
    });
  }

  handlePasswordChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let createUser = Object.assign({}, prevState.createUser);
      createUser.password = value;
      return { createUser };
    });
  }
  handleUserNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let createUser = Object.assign({}, prevState.createUser);
      createUser.username = value;
      return { createUser };
    });
  }
  handleRePasswordChange(event) {
    this.setState({ rePassword: event.target.value });
  }
  handleCreateUser() {
    this.setState({ loading: true });
    createUserApi(this.state.createUser).then((res) => {
      res.code !== 400
        ? this.setState(
            {
              msg: "User Has Been Created",
              loading: false,
            },
            () => {
              this.props.reload();
            }
          )
        : this.setState({
            msg: res.res.error.errors[0].message,
            loading: false,
          });
    });
  }
  render() {
    const { loading, createUser, visible, rePassword } = this.state;

    return (
      <span class={"ml-2"}>
        <Rodal
          duration={500}
          animation={"door"}
          visible={visible}
          onClose={() => {
            this.setState({
              visible: false,
              msg: "",
            });
          }}
        >
          <Container className={"bg-dark p-4"}>
            <h6
              className={"text-light mb-0 border-bottom border-secondary pb-1"}
            >
              User Information
            </h6>
            <Form autocomplete="off" className={"mt-3 mb-0"}>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Owner's Display Name :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    value={createUser.full_name}
                    onChange={this.handleFullNameChange}
                    className={"rounded-0"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Username :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    value={createUser.username}
                    onChange={this.handleUserNameChange}
                    className={"rounded-0"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Owner's Email :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    autocomplete="email"
                    value={createUser.email}
                    onChange={this.handleEmailChange}
                    className={"rounded-0"}
                    type={"email"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Password :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    autocomplete="new-password"
                    value={createUser.password}
                    onChange={this.handlePasswordChange}
                    className={"rounded-0"}
                    type={"password"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Confirm Password :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    value={rePassword}
                    onChange={this.handleRePasswordChange}
                    className={"rounded-0"}
                    type={"password"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Status :
                </Form.Label>
                <Col sm="3">
                  <Form.Control
                    onChange={this.handleStatusChange}
                    size="sm"
                    as="select"
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Form>
            <Row>
              <Col xs={12} className={"text-right"}>
                <Button
                  onClick={() => {
                    this.setState({
                      visible: false,
                      msg: "",
                    });
                  }}
                  size="sm"
                  className={
                    "bg-light border mb-4 mr-5 d-sm-none btn-shaped px-3"
                  }
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    rePassword !== createUser.password ||
                    createUser.email.lenght < 5 ||
                    createUser.username.lenght < 5 ||
                    createUser.full_name.lenght < 5
                  }
                  size="sm"
                  className={"bg-light border mb-4 btn-shaped px-5"}
                  onClick={this.handleCreateUser}
                >
                  {loading ? (
                    <Spinner size={"sm"} animation="border" variant="light" />
                  ) : (
                    "Create"
                  )}
                </Button>
              </Col>
              <div>
                <p className={"pl-3 txt-orange"}>{this.state.msg}</p>
              </div>
            </Row>
          </Container>
        </Rodal>

        <Button
          onClick={() => {
            this.setState({ visible: true });
          }}
          size="sm"
          className={"bg-light border mb-4 btn-shaped px-3"}
        >
          {this.props.user ? "Create User" : "Create Member"}
        </Button>
      </span>
    );
  }
}
