import React, { Component } from "react";
import { Row } from "react-bootstrap";
import UserAttribute from "./components/userAttribute";
import FinancialBrief from "./components/financialBrief";
import Tabs from "./components/tabs";
class UserProfile extends Component {
  render() {
    return (
      <div className={"p-5 bg-light mt-4 rounded"}>
        <Row>
          <UserAttribute
            setEmail={this.setEmail}
            id={this.props.match.params.id}
          />
          <FinancialBrief id={this.props.match.params.id} />
        </Row>
        <Tabs id={this.props.match.params.id} />
      </div>
    );
  }
}

export default UserProfile;
