import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import BarChart from "../../components/barChart";
import { totalReveneueEarnedByExchangeApi } from "../../utils";
export default class TotalReveneueEarnedByExchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      data: [],
      days: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 10000);
  }
  getData() {
    totalReveneueEarnedByExchangeApi().then((res) => {
      let data = [];
      for (let i = 0; i < 7; i++) {
        data.push({ x: i, y: Number(res.main.week[i].amount) });
      }
      this.setState({
        data,
        total: res.main.total.amount,
        days: res.main.week,
        loading: false,
      });
    });
  }
  render() {
    const { total, data, days, loading } = this.state;
    if (loading)
      return (
        <div
          style={{
            height: 150,
            width: "33%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Spinner animation="grow" variant="light" />
        </div>
      );
    return (
      <BarChart
        price={true}
        title={"Total Revenue Earned By Exchange"}
        days={days}
        count={total}
        data={data}
      />
    );
  }
}
