import React, { Component } from "react";
import QRCode from "qrcode.react";
import { TwoFaLoginApi, TwoFaSendCodeApi } from "../utils";
import { InputGroup, FormControl, Alert, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
class TwoFa extends Component {
  state = {
    data: {},
    code: "",
    loading: true,
    err: false,
    message: "",
    password:""
  };
  componentDidMount() {
    this.getData();
  }
  handleChangePassword=(e)=>{
    const { value } = e.target;
    this.setState({password:value})

  }
  handleChange = (e) => {
    const { value } = e.target;
    this.setState({ code: value }, () => {
      if (value.length === 6) {
        this.setState({ loading: true });
        TwoFaLoginApi(value,this.state.password).then((res) => {
          if (res !== 400) {
            if (res.token) {
              this.setState({ loading: false }, () => {
                window.location.reload();
              });
            } else {
              this.setState({
                err: true,
                loading: false,
                message: "Invalid Code",
                code: "",
              });
            }
          } else {
            this.setState({
              err: true,
              loading: false,
              message: "Invalid Code",
              code: "",
            });
          }
        });
      }
    });
  };
  getData = () => {
    TwoFaSendCodeApi("", "enable").then((data) => {
      this.setState({ data: data.res, loading: false });
    });
  };
  render() {
    const { data, code, loading,password } = this.state;
    return (
      <div className={"text-center  pt-5"}>
        <h2 className={"mt-0 mb-5 txt-light"}>Set up Google Authenticator</h2>
        <QRCode
          value={`otpauth://totp/${data.issuer}:${data.account} 
            ?secret=${data.secret}&issuer=${data.issuer}&algorithm=SHA1&digits=6&period=30`}
          size={350}
          bgColor={"#fff"}
          fgColor={"#000"}
          level={"L"}
          includeMargin={true}
          renderAs={"canvas"}
        />
        <div>
          <h4 className={"text-center txt-light mt-4"}>{data.secret}</h4>
        </div>
        <div style={{ width: 250 }} className={"d-inline-block mt-4"}>
        <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="2fa">Enter Pssword</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="password"
              value={password}
              disabled={loading}
              onChange={this.handleChangePassword}
              className={"text-center"}
              aria-label="password"
              aria-describedby="password"
            />
          </InputGroup>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="2fa">Enter 2Fa Code</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              value={code}
              disabled={loading || password.length<4}
              onChange={this.handleChange}
              className={"text-center"}
              aria-label="2fa"
              aria-describedby="2fa"
            />
          </InputGroup>
          {this.state.err ? (
            <Alert className={"mt-2"} variant="danger">
              {this.state.message}
            </Alert>
          ) : null}
        </div>
        <div className={"mt-3"}>
          <Button
            onClick={() => {
              localStorage.removeItem("token");
              window.location.reload();
            }}
            size="sm"
            className={"bg-light border mb-2 mt-3 btn-shaped px-5"}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            LogOut
          </Button>
        </div>
      </div>
    );
  }
}

export default TwoFa;
