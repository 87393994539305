import React, { Component } from "react";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteRole from "./deleteRole";

export default class RolesListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.cheange = this.cheange.bind(this);
    this.rodalUnvisible = this.rodalUnvisible.bind(this);
  }
  cheange() {
    const { roles } = this.props;
    this.props.showModify(roles);
  }
  rodalUnvisible = () => {
    this.setState({ visible: false });
  };

  render() {
    const { roles, removeRole, editRole } = this.props;
    return (
      <tr>
        <td>{roles.role}</td>
        <td>{roles.description}</td>
        {editRole ? (
          <td>
            <Button
              onClick={this.cheange}
              className={"border-0 p-0 px-1"}
              variant="outline-light"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </td>
        ) : null}
        {removeRole ? (
          <td>
            {this.state.visible ? (
              <DeleteRole
                reloadData={this.props.reloadData}
                rodalUnvisible={this.rodalUnvisible}
                roles={roles}
              />
            ) : null}
            <Button
              onClick={() => {
                this.setState({ visible: true });
              }}
              className={"border-0 p-0 px-1"}
              variant="outline-light"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        ) : null}
      </tr>
    );
  }
}
