import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import NotifIcons from "../notifIcons";
import {
  faBars,
  faBell,
  faEnvelope,
  faTachometerAlt,
  faShoppingCart,
  faCogs,
  faUsersCog,
  faTasks,
  faSignOutAlt,
  faCode,
  faGift,
  faChartArea,
  faChartBar,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";

import Avatar from "../avatar";
import Logo from "./../../assets/png/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
export default class NavSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuToggle: false,
      visible: false,
      mNavVis: false,
      user: props.user,
      setting: false,
      report: false,
    };
  }
  show() {
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  render() {
    const { permissions } = this.props.user;
    return (
      <Container
        fluid="fluid"
        className={this.state.menuToggle ? "open-navigation" : null}
      >
        <Row>
          <Col
            lg={this.state.menuToggle ? 1 : 2}
            xs={1}
            className={"animated"}
          />
          <Col
            xs={1}
            lg={this.state.menuToggle ? 1 : 2}
            className={"animated no-padding left-side"}
          >
            <div className={"logo bg-light"}>
              <img alt={"logo"} src={Logo} />
              <h1 className={"no-margin d-none d-lg-inline"}>Xapa Dashboard</h1>
            </div>
            <div className={"side-nav bg-light"}>
              <h6 className={"no-margin navigations"}>
                <span className={"d-none d-lg-inline"}>NAVIGATIONS</span>
                <span className={"d-lg-none"}>N</span>{" "}
              </h6>
              <ul className={"no-padding"}>
                <li
                  className={
                    "animated" + (this.props.path === "/" ? " active" : "")
                  }
                >
                  <Link to="/" className={"txt-light"}>
                    <FontAwesomeIcon
                      icon={faTachometerAlt}
                      style={{
                        marginRight: 20,
                      }}
                      className={"txt-light"}
                    />
                    <span className={"d-none d-lg-inline"}>Dashboards</span>
                  </Link>
                </li>
                {permissions.indexOf("ADMIN_TRANSACTION_HISTORY") !== -1 ? (
                  <li
                    className={
                      "animated" +
                      (this.props.path === "/history" ? " active" : "")
                    }
                  >
                    <Link to="/history" className={"txt-light"}>
                      <FontAwesomeIcon
                        icon={faShoppingCart}
                        style={{
                          marginRight: 20,
                        }}
                        className={"txt-light"}
                      />
                      <span className={"d-none d-lg-inline"}>History</span>
                    </Link>
                  </li>
                ) : null}
                {permissions.indexOf("ADMIN_USERS_LIST") !== -1 ? (
                  <li
                    className={
                      "animated" +
                      (this.props.path === "/usermanagement" ? " active" : "")
                    }
                  >
                    <Link to="/usermanagement" className={"txt-light"}>
                      <FontAwesomeIcon
                        icon={faUsersCog}
                        style={{
                          marginRight: 20,
                        }}
                        className={"txt-light"}
                      />
                      <span className={"d-none d-lg-inline"}>
                        User Management
                      </span>
                    </Link>
                  </li>
                ) : null}
                {permissions.indexOf("ADMIN_USERS_LIST") !== -1 ||
                permissions.indexOf("ADMIN_ROLES_LIST") !== -1 ||
                permissions.indexOf("ADMIN_APP_VERSIONS_LIST") !== -1 ? (
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ setting: !this.state.setting });
                    }}
                    className={"animated"}
                  >
                    <Link
                      style={{ pointerEvents: "none" }}
                      href="/"
                      className={"txt-light"}
                    >
                      <FontAwesomeIcon
                        icon={faCogs}
                        style={{
                          marginRight: 20,
                        }}
                        className={"txt-light"}
                      />
                      <span className={"d-none d-lg-inline"}>Setting </span>
                    </Link>
                    <ul
                      className={
                        this.props.path === "/security" ||
                        this.props.path === "/members" ||
                        this.props.path === "/roles" ||
                        this.props.path === "/changelog" ||
                        this.props.path === "/promotion" ||
                        this.props.path === "/tokens" ||
                        this.state.setting
                          ? "d-block pl-1"
                          : "pl-1"
                      }
                    >
                      {permissions.indexOf("ADMIN_USERS_LIST") !== -1 ? (
                        <li
                          className={
                            "animated" +
                            (this.props.path === "/members" ? " active" : "")
                          }
                        >
                          <Link to="/members" className={"txt-light"}>
                            <FontAwesomeIcon
                              icon={faUsersCog}
                              style={{
                                marginRight: 20,
                              }}
                              className={"txt-light"}
                            />
                            <span className={"d-none d-lg-inline"}>
                              Members{" "}
                            </span>
                          </Link>
                        </li>
                      ) : null}
                      {permissions.indexOf("ADMIN_ROLES_LIST") !== -1 ? (
                        <li
                          className={
                            "animated" +
                            (this.props.path === "/roles" ? " active" : "")
                          }
                        >
                          <Link to="/roles" className={"txt-light"}>
                            <FontAwesomeIcon
                              icon={faTasks}
                              style={{
                                marginRight: 20,
                              }}
                              className={"txt-light"}
                            />
                            <span className={"d-none d-lg-inline"}>
                              Roles & Premissions{" "}
                            </span>
                          </Link>
                        </li>
                      ) : null}
                      {permissions.indexOf("ADMIN_APP_VERSIONS_LIST") !== -1 ? (
                        <li
                          className={
                            "animated" +
                            (this.props.path === "/changelog" ? " active" : "")
                          }
                        >
                          <Link to="/changelog" className={"txt-light"}>
                            <FontAwesomeIcon
                              icon={faCode}
                              style={{
                                marginRight: 20,
                              }}
                              className={"txt-light"}
                            />
                            <span className={"d-none d-lg-inline"}>
                              ChangeLog
                            </span>
                          </Link>
                        </li>
                      ) : null}
                      {permissions.indexOf("ADMIN_SHOW_PROMOTION") !== -1 ? (
                        <li
                          className={
                            "animated" +
                            (this.props.path === "/promotion" ? " active" : "")
                          }
                        >
                          <Link to="/promotion" className={"txt-light"}>
                            <FontAwesomeIcon
                              icon={faGift}
                              style={{
                                marginRight: 20,
                              }}
                              className={"txt-light"}
                            />
                            <span className={"d-none d-lg-inline"}>
                              Promotion
                            </span>
                          </Link>
                        </li>
                      ) : null}
                      {permissions.indexOf("ADMIN_CREATE_TOKEN") !== -1 ? (
                        <li
                          className={
                            "animated" +
                            (this.props.path === "/tokens" ? " active" : "")
                          }
                        >
                          <Link to="/tokens" className={"txt-light"}>
                            <FontAwesomeIcon
                              icon={faCoins}
                              style={{
                                marginRight: 20,
                              }}
                              className={"txt-light"}
                            />
                            <span className={"d-none d-lg-inline"}>Tokens</span>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}
                {(permissions.indexOf("ADMIN_TRANSACTION_ACCOUNTING") !== -1 &&
                  permissions.indexOf("ADMIN_USER_ACCOUNTING") !== -1) ||
                permissions.indexOf("ADMIN_BONUS_REPORT") !== -1 ? (
                  <li
                    onClick={() => {
                      this.setState({ report: !this.state.report });
                    }}
                    style={{ cursor: "pointer" }}
                    className={"animated"}
                  >
                    <Link
                      style={{ pointerEvents: "none" }}
                      href="/"
                      className={"txt-light"}
                    >
                      <FontAwesomeIcon
                        icon={faChartArea}
                        style={{
                          marginRight: 20,
                        }}
                        className={"txt-light"}
                      />
                      <span className={"d-none d-lg-inline"}>Report </span>
                    </Link>
                    <ul
                      className={
                        this.props.path === "/xapareport" ||
                        this.props.path === "/bonusreport" ||
                        this.props.path === "/exchangereport" ||
                        this.state.report
                          ? "d-block pl-1"
                          : "pl-1"
                      }
                    >
                      {permissions.indexOf("ADMIN_TRANSACTION_ACCOUNTING") !==
                        -1 &&
                      permissions.indexOf("ADMIN_USER_ACCOUNTING") !== -1 ? (
                        <li
                          className={
                            "animated" +
                            (this.props.path === "/xapareport" ? " active" : "")
                          }
                        >
                          <Link to="/xapareport" className={"txt-light"}>
                            <FontAwesomeIcon
                              icon={faChartBar}
                              style={{
                                marginRight: 20,
                              }}
                              className={"txt-light"}
                            />
                            <span className={"d-none d-lg-inline"}>
                              Xapa Report{" "}
                            </span>
                          </Link>
                        </li>
                      ) : null}
                      {permissions.indexOf("ADMIN_BONUS_REPORT") !== -1 ? (
                        <li
                          className={
                            "animated" +
                            (this.props.path === "/bonusreport"
                              ? " active"
                              : "")
                          }
                        >
                          <Link to="/bonusreport" className={"txt-light"}>
                            <FontAwesomeIcon
                              icon={faChartBar}
                              style={{
                                marginRight: 20,
                              }}
                              className={"txt-light"}
                            />
                            <span className={"d-none d-lg-inline"}>
                              Bonus Report{" "}
                            </span>
                          </Link>
                        </li>
                      ) : null}
                      {permissions.indexOf("ADMIN_TRANSACTION_EXCHANGE") !==
                      -1 ? (
                        <li
                          className={
                            "animated" +
                            (this.props.path === "/exchangereport"
                              ? " active"
                              : "")
                          }
                        >
                          <Link to="/exchangereport" className={"txt-light"}>
                            <FontAwesomeIcon
                              icon={faChartBar}
                              style={{
                                marginRight: 20,
                              }}
                              className={"txt-light"}
                            />
                            <span className={"d-none d-lg-inline"}>
                              Exchange Report{" "}
                            </span>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                ) : null}

                <li className={"animated"}>
                  <Link
                    onClick={() => {
                      localStorage.removeItem("token");
                      window.location.reload();
                    }}
                    to="/"
                    className={"txt-light"}
                  >
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      style={{
                        marginRight: 20,
                      }}
                      className={"txt-light"}
                    />
                    <span className={"d-none d-lg-inline"}>Logout </span>
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            lg={this.state.menuToggle ? 11 : 10}
            xs={11}
            className={"animated"}
          >
            <Row>
              <Col className={"animated no-padding"} xs={12}>
                <div className="bg-dark top-nav flex">
                  <div className={"right-navbar"}>
                    <Avatar user={this.state.user} />
                    <NotifIcons icon={faBell} active={true} />
                    <NotifIcons icon={faEnvelope} active={true} />
                  </div>
                  <div className={"left-navbar"}>
                    <Button
                      variant="outline-light"
                      className={
                        "bar-style  txt-light d-none d-lg-inline-block"
                      }
                      onClick={() => {
                        this.setState({
                          menuToggle: !this.state.menuToggle,
                        });
                      }}
                    >
                      <FontAwesomeIcon className={"animated"} icon={faBars} />
                    </Button>
                  </div>
                </div>
                <div className={"breadcrumb-top bg-dark-2"}>
                  <Col xs={6}>
                    <h6 className={"breadcrumb-title no-margin"}>
                      Xapa Cryptocurrency
                    </h6>
                  </Col>
                  <Col xs={6}>
                    <h6 className={"breadcrumb-route no-margin"}>
                      {this.props.path === "/members" ||
                      this.props.path === "/changelog" ||
                      this.props.path === "/promotion" ||
                      this.props.path === "/tokens" ||
                      this.props.path === "/roles"
                        ? "Setting"
                        : this.props.path === "/bonusreport" ||
                          this.props.path === "/xapareport" ||
                          this.props.path === "/exchangereport"
                        ? "Report"
                        : "Home"}{" "}
                      -
                      <span className={"txt-light"}>
                        {this.props.path === "/"
                          ? " Dashboard"
                          : this.props.path === "/roles"
                          ? " Roles"
                          : this.props.path === "/members"
                          ? " Members"
                          : this.props.path === "/history"
                          ? " History"
                          : this.props.path === "/usermanagement"
                          ? " User Management"
                          : this.props.path === "/changelog"
                          ? " Changelog"
                          : this.props.path === "/promotion"
                          ? " Promotion"
                          : this.props.path === "/xapareport"
                          ? " Xapa Report"
                          : this.props.path.slice(0, 12) === "/userprofile"
                          ? " User Profile"
                          : this.props.path === "/bonusreport"
                          ? " Bonus Report "
                          : this.props.path === "/exchangereport"
                          ? " Exchange Report"
                          : this.props.path === "/tokens"
                          ? "Tokens"
                          : null}
                      </span>
                    </h6>
                  </Col>
                </div>
              </Col>
            </Row>
            {this.props.children}
            <Row>
              <Col className={"footer bg-dark mt-5"}>
                <div
                  className={
                    "d-flex justify-content-center align-items-center txt-light"
                  }
                >
                  <h6>
                    2019 Copyrights © <span>Xapa Wallet</span>
                  </h6>
                </div>{" "}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
