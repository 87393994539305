import React, { Component } from "react";
import { Table, Col, Form, Row, Button, Accordion } from "react-bootstrap";

import { appVersionList } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import DeleteAppVersion from "./deleteAppVersion";

export default class ChangeLogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      maxPerPage: 25,
      loading: true,
      totalPages: 1,
      totalItems: 1,
      data: [],
      visible: false,
      id: 0,
      name: "",
    };

    this.handleMaxPerPageChange = this.handleMaxPerPageChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getData = this.getData.bind(this);
    this.rodaUnvisitable = this.rodaUnvisitable.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  getData() {
    const { page, maxPerPage } = this.state;
    this.setState({ loading: true });
    appVersionList(page, maxPerPage).then((res) => {
      this.setState({
        data: res.appVersions,
        totalItems: res.total_items,
        maxPerPage: res.items_per_page,
        totalPages: res.total_pages,
        loading: false,
      });
    });
  }

  handleMaxPerPageChange(event) {
    this.setState({ maxPerPage: event.target.value, page: 1 }, () => {
      this.getData();
    });
  }
  handlePageChange(event) {
    this.setState({ page: event.target.value }, () => {
      this.getData();
    });
  }
  rodaUnvisitable() {
    this.setState({ visible: false });
  }
  pagination = () => {
    const { totalPages } = this.state;
    let pagination = [];
    for (let i = 1; i <= totalPages; i++) {
      pagination.push(
        <option value={i}>
          Page {i} of {totalPages}
        </option>
      );
    }
    return pagination;
  };
  render() {
    const { page, maxPerPage, loading, data, visible, id, name } = this.state;
    return (
      <div className={"pt-5 member-main"}>
        {visible ? (
          <DeleteAppVersion
            rodalUnvisible={this.rodaUnvisitable}
            id={id}
            name={name}
            reloadData={this.getData}
          />
        ) : null}
        <Row className={"pr-3"}>
          <Col xs={8}>
            <h5 className={"no-margin text-light"}>App Versions</h5>
          </Col>
          <Col xs={2} className={"px-1"}>
            <Form.Control
              value={maxPerPage}
              onChange={this.handleMaxPerPageChange}
              size="sm"
              as="select"
            >
              <option value={25}>View 25 Per Page</option>
              <option value={50}>View 50 Per Page</option>
              <option value={100}>View 100 Per Page</option>
            </Form.Control>
          </Col>
          <Col xs={2} className={"px-1"}>
            <Form.Control
              value={page}
              onChange={this.handlePageChange}
              size="sm"
              as="select"
            >
              {this.pagination()}
            </Form.Control>
          </Col>
        </Row>
        <Accordion defaultActiveKey="-1">
          <Table
            borderless
            striped
            className={"data-table table-responsive-xl"}
          >
            <thead className={"txt-light"}>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Release Date</th>
                <th>Platform</th>
                <th>Force Update</th>
                <th>View Change Log</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody className={"bg-light txt-light rounded"}>
              {!loading
                ? data.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.releaseDate}</td>
                        <td>{item.platform}</td>
                        <td>
                          {item.forceUpdateRequired ? (
                            <FontAwesomeIcon icon={faCheck} />
                          ) : null}
                        </td>
                        <td>
                          <Accordion.Toggle
                            as={Button}
                            eventKey={item.id}
                            className={"border-0 p-0 px-1"}
                            variant="outline-light"
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Accordion.Toggle>
                        </td>
                        <td>
                          <Button
                            onClick={() => {
                              this.setState({
                                visible: true,
                                id: item.id,
                                name: item.name,
                              });
                            }}
                            className={"border-0 p-0 px-1"}
                            variant="outline-light"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={7}>
                          <Accordion.Collapse eventKey={item.id}>
                            <Table
                              bordered
                              className={"app-version text-light"}
                            >
                              {item.changeLog.map((logs, i) => (
                                <>
                                  <thead
                                    className={i > 0 ? "border-top" : null}
                                  >
                                    <tr>
                                      <th colSpan={10}>{logs.groupName}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      {logs.items.map((items, key) => (
                                        <td>{items}</td>
                                      ))}
                                    </tr>
                                  </tbody>
                                </>
                              ))}
                            </Table>
                          </Accordion.Collapse>
                        </td>
                      </tr>
                    </>
                  ))
                : null}
            </tbody>
          </Table>
        </Accordion>
      </div>
    );
  }
}
