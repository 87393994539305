import React, { Component } from 'react'
import { totalBalanceApi } from '../../utils'
import { Spinner } from 'react-bootstrap'
import BarChart from '../../components/barChart'
export default class TotalBalance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalBalance: 0,
            data: [],
            days: [],
            loading: true,
        }
    }
    componentDidMount() {
        this.getData()
        setInterval(() => {
            this.getData()
        }, 10000)
    }
    getData() {
        totalBalanceApi().then((res) => {
            let data = []
            let totalBalance = res.main.total.balance
            for (let i = 0; i < 7; i++) {
                data.push({ x: i, y: Number(res.main.week[i].balance) })
            }
            this.setState({
                data,
                totalBalance,
                days: res.main.week,
                loading: false,
            })
        })
    }
    render() {
        const { totalBalance, data, days, loading } = this.state
        if (loading)
            return (
                <div
                    style={{
                        height: 150,
                        width: '33%',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Spinner animation="grow" variant="light" />
                </div>
            )
        return (
            <BarChart
                price={true}
                title={'Total Balances'}
                days={days}
                count={totalBalance}
                data={data}
            />
        )
    }
}
