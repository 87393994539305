import React, { Component } from 'react'
import { Form, Spinner, InputGroup, FormControl, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import {
    faEnvelope,
    faLock,
    faCheckSquare,
    faUser,
    faAddressCard,
} from '@fortawesome/free-solid-svg-icons'
import { RegisterApi } from '../../utils'
export default class RegisterForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            password: '',
            rePassword: '',
            referral: '',
            terms: false,
        }
        this.handleEmail = this.handleEmail.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
        this.handleRePassword = this.handleRePassword.bind(this)
        this.handleName = this.handleName.bind(this)
        this.handleReferral = this.handleReferral.bind(this)
        this.handleTerms = this.handleTerms.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleEmail(event) {
        this.setState({ email: event.target.value })
    }

    handlePassword(event) {
        this.setState({ password: event.target.value })
    }
    handleRePassword(event) {
        this.setState({ rePassword: event.target.value })
    }
    handleName(event) {
        this.setState({ name: event.target.value })
    }
    handleReferral(event) {
        this.setState({ referral: event.target.value })
    }
    handleTerms(event) {
        this.setState({ terms: event.target.checked })
    }
    async handleSubmit(event) {
        const { name, email, password, rePassword, referral } = this.state
        event.preventDefault()
        this.setState({ loading: true })
        RegisterApi(email, email, name, referral, password, rePassword).then(
            (res) => {
                if (res.code === 201) {
                    this.setState({ loading: false, redirect: true })
                } else {
                    this.setState({ err: true, loading: false })
                }
            }
        )
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to="/" />
        }
        return (
            <div className={'register  bg-light'}>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Label>Enter Name</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={'bg-dark txt-light rounded-0'}
                            >
                                <FontAwesomeIcon icon={faUser} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.name}
                            onChange={this.handleName}
                            type={'text'}
                            placeholder={'Enter Name'}
                            className={'bg-light txt-light rounded-0'}
                        />
                    </InputGroup>
                    <Form.Label>Enter Email</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={'bg-dark txt-light rounded-0'}
                            >
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.email}
                            onChange={this.handleEmail}
                            type={'Email'}
                            placeholder={'Enter Email'}
                            className={'bg-light txt-light rounded-0'}
                        />
                    </InputGroup>
                    <Form.Label>Enter Password</Form.Label>

                    <InputGroup className="mb-1">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={'bg-dark txt-light rounded-0'}
                            >
                                <FontAwesomeIcon icon={faCheckSquare} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.password}
                            onChange={this.handlePassword}
                            type={'password'}
                            placeholder={'Enter Password'}
                            className={'bg-light txt-light rounded-0'}
                        />
                    </InputGroup>
                    <Form.Label>Confirm Password</Form.Label>

                    <InputGroup className="mb-1">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={'bg-dark txt-light rounded-0'}
                            >
                                <FontAwesomeIcon icon={faLock} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.rePassword}
                            onChange={this.handleRePassword}
                            type={'password'}
                            placeholder={'Enter Password'}
                            className={'bg-light txt-light rounded-0'}
                        />
                    </InputGroup>
                    <Form.Label>Referral</Form.Label>

                    <InputGroup className="mb-1">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={'bg-dark txt-light rounded-0'}
                            >
                                <FontAwesomeIcon icon={faAddressCard} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.referral}
                            onChange={this.handleReferral}
                            type={'text'}
                            placeholder={'Referral ID'}
                            className={'bg-light txt-light rounded-0'}
                        />
                    </InputGroup>

                    <Form.Check
                        onChange={this.handleTerms}
                        type="checkbox"
                        label={
                            <p className={'txt-light forgot-text'}>
                                {' '}
                                By registraition you accepted{' '}
                                <span> Terms and Condition</span>
                            </p>
                        }
                    />
                    <Button
                        disabled={
                            this.state.password.length < 5 ||
                            this.state.password !== this.state.rePassword ||
                            this.state.email.length < 10 ||
                            !this.state.terms
                        }
                        size="sm"
                        type="submit"
                        className={'bg-light-2 border mt-4 btn-shaped'}
                        block="block"
                    >
                        {this.state.loading ? (
                            <Spinner animation="grow" variant="light" />
                        ) : (
                            'Register'
                        )}
                    </Button>
                    <Button
                        onClick={this.props.loginHandler}
                        size="sm"
                        className={'bg-light border mt-3 btn-shaped'}
                        block="block"
                    >
                        Login
                    </Button>
                </Form>
            </div>
        )
    }
}
