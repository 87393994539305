import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import DataTable from "../../components/dataTable";
import { transactionsListApi } from "../../utils";

export default class RecentTransActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 10000);
  }
  getData() {
    transactionsListApi(1, 10, "", "0001-01-01", "9999-12-29", "","main").then(
      (res) => {
        this.setState({ data: res.transactionHistory, loading: false });
      }
    );
  }
  render() {
    const { data, loading } = this.state;
    return (
      <div>
        <Row className={"panel-rows"}>
          <Col>
            <h5 className={"no-margin"}>Recent Transactions</h5>
          </Col>
        </Row>
        {!loading ? (
          <DataTable userProfile={this.props.userProfile} data={data} />
        ) : (
          <span />
        )}
      </div>
    );
  }
}
