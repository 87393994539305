import { Table } from "react-bootstrap";
import React from "react";
import BigNumber from "bignumber.js/bignumber";

const DataTableBonusReport = (props) => {
  return (
    <>
      <Table className={"data-table  table-responsive-xl"} striped hover>
        <thead className={"txt-light"}>
          <tr>
            {props.headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className={"bg-light txt-light rounded"}>
          {props.data.map((item, index) => (
            <tr key={index}>
              <td>{item.user.id}</td>
              <td>
                <a
                  className={"txt-orange"}
                  href={`/userprofile/${item.user.id}`}
                >
                  {item.user.email}
                </a>
              </td>
              <td>
                <a
                  className={"txt-orange"}
                  href={`/userprofile/${item.invited_user.id}`}
                >
                  {item.invited_user.email}
                </a>
              </td>
              <td>
                {BigNumber(
                  item.bonusAmount / item.bonusWallet.coinType.conversionRate
                ).toFixed(6)}{" "}
                {item.bonusWallet.coinType.symbol}
              </td>
              <td>
                {(
                  BigNumber(
                    item.bonusAmount / item.bonusWallet.coinType.conversionRate
                  ) * item.bonusWallet.coinType.fiatExchangeRate
                ).toFixed(6)}
                {" USD"}
              </td>

              <td>{item.created_at.replace("+00:00", "").replace("T", " ")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DataTableBonusReport;
