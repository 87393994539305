import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DataTableBlackList from "./dataTableBlackList";
import { getBlackList } from "../../utils";

class BlackList extends Component {
  state = {
    data: [],
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    getBlackList().then((res) => {
      if (res.code === 200) {
        this.setState({ data: res.res.result });
      }
    });
  };
  render() {
    const { data } = this.state;
    return (
      <Container fluid>
        <Row className={"panel-rows"}>
          <h5>Black List</h5>
        </Row>
        <Row>
          <Col>
            <DataTableBlackList
              data={data}
              headers={["Id", "Market Name", "Delete"]}
            />
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}

export default BlackList;
