import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default class AccessDenied extends Component {
    render() {
        return (
            <div
                style={{ height: '100vh' }}
                className={
                    'justify-content-center align-items-center d-flex flex-column'
                }
            >
                <h1 className="text-center text-light">Access Denied</h1>
                <Button
                    onClick={() => {
                        localStorage.removeItem('token')
                        window.location.reload()
                    }}
                    size="lg"
                    className={'bg-light border mb-2 mt-3 btn-shaped px-5'}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    LogOut
                </Button>
            </div>
        )
    }
}
