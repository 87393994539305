import { Button, Table } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DataTableBlackList = (props) => {
  return (
    <>
      <Table className={"data-table  table-responsive-xl"} striped hover>
        <thead className={"txt-light"}>
          <tr>
            {props.headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className={"bg-light txt-light rounded"}>
          {props.data.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.MarketName}</td>
              <td>
                <Button className={"border-0 p-0 px-1"} variant="outline-light">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DataTableBlackList;
