import React, { Component } from "react";
import { Col } from "react-bootstrap";

class AccountingFinancial extends Component {
  render() {
    const { accountingFinancial } = this.props;
    return (
      <Col lg={6} xs={12}>
        <div className={"bg-light rounded mt-2  mx-3 p-3"}>
          <div>
            <h4 className={"text-center txt-orange border-bottom"}>
              Financial Reports
            </h4>
            <ul className={"non-list-style text-light m-0 pl-3"}>
              <li>
                Total Customers’ Credit:
                <span className="txt-orange">
                  {" $" +
                    Number(
                      accountingFinancial.total_customers_credit.usd_balance
                    ).toFixed(2)}
                </span>
              </li>
              <li>
                Total Current Balance:
                <span className="txt-orange">
                  {" $" +
                    Number(
                      accountingFinancial.total_current_balance.usd_balance
                    ).toFixed(2)}
                </span>
              </li>

              <li className={"border-top"}>
                Total Income:
                <span className="txt-orange">
                  {" " + accountingFinancial.total_income.count} Transaction ($
                  {Number(accountingFinancial.total_income.usd_balance).toFixed(
                    2
                  )}
                  )
                </span>
              </li>
              <li>
                Total Outcome:
                <span className={"txt-orange"}>
                  {" " + accountingFinancial.total_outcome.count} Transaction ($
                  {Number(
                    accountingFinancial.total_outcome.usd_balance
                  ).toFixed(2)}
                  )
                </span>
              </li>
              <li>
                Paid Bonus:
                <span className="txt-orange">
                  {" $" +
                    Number(
                      accountingFinancial.paid_bonuses.usd_balance
                    ).toFixed(2)}
                </span>
              </li>
              <li>
                Allocated Bonus:
                <span className="txt-orange">
                  {" " +
                    accountingFinancial.allocated_bonus_wallets.details
                      .users}{" "}
                  Users{" ,"}
                  {
                    accountingFinancial.allocated_bonus_wallets.details.bonuses
                  }{" "}
                  Bonuses
                </span>
              </li>
              <li>
                Total Net Revenue Earned by Send:
                <span className="txt-orange">
                  {" $" +
                    Number(
                      accountingFinancial.total_revenue_earned_by_send
                        .usd_balance
                    ).toFixed(2)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    );
  }
}

export default AccountingFinancial;
