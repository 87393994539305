import React, { Component } from "react";
import { Row } from "react-bootstrap";

import CoinCharts from "../../components/coinChart";
import UsersStatistics from "./usersStatistics";
import RecentTransActions from "./recentTransActions";
import BalanceStatistics from "./balanceStatistics";
import CustomerBalance from "./customerBalance";
import XapaBalance from "./xapaBalance";
export default class Home extends Component {
  state = {
    customerBalanceData: [],
  };
  setCustomerBalance = (customerBalanceData) => {
    this.setState({ customerBalanceData });
  };
  render() {
    const { permissions } = this.props.user;
    const { customerBalanceData } = this.state;
    return (
      <div className="main-panel animated">
        {permissions.indexOf("ADMIN_CANDLES") !== -1 ? <CoinCharts /> : null}
        {permissions.indexOf("ADMIN_TOTAL_USER") !== -1 ||
        permissions.indexOf("ADMIN_TOTAL_ONLINE_USER") !== -1 ? (
          <UsersStatistics permissions={permissions} />
        ) : null}
        {permissions.indexOf("ADMIN_CUSTOMER_BALANCE") !== -1 ? (
          customerBalanceData.length ? (
            <XapaBalance customerBalanceData={customerBalanceData} />
          ) : null
        ) : null}
        {permissions.indexOf("ADMIN_CUSTOMER_BALANCE") !== -1 ? (
          <CustomerBalance setCustomerBalance={this.setCustomerBalance} />
        ) : null}
        {permissions.indexOf("ADMIN_TRANSACTION_BALANCES") !== -1 ||
        permissions.indexOf("ADMIN_TRANSACTION_SENT") !== -1 ||
        permissions.indexOf("ADMIN_TRANSACTION_EXCHANGE") !== -1 ? (
          <BalanceStatistics permissions={permissions} />
        ) : null}
        {permissions.indexOf("ADMIN_TRANSACTION_HISTORY") !== -1 ? (
          <RecentTransActions
            userProfile={permissions.indexOf("ADMIN_SHOW_USER") !== -1}
          />
        ) : null}
        <Row />
      </div>
    );
  }
}
