import React, { Component } from "react";
import { Col } from "react-bootstrap";

class AccountingUser extends Component {
  render() {
    const { accountingUser } = this.props;
    return (
      <Col lg={6} xs={12}>
        <div className={"bg-light rounded  mx-3 mt-3 p-3"}>
          <div>
            <h4 className={"text-center txt-orange border-bottom"}>Users</h4>
            <ul className={"non-list-style text-light m-0 pl-3"}>
              <li>
                Total Registered:{" "}
                <span className="txt-orange">
                  {accountingUser.total_registered}
                </span>{" "}
              </li>
              <li>
                Total verified:{" "}
                <span className="txt-orange">
                  {accountingUser.total_verified}
                </span>
              </li>
              <li>
                Total Investors:{" "}
                <span className="txt-orange">
                  {" "}
                  {accountingUser.total_users_assets}
                </span>
              </li>

              <li className={"border-top"}>
                Registration:{" "}
                <span className="txt-orange">
                  {accountingUser.total_registration}
                </span>
              </li>
              <li>
                Verified:{" "}
                <span className="txt-orange">
                  {accountingUser.total_verified_last}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Col>
    );
  }
}

export default AccountingUser;
