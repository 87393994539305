import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import TotalUserRegister from './totaluserRegister'
import TotalUserRegisterVerified from './totalUserRegisterVerified'
import TotalOnlineUsers from './totalOnlineUsers'

export default class UsersStatistics extends Component {
    render() {
        const { permissions } = this.props
        return (
            <Row className={'panel-rows'}>
                {permissions.indexOf('ADMIN_TOTAL_USER') !== -1 ? (
                    <TotalUserRegister />
                ) : null}
                {permissions.indexOf('ADMIN_TOTAL_USER') !== -1 ? (
                    <TotalUserRegisterVerified />
                ) : null}
                {permissions.indexOf('ADMIN_TOTAL_ONLINE_USER') !== -1 ? (
                    <TotalOnlineUsers />
                ) : null}
            </Row>
        )
    }
}
