import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { customerBalanceApi } from "../../utils";

export default class CustomerBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 10000);
  }
  getData() {
    customerBalanceApi().then(({res, code}) => {
      if(code===200){
        this.setState({ data: res.balances, loading: false }, () => {
        this.props.setCustomerBalance(res.balances);
      })
    }
    });
  }

  render() {
    const { loading, data } = this.state;
    return (
      <Row className={"panel-rows"}>
        <Col xs={12} className={"no-padding"}>
          <h5 className={"no-margin text-light pl-3"}>Customer Balance</h5>
        </Col>
        {!loading || !data.id
          ? data.map((balance, index) => (
              <Col key={index} md={3} sm={6} xs={12}>
                <div
                  style={{ backgroundColor: "#00aae5" }}
                  className={"rounded gradient-card p-2 mt-2"}
                >
                  <Row>
                    <Col>
                      <img alt="bitcoin" src={balance.icon} />
                      <h6
                        className={"no-margin text-light d-inline-block pl-1"}
                      >
                        {balance.name}
                      </h6>
                    </Col>
                    <Col>
                      <h6 className={"no-margin text-light text-right  mt-1"}>
                        {Number(balance.formatted_balance).toFixed(6)}{" "}
                        {balance.symbol}
                      </h6>
                      <h6 className={"no-margin text-light text-right "}>
                        {Number(balance.price).toFixed(2)} $
                      </h6>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))
          : null}
      </Row>
    );
  }
}
