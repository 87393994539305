import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import UserListTable from './userListTable'
import CreateUser from './createUser'

export default class UserManagement extends Component {
    constructor(props) {
        super(props)
        this.reload = this.reload.bind(this)
    }
    reload() {
        this.refs.adminList.getData()
    }
    render() {
        const { permissions } = this.props.user
        return (
            <Container className={'pt-5 member-main'}>
                {permissions.indexOf('ADMIN_CREATE_USER') !== -1 ? (
                    <CreateUser reload={this.reload} user={true} />
                ) : null}

                <UserListTable
                    r
                    edit={permissions.indexOf('ADMIN_EDIT_USER') !== -1}
                    user={true}
                    ref="adminList"
                />
            </Container>
        )
    }
}
