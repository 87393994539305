import React, { Component } from 'react'
import Rodal from 'rodal'
import { Container, Button, Spinner } from 'react-bootstrap'
import { roleDeleteApi } from '../../utils'

export default class DeleteRole extends Component {
    constructor(props) {
        super(props)
        this.state = { loading: false }
        this.delete = this.delete.bind(this)
    }
    delete() {
        this.setState({ loading: true })
        const { roles } = this.props
        roleDeleteApi(roles.id).then((res) => {
            this.props.reloadData()
        })
    }
    render() {
        const { roles } = this.props
        const { loading } = this.state
        return (
            <div>
                <Rodal
                    width={300}
                    duration={500}
                    animation={'zoom'}
                    visible={true}
                    onClose={this.props.rodalUnvisible}
                >
                    <Container className={'bg-dark p-4'}>
                        <p>Do You Want To Delete {roles.role} Role?</p>
                        <Button
                            onClick={this.delete}
                            size="sm"
                            className={'bg-light border mb-2 btn-shaped px-5'}
                        >
                            {loading ? (
                                <Spinner
                                    size={'sm'}
                                    animation="border"
                                    variant="light"
                                />
                            ) : (
                                'Yes'
                            )}
                        </Button>
                        <Button
                            onClick={this.props.rodalUnvisible}
                            size="sm"
                            className={
                                'bg-dark border ml-5 mb-2 btn-shaped px-3'
                            }
                        >
                            Cancel
                        </Button>
                    </Container>
                </Rodal>
            </div>
        )
    }
}
