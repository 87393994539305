import React from "react";
import DatePicker from "react-datepicker";
import Rodal from "rodal";
import { appVersionGroupList, appVersionAddApi } from "../../utils";
import { Form, Col, Row, Button, Container, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

class CreateAppVersion extends React.Component {
  state = {
    date: "",
    visible: false,
    groupName: "",
    name: "",
    platform: "Android",
    forceUpdateRequired: false,
    changeLog: [],
    items: [""],
    groupNames: [],
    loading: false,
    removeItem: 0,
  };
  componentDidMount() {
    this.setState({ loading: true });
    appVersionGroupList().then((res) => {
      this.setState({ groupNames: res, loading: false });
    });
  }
  handleSubmit = () => {
    const { name, date, platform, changeLog, forceUpdateRequired } = this.state;
    this.setState({ loading: true });
    appVersionAddApi(
      name,
      moment(date).format("yyyy-MM-DD"),
      platform,
      changeLog,
      forceUpdateRequired
    ).then((res) => {
      this.setState({ loading: false }, () => {
        this.props.refreshPage();
      });
    });
  };
  handleNameChange = (e) => {
    const { value } = e.target;
    let itemValue = value;
    const { name } = this.state;
    (name.length === 0 && itemValue.length > name.length) ||
    (name.length === 4 && itemValue.length > name.length)
      ? (itemValue = value + ".")
      : (itemValue = value);
    this.setState({ name: itemValue });
  };
  handleReleaseDateChange = (e) => {
    const { value } = e.target;
    this.setState({ releaseDate: value });
  };
  handlePlatFormChange = (e) => {
    const { value } = e.target;
    this.setState({ platform: value });
  };
  handleForceUpdateRequiredChange = () => {
    this.setState({ forceUpdateRequired: !this.state.forceUpdateRequired });
  };
  handleGroupNameChange = (e) => {
    const { value } = e.target;
    this.setState({ groupName: value });
  };
  handleItemChange = (e) => {
    const { name, value } = e.target;
    let items = this.state.items;
    items[name] = value;
    this.setState({ items });
  };
  handleAddField = () => {
    this.setState((state) => {
      const items = [...state.items, ""];
      return {
        items,
      };
    });
  };
  handleAddChangeLog = () => {
    const { items, groupName, changeLog } = this.state;
    let list = changeLog;
    items.forEach((item) => {
      list.push({ group: groupName, text: item });
    });
    this.setState(
      (state) => {
        return {
          changeLog: list,
          items: [""],
          groupName: "",
          visible: false,
        };
      },
      () => {
        this.changelogForm.reset();
      }
    );
  };
  removeGroupItem = () => {
    let { removeItem, changeLog } = this.state;
    changeLog.splice(removeItem, 1);
    this.setState({ changeLog });
  };
  render() {
    const {
      date,
      items,
      name,
      groupName,
      groupNames,
      loading,
      changeLog,
      forceUpdateRequired,
    } = this.state;
    return (
      <div>
        <h5 className={"no-margin text-light mt-5"}>Create App Version</h5>

        <Form className={"mt-3 mb-0"}>
          <Row>
            <Col lg={3} xs={6}>
              <Form.Group>
                <Form.Label column sm="12" className={"text-light"}>
                  Name :
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    value={name}
                    onChange={this.handleNameChange}
                    minlength="8"
                    maxlength="8"
                    className={"rounded-0"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col lg={3} xs={6}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label column sm="12" className={"text-light"}>
                  Platform :
                </Form.Label>
                <Form.Control onChange={this.handlePlatFormChange} as="select">
                  <option value={"Android"}>Android</option>
                  <option value={"IOS"}>IOS</option>
                  <option value={"Web"}>Web</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={2} xs={12}>
              <Form.Group>
                <Form.Label column sm="12" className={"text-light"}>
                  Release Date :
                </Form.Label>
                <Col sm="12">
                  <DatePicker
                    dateFormat={"yyyy-MM-dd"}
                    className={"text-center"}
                    selected={this.state.date}
                    onChange={(date) => {
                      this.setState({ date });
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col lg={3} xs={12}>
              <Form.Group className={"mb-0 p-3"} as={Row}>
                <Col xs={"2 mt-3 pt-2 pr-0"}>
                  <Form.Group id="formGridCheckbox">
                    <Form.Check
                      value={forceUpdateRequired}
                      onChange={this.handleForceUpdateRequiredChange}
                      type="checkbox"
                    />
                  </Form.Group>
                </Col>
                <Form.Label
                  column
                  xs="10"
                  className={"text-light mt-3 pl-0 ml-0"}
                >
                  Force Update Required
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <h6 className={"text-light ml-4"}>Changelog</h6>
          </Row>
          <Row className={"px-5"}>
            {changeLog.map((logs, i) => (
              <Col xs={3} className={"text-center text-light border"}>
                <b>{logs.group}</b>:{logs.text}
                <Button
                  onClick={this.removeGroupItem}
                  size={"sm"}
                  variant={"danger"}
                  className={"ml-2 rounded-circle my-1"}
                >
                  X
                </Button>
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <Button
                disabled={date.length === 0 || name.length === 0}
                onClick={() => {
                  this.setState({ visible: true });
                }}
                className={"bg-light border mb-4 btn-shaped px-3 ml-4 mt-4 "}
              >
                Add Changelog
              </Button>
            </Col>
            <Col className={"text-right pr-5"}>
              <Button
                disabled={changeLog.length === 0}
                onClick={this.handleSubmit}
                className={"bg-dark border mb-4 btn-shaped px-3 ml-4 mt-4"}
              >
                {loading ? (
                  <Spinner size={"sm"} animation="border" variant="light" />
                ) : (
                  "Submit Changelog"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
        <Rodal
          width={800}
          duration={500}
          animation={"door"}
          visible={this.state.visible}
          onClose={() => {
            this.setState({ visible: false });
          }}
        >
          <Container className={"bg-dark p-4"}>
            <div className={"border-bottom border-secondary pb-1"}>
              <h5 className={"text-light mb-0"}>New Version</h5>
              <span className={"txt-light"}>Create New App Version</span>
            </div>
            <Form
              ref={(el) => (this.changelogForm = el)}
              className={"mt-3 mb-0"}
            >
              <Form.Group>
                <Form.Label column sm="12" className={"text-light"}>
                  * Group Name :
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    onChange={this.handleGroupNameChange}
                    size="sm"
                    as="select"
                  >
                    <option />
                    {!loading
                      ? groupNames.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))
                      : null}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Label column sm="12" className={"text-light"}>
                * Items :
              </Form.Label>
              <Row>
                {items.map((item, index) => (
                  <Col xs={4}>
                    <Form.Group>
                      <Col sm="12">
                        <Form.Control
                          disabled={groupName.length === 0}
                          value={item.text}
                          name={index}
                          onChange={this.handleItemChange}
                          className={"rounded-0"}
                          type={"text"}
                          placeholder=" "
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                ))}

                <div className={"pl-4"}>
                  <Button
                    size="sm"
                    disabled={
                      groupName.length === 0 ||
                      items[items.length - 1].length < 4
                    }
                    className={"bg-light border mb-4 btn-shaped px-2"}
                    onClick={this.handleAddField}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </div>
              </Row>
              <Row>
                <Col>
                  <div className={"pl-4"}>
                    <Button
                      disabled={
                        groupName.length === 0 ||
                        items[items.length - 1].length < 4
                      }
                      size="sm"
                      className={"bg-light border mb-4 btn-shaped px-3"}
                      onClick={this.handleAddChangeLog}
                    >
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </Rodal>
      </div>
    );
  }
}

export default CreateAppVersion;
