import React, { Component } from 'react'
import { Form, Spinner, InputGroup, FormControl, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import { LoginApi } from '../../utils'
import { Redirect } from 'react-router-dom'
export default class LoginForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            loading: false,
            redirect: false,
            err: false,
        }

        this.handleEmail = this.handleEmail.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleEmail(event) {
        this.setState({ email: event.target.value })
    }

    handlePassword(event) {
        this.setState({ password: event.target.value })
    }

    async handleSubmit(event) {
        event.preventDefault()
        this.setState({ loading: true })
        LoginApi(this.state.email, this.state.password).then((res) => {
            if (res.token) {
                this.setState({ loading: false, redirect: true })
            } else {
                this.setState({ err: true, loading: false })
            }
        })
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to="/" />
        }
        return (
            <div className={'login bg-light'}>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Label>Enter Email</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={'bg-dark txt-light rounded-0'}
                            >
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.email}
                            onChange={this.handleEmail}
                            type={'Email'}
                            placeholder={'Enter Email'}
                            className={'bg-light txt-light rounded-0'}
                        />
                    </InputGroup>
                    <Form.Label>Enter Password</Form.Label>

                    <InputGroup className="mb-1">
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                className={'bg-dark txt-light rounded-0'}
                            >
                                <FontAwesomeIcon icon={faLock} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            value={this.state.password}
                            onChange={this.handlePassword}
                            type={'password'}
                            placeholder={'Enter Password'}
                            className={'bg-light txt-light rounded-0'}
                        />
                    </InputGroup>
                    <p className={'txt-light forgot-text'}>
                        Forgot your password?
                        <span onClick={this.props.resetHandler}>Rest it</span>
                    </p>
                    <Button
                        disabled={this.state.password.length < 5}
                        type="submit"
                        size="sm"
                        className={'bg-light-2 border mt-4 btn-shaped'}
                        block="block"
                    >
                        {this.state.loading ? (
                            <Spinner animation="grow" variant="light" />
                        ) : (
                            'Login'
                        )}
                    </Button>
                    <Button
                        onClick={this.props.registerHandler}
                        size="sm"
                        className={'bg-light border mt-3 btn-shaped'}
                        block="block"
                    >
                        Create Account
                    </Button>
                </Form>
            </div>
        )
    }
}
