import React, { Component } from "react";
import Rodal from "rodal";
import { Container, Button, Spinner } from "react-bootstrap";
import { deleteAppVersionApi } from "../../utils";

export default class DeleteAppVersion extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.delete = this.delete.bind(this);
  }
  delete() {
    this.setState({ loading: true });
    const { id } = this.props;
    deleteAppVersionApi(id).then((res) => {
      this.setState({ loading: false }, () => {
        this.props.reloadData();
        this.props.rodalUnvisible();
      });
    });
  }
  render() {
    const { name } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Rodal
          width={300}
          duration={500}
          animation={"zoom"}
          visible={true}
          onClose={this.props.rodalUnvisible}
        >
          <Container className={"bg-dark p-4"}>
            <p className="text-light">Do You Want To Delete {name} Version?</p>
            <Button
              onClick={this.delete}
              size="sm"
              className={"bg-light border mb-2 btn-shaped px-5"}
            >
              {loading ? (
                <Spinner size={"sm"} animation="border" variant="light" />
              ) : (
                "Yes"
              )}
            </Button>
            <Button
              onClick={this.props.rodalUnvisible}
              size="sm"
              className={"bg-dark border ml-5 mb-2 btn-shaped px-3"}
            >
              Cancel
            </Button>
          </Container>
        </Rodal>
      </div>
    );
  }
}
