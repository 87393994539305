import { Table } from "react-bootstrap";
import React from "react";

const DataTableExchangeReport = (props) => {
  return (
    <>
      <Table className={"data-table  table-responsive-xl"} striped hover>
        <thead className={"txt-light"}>
          <tr>
            {props.headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className={"bg-light txt-light rounded"}>
          {props.data.map((item, index) => (
            <tr key={index}>
              <td>
                {props.link ? (
                  <a
                    className={"txt-orange"}
                    href={`/userprofile/${item.user.id}`}
                  >
                    {item.user.email}
                  </a>
                ) : (
                  item.user.email
                )}
              </td>
              <td>{item.fromWallet.coinType.symbol}</td>
              <td>{item.toWallet.coinType.symbol}</td>
              <td>{item.exchangeRate}</td>
              <td>{item.formatedFromAmount}</td>
              <td>{item.formatedToAmount}</td>
              <td>{item.created_at.replace("+00:00", "").replace("T", " ")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DataTableExchangeReport;
