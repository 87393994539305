import React from "react";
import SetPromotion from "./setPromotion";

class Promotion extends React.Component {
  render() {
    return (
      <div>
        <SetPromotion />
      </div>
    );
  }
}
export default Promotion;
