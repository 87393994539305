import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Table,
  InputGroup,
  FormControl,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import TimeCountDown from "./timeCountDown";
import { coinsApi, postPromotionApi, promotionApi } from "../../utils";
import moment from "moment";
import BigNumber from "bignumber.js/bignumber";
class SetPromotion extends React.Component {
  state = {
    loading: true,
    startDate: Date.now(),
    endDate: Date.now(),
    inviterCoin: 1,
    invitedCoin: 1,
    minimumInvitedCoin: 1,
    inviterDefault: 0,
    inviterAmount: 0,
    invitedDefault: 0,
    invitedAmount: 0,
    waitingTime: 50,
    minimumInvitedBonus: 60,
    removeItem: 0,
    minimumList: [],
    coins: [],
    timerTime: 100000,
    timerStart: false,
    minimumBonusCoins: [],
    enable: true,
    updateLoading: false,
    stopLoading: false,
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true });
    coinsApi().then((res) => {
      this.setState({ coins: res }, () => {
        const { coins } = this.state;
        promotionApi().then((res) => {
          const invitedCoin = coins.find(
            ({ symbol }) => symbol === res.invitation_bonus.invited_bonus.coin
          );
          const inviterCoin = coins.find(
            ({ symbol }) => symbol === res.invitation_bonus.inviter_bonus.coin
          );
          const minimumInvitedCoin = coins.find(
            ({ symbol }) => symbol === res.invitation_bonus.minimum[0].coin
          );
          this.setState(
            {
              enable: res.enabled.isEnabled,
              startDate: new Date(res.range.start_date + "+00:00"),
              endDate: new Date(res.range.end_date + "+00:00"),
              invitedAmount: (
                res.invitation_bonus.invited_bonus.amount *
                coins[invitedCoin.id - 1].fiatExchangeRate
              ).toFixed(2),
              invitedDefault: (
                res.invitation_bonus.invited_bonus.default *
                coins[invitedCoin.id - 1].fiatExchangeRate
              ).toFixed(2),
              invitedCoin: invitedCoin.id,
              inviterCoin: inviterCoin.id,
              inviterAmount: (
                res.invitation_bonus.inviter_bonus.amount *
                coins[inviterCoin.id - 1].fiatExchangeRate
              ).toFixed(2),
              inviterDefault: (
                res.invitation_bonus.inviter_bonus.default *
                coins[inviterCoin.id - 1].fiatExchangeRate
              ).toFixed(2),
              timerTime: res.timer.totalDuration,
              timerStart: res.timer.isStart,
              waitingTime: res.waiting_time.days,
              minimumInvitedBonus: (
                res.invitation_bonus.minimum[0].main *
                coins[minimumInvitedCoin.id - 1].fiatExchangeRate
              ).toFixed(2),
            },
            () => {
              const { timerTime } = this.state;
              if (timerTime === 0) {
                this.setState({ enable: false });
              }
              let minimumList = [];
              for (let i = 0; i < res.invitation_bonus.minimum.length; i++) {
                const minimumInvitedCoin = coins.find(
                  ({ symbol }) =>
                    symbol === res.invitation_bonus.minimum[i].coin
                );
                minimumList.push({
                  minimumInvitedBonus: (
                    res.invitation_bonus.minimum[i].main *
                    coins[minimumInvitedCoin.id - 1].fiatExchangeRate
                  ).toFixed(2),
                  minimumInvitedCoin: minimumInvitedCoin.id,
                  waitingTime: res.waiting_time.days,
                });
                this.setState({ minimumList, loading: false });
              }
            }
          );
        });
      });
    });
  };
  handleInviterDefaultChange = (e) => {
    const { value } = e.target;
    this.setState({
      inviterDefault: value,
    });
  };
  handleInviterAmount = (e) => {
    const { value } = e.target;
    this.setState({
      inviterAmount: value,
    });
  };
  handleInviterDefault = (e) => {
    const { value } = e.target;
    this.setState({
      invitedDefault: value,
    });
  };
  handleInvitedAmount = (e) => {
    const { value } = e.target;
    this.setState({
      invitedAmount: value,
    });
  };
  handleInviteDefault = (e) => {
    const { value } = e.target;
    this.setState({
      invitedDefault: value,
    });
  };
  handleMinimumInvitedBonus = (e) => {
    const { value } = e.target;
    this.setState({
      minimumInvitedBonus: value,
    });
  };
  handleWaitingTime = (e) => {
    const { value } = e.target;
    this.setState({
      waitingTime: value,
    });
  };
  addToMinimumList = () => {
    const { minimumInvitedBonus, waitingTime, minimumInvitedCoin } = this.state;
    let minimumList = this.state.minimumList;
    let minimumBonusCoins = this.state.minimumBonusCoins;
    minimumList.push({ minimumInvitedBonus, waitingTime, minimumInvitedCoin });
    minimumBonusCoins.push(minimumInvitedCoin);
    this.setState({ minimumList, minimumInvitedCoin });
  };
  removeItemFromMinimumList = () => {
    const { removeItem } = this.state;
    let minimumList = this.state.minimumList;
    minimumList.splice(removeItem, 1);
    this.setState({ minimumList });
  };
  updatePromotion = () => {
    this.setState({ updateLoading: true });
    const {
      startDate,
      endDate,
      inviterCoin,
      invitedCoin,
      inviterDefault,
      inviterAmount,
      invitedDefault,
      invitedAmount,
      waitingTime,
      minimumList,
      coins,

      enable,
    } = this.state;
    let minimum = [];
    for (let i = 0; i < minimumList.length; i++) {
      const minimumInvitedCoinSymbol = coins.find(
        ({ id }) => id === minimumList[i].minimumInvitedCoin
      );
      minimum.push({
        coin: minimumInvitedCoinSymbol.symbol,
        network: "main",
        amount: BigNumber(
          minimumList[i].minimumInvitedBonus /
            coins[minimumInvitedCoinSymbol.id - 1].fiatExchangeRate
        ),
      });
    }
    const inviterCoinSymbol = coins.find(({ id }) => id === inviterCoin);
    const invitedCoinSymbol = coins.find(({ id }) => id === invitedCoin);
    let data = {
      isEnabled: enable,
      minimum,
      invited_bonus: {
        coin: invitedCoinSymbol.symbol,
        amount: BigNumber(
          invitedAmount / coins[invitedCoin - 1].fiatExchangeRate
        ),
        default: BigNumber(
          invitedDefault / coins[invitedCoin - 1].fiatExchangeRate
        ),
        network: "main",
      },
      inviter_bonus: {
        coin: inviterCoinSymbol.symbol,
        amount: BigNumber(
          inviterAmount / coins[inviterCoin - 1].fiatExchangeRate
        ),
        default: BigNumber(
          inviterDefault / coins[inviterCoin - 1].fiatExchangeRate
        ),
        network: "main",
      },
      waiting_time: waitingTime,
      range: {
        start_date: moment(startDate).utc().format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment(endDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      },
    };
    postPromotionApi(data).then((res) => {
      this.setState({ updateLoading: false, stopLoading: false }, () => {
        this.getData();
      });
    });
  };
  timerEnded = () => {
    this.setState({ enable: false, timerStart: false });
  };
  submitPromotion = () => {
    this.setState({ enable: true }, () => {
      this.updatePromotion();
    });
  };
  stopNow = () => {
    this.setState({ stopLoading: true, enable: false }, () => {
      this.updatePromotion();
    });
  };
  startNow = () => {
    this.setState(
      {
        stopLoading: true,
        enable: true,
        startDate: Date.now(),
        endDate: Date.now() + 40000000000,
      },
      () => {
        this.updatePromotion();
      }
    );
  };
  render() {
    const {
      startDate,
      endDate,
      coins,
      inviterCoin,
      invitedCoin,
      minimumInvitedCoin,
      inviterDefault,
      inviterAmount,
      invitedDefault,
      invitedAmount,
      minimumInvitedBonus,
      waitingTime,
      minimumList,
      minimumBonusCoins,
      loading,
      timerTime,
      timerStart,
      enable,
      updateLoading,
      stopLoading,
    } = this.state;
    if (loading) return <span />;
    return (
      <div>
        <Row className={" d-flex justify-content-center"}>
          <Col xs={12}>
            <div className={"text-light bg-light rounded my-5 p-4"}>
              <h5 className={"text-center txt-light pb-4"}>
                <b>Set Promotion</b>
              </h5>
              <Row>
                <Col />
                <TimeCountDown
                  timerEnded={this.timerEnded}
                  timerStart={timerStart}
                  col={4}
                  time={enable ? timerTime : 0}
                />
                <Col />
              </Row>

              <Row>
                <Col xl={6} xs={12}>
                  <Row>
                    <Col xs={6} className={"text-center"}>
                      <div className={"border rounded"}>
                        <Row>
                          <Col xs={12}>
                            <label
                              style={{ position: "relative", top: -14 }}
                              className={"bg-light px-1"}
                            >
                              Start Date
                            </label>
                            <DatePicker
                              className={"text-center w-100"}
                              dateFormat={"yyyy-MM-dd HH:mm"}
                              showTimeSelect
                              popperClassName={"w-340"}
                              selected={startDate}
                              onChange={(startDate) => {
                                this.setState({ startDate });
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={6} className={"text-center"}>
                      <div className={"border rounded"}>
                        <Row>
                          <Col xs={12}>
                            <label
                              style={{ position: "relative", top: -14 }}
                              className={"bg-light px-1"}
                            >
                              End Date
                            </label>
                            <DatePicker
                              className={"text-center w-100"}
                              dateFormat={"yyyy-MM-dd HH:mm"}
                              showTimeSelect
                              popperClassName={"w-340"}
                              selected={endDate}
                              minDate={startDate}
                              onChange={(endDate) => {
                                this.setState({ endDate });
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className={"border rounded mt-4"}>
                        <label
                          style={{ position: "relative", top: -14 }}
                          className={"bg-light px-1"}
                        >
                          Inviter Default & Bonus Amount
                        </label>
                        <Row className={"p-2"}>
                          <Col lg={4} xs={12}>
                            <span className={"pl-2 small-text"}>
                              Default Amount
                            </span>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                disabled={inviterAmount > 0}
                                value={inviterDefault}
                                onChange={this.handleInviterDefaultChange}
                              />
                            </InputGroup>
                            <span className={"txt-orange small-text"}>
                              {coins.length !== 0
                                ? BigNumber(
                                    inviterDefault /
                                      coins[inviterCoin - 1].fiatExchangeRate
                                  ).toFixed(7) +
                                  " " +
                                  coins[inviterCoin - 1].symbol
                                : null}
                            </span>
                          </Col>
                          <Col lg={4} xs={12}>
                            <span className={"pl-2 small-text"}>
                              Promotion Amount
                            </span>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                disabled={inviterDefault > 0}
                                value={inviterAmount}
                                onChange={this.handleInviterAmount}
                              />
                            </InputGroup>
                            <span className={"txt-orange small-text"}>
                              {coins.length !== 0
                                ? BigNumber(
                                    inviterAmount /
                                      coins[inviterCoin - 1].fiatExchangeRate
                                  ).toFixed(7) +
                                  " " +
                                  coins[inviterCoin - 1].symbol
                                : null}
                            </span>
                          </Col>
                          <Col lg={4} xs={12}>
                            <span className={"pl-2 small-text"}>Coin Type</span>
                            <Form.Group>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="wallet"
                                  className={"bg-white"}
                                  id="dropdown-type"
                                >
                                  {coins.length > 0 ? (
                                    <>
                                      <img
                                        width={20}
                                        className={"mr-1"}
                                        src={coins[inviterCoin - 1].icon}
                                        alt={"coin"}
                                      />{" "}
                                      {coins[inviterCoin - 1].name}
                                    </>
                                  ) : null}
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{ minWidth: 130, width: 140 }}
                                >
                                  {coins.length > 0
                                    ? coins.map((coin, index) => (
                                        <Dropdown.Item
                                          key={index}
                                          onClick={() =>
                                            this.setState({
                                              inviterCoin: coin.id,
                                            })
                                          }
                                        >
                                          <img
                                            width={20}
                                            className={"mr-1"}
                                            src={coin.icon}
                                            alt={"coin"}
                                          />
                                          {coin.name}
                                        </Dropdown.Item>
                                      ))
                                    : null}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className={"border rounded mt-4"}>
                        <label
                          style={{ position: "relative", top: -14 }}
                          className={"bg-light px-1"}
                        >
                          Invited Default & Bonus Amount
                        </label>
                        <Row className={"p-2"}>
                          <Col lg={4} xs={12}>
                            <span className={"pl-2 small-text"}>
                              Default Amount
                            </span>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                disabled={invitedAmount > 0}
                                value={invitedDefault}
                                onChange={this.handleInviteDefault}
                              />
                            </InputGroup>
                            <span className={"txt-orange small-text"}>
                              {coins.length !== 0
                                ? BigNumber(
                                    invitedDefault /
                                      coins[invitedCoin - 1].fiatExchangeRate
                                  ).toFixed(7) +
                                  " " +
                                  coins[invitedCoin - 1].symbol
                                : null}
                            </span>
                          </Col>
                          <Col lg={4} xs={12}>
                            <span className={"pl-2 small-text"}>
                              Promotion Amount
                            </span>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                disabled={invitedDefault > 0}
                                value={invitedAmount}
                                onChange={this.handleInvitedAmount}
                              />
                            </InputGroup>
                            <span className={"txt-orange small-text"}>
                              {coins.length !== 0
                                ? BigNumber(
                                    invitedAmount /
                                      coins[invitedCoin - 1].fiatExchangeRate
                                  ).toFixed(7) +
                                  " " +
                                  coins[invitedCoin - 1].symbol
                                : null}
                            </span>
                          </Col>
                          <Col lg={4} xs={12}>
                            <span className={"pl-2 small-text"}>Coin Type</span>
                            <Form.Group>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="wallet"
                                  className={"bg-white"}
                                  id="dropdown-type"
                                >
                                  {coins.length > 0 ? (
                                    <>
                                      <img
                                        width={20}
                                        className={"mr-1"}
                                        src={coins[invitedCoin - 1].icon}
                                        alt={"coin"}
                                      />{" "}
                                      {coins[invitedCoin - 1].name}
                                    </>
                                  ) : null}
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{ minWidth: 130, width: 140 }}
                                >
                                  {coins.length > 0
                                    ? coins.map((coin, index) => (
                                        <Dropdown.Item
                                          key={index}
                                          onClick={() =>
                                            this.setState({
                                              invitedCoin: coin.id,
                                            })
                                          }
                                        >
                                          <img
                                            width={20}
                                            className={"mr-1"}
                                            src={coin.icon}
                                            alt={"coin"}
                                          />
                                          {coin.name}
                                        </Dropdown.Item>
                                      ))
                                    : null}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} xs={12}>
                  <Row>
                    <Col xs={12}>
                      <div className={"border"}>
                        <label
                          style={{ position: "relative", top: -14 }}
                          className={"bg-light px-1"}
                        >
                          Set Minimum Bonus For Invited
                        </label>
                        <Row className={"p-2"}>
                          <Col lg={3} xs={12}>
                            <span className={"pl-2 small-text"}>
                              Minimum Amount
                            </span>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                value={minimumInvitedBonus}
                                onChange={this.handleMinimumInvitedBonus}
                              />
                            </InputGroup>
                            <span className={"txt-orange small-text"}>
                              {coins.length !== 0
                                ? (
                                    minimumInvitedBonus /
                                    coins[minimumInvitedCoin - 1]
                                      .fiatExchangeRate
                                  ).toFixed(5) +
                                  " " +
                                  coins[minimumInvitedCoin - 1].symbol
                                : null}
                            </span>
                          </Col>
                          <Col lg={3} xs={12}>
                            <span className={"pl-2 small-text"}>
                              Waiting Time (Day)
                            </span>
                            <Form.Group>
                              <Form.Control
                                className={"d-inline-block"}
                                type={"text"}
                                disabled={minimumList.length > 0}
                                value={waitingTime}
                                onChange={this.handleWaitingTime}
                              />
                              <Form.Text />
                            </Form.Group>
                          </Col>
                          <Col lg={4} xs={12}>
                            <span className={"pl-2 small-text"}>Coin Type</span>
                            <Form.Group>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="wallet"
                                  className={"bg-white"}
                                  id="dropdown-type"
                                >
                                  {coins.length > 0 ? (
                                    <>
                                      <img
                                        width={20}
                                        className={"mr-1"}
                                        src={coins[minimumInvitedCoin - 1].icon}
                                        alt={"coin"}
                                      />{" "}
                                      {coins[minimumInvitedCoin - 1].name}
                                    </>
                                  ) : null}
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  style={{ minWidth: 130, width: 140 }}
                                >
                                  {coins.length > 0
                                    ? coins.map((coinItem, index) => (
                                        <Dropdown.Item
                                          disabled={
                                            minimumBonusCoins.indexOf(
                                              coinItem.id
                                            ) !== -1
                                          }
                                          key={index}
                                          onClick={() =>
                                            this.setState({
                                              minimumInvitedCoin: coinItem.id,
                                            })
                                          }
                                        >
                                          <img
                                            width={20}
                                            className={"mr-1"}
                                            src={coinItem.icon}
                                            alt={"coin"}
                                          />
                                          {coinItem.name}
                                        </Dropdown.Item>
                                      ))
                                    : null}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Form.Group>
                          </Col>
                          <Col lg={4} xs={12}>
                            <Button
                              variant={"success"}
                              disabled={
                                minimumInvitedBonus.length === 0 ||
                                waitingTime.length === 0
                              }
                              onClick={this.addToMinimumList}
                              className={"mt-4"}
                            >
                              Add
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col xs={12}>
                          <Table
                            className={"txt-light text-center "}
                            striped
                            bordered
                            hover
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Coin Type</th>
                                <th>Minimum Amount</th>
                                <th>Waiting Time</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {minimumList.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {coins[item.minimumInvitedCoin - 1].name}
                                  </td>
                                  <td>{item.minimumInvitedBonus}</td>
                                  <td>{item.waitingTime} Days</td>
                                  <td>
                                    <Button
                                      size={"sm"}
                                      onClick={() => {
                                        this.setState(
                                          { removeItem: index },
                                          () => {
                                            this.removeItemFromMinimumList();
                                          }
                                        );
                                      }}
                                      variant={"danger"}
                                    >
                                      X
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className={"text-right"}>
                  <Button
                    disabled={updateLoading || minimumList.length === 0}
                    onClick={this.submitPromotion}
                    className={"mt-4 btn-dark bg-dark mr-4"}
                  >
                    {updateLoading ? (
                      <Spinner
                        animation={"border"}
                        variant={"light"}
                        size={"sm"}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                  {enable ? (
                    <Button
                      disabled={stopLoading}
                      onClick={this.stopNow}
                      className={"mt-4 btn-danger bg-red"}
                    >
                      {stopLoading ? (
                        <Spinner
                          animation={"border"}
                          variant={"light"}
                          size={"sm"}
                        />
                      ) : (
                        "Stop Now"
                      )}
                    </Button>
                  ) : (
                    <Button
                      onClick={this.startNow}
                      disabled={stopLoading}
                      className={"mt-4 btn-success"}
                    >
                      {stopLoading ? (
                        <Spinner
                          animation={"border"}
                          variant={"light"}
                          size={"sm"}
                        />
                      ) : (
                        "Start Now"
                      )}
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default SetPromotion;
