import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Accordion,
  Card,
  Spinner,
} from "react-bootstrap";
import Rodal from "rodal";
import { permissionsApi, createNewRoleApi } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
export default class CreateRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedPermissions: [],
      permissions: [],
      roleName: "",
      Description: "",
      msg: "",
      loading: false,
    };
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
    this.handleRoleName = this.handleRoleName.bind(this);
    this.handleCreateNewRole = this.handleCreateNewRole.bind(this);
    this.handleRoleDescription = this.handleRoleDescription.bind(this);
  }
  componentDidMount() {
    this.getPermissions();
  }

  getPermissions() {
    permissionsApi().then((res) => {
      this.setState({ permissions: res.permissions });
    });
  }
  handleRoleName(event) {
    this.setState({ roleName: event.target.value });
  }
  handleRoleDescription(event) {
    this.setState({ Description: event.target.value });
  }
  handleCreateNewRole() {
    this.setState({ loading: true });
    const { roleName, selectedPermissions, Description } = this.state;
    createNewRoleApi(roleName, selectedPermissions, Description).then((res) => {
      res.code !== 400
        ? this.setState(
            {
              loading: false,
              msg: "Role registered successfully",
            },
            () => {
              this.props.getData();
            }
          )
        : this.setState({ loading: false, msg: res.message });
    });
  }
  selectAll = (event) => {
    const { selectedPermissions, permissions } = this.state;
    const target = event.target;
    let edited = permissions[target.value].map((k) => k.key);
    let per = selectedPermissions;
    if (target.checked) {
      per = per.concat(_.difference(edited, per));
    } else {
      per = _.pullAll(per, edited);
    }
    this.setState({ selectedPermissions: per });
  };
  handleSelectedChange(event) {
    let selectedPermissions = this.state.selectedPermissions;
    const target = event.target;

    target.checked
      ? selectedPermissions.push(target.value)
      : selectedPermissions.splice(
          selectedPermissions.indexOf(target.value),
          1
        );

    this.setState({
      selectedPermissions,
    });
  }

  render() {
    const {
      permissions,
      roleName,
      loading,
      Description,
      msg,
      selectedPermissions,
    } = this.state;
    return (
      <div>
        <Button
          onClick={() => {
            this.setState({ visible: true });
          }}
          size="sm"
          className={"bg-light border mb-4 btn-shaped px-3"}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Create New Role
        </Button>
        <Rodal
          width={800}
          duration={500}
          animation={"door"}
          visible={this.state.visible}
          onClose={() => {
            this.setState({ visible: false });
          }}
        >
          <Container className={"bg-dark p-4"}>
            <div className={"border-bottom border-secondary pb-1"}>
              <h5 className={"text-light mb-0"}>New Role</h5>
              <span className={"txt-light"}>Create New Roles</span>
            </div>
            <Form className={"mt-3 mb-0"}>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Role's Display Name :
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    value={roleName}
                    onChange={this.handleRoleName}
                    className={"rounded-0"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  Description :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    value={Description}
                    onChange={this.handleRoleDescription}
                    className={"rounded-0"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Accordion>
                {Object.keys(permissions).map((key, index) => (
                  <Card className={"bg-dark border-0 txt-light"}>
                    <Accordion.Toggle
                      className={"bg-light border-rounded mb-1"}
                      as={Card.Header}
                      eventKey={key}
                    >
                      {key.charAt(0).toUpperCase() + key.slice(1)} Permissions
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={key}>
                      <Row>
                        <Container>
                          <Row>
                            <Col>
                              <Form.Group controlId={"Select" + index}>
                                <Form.Check
                                  className={"small-text-1"}
                                  title={"Select All"}
                                  type="checkbox"
                                  onChange={this.selectAll}
                                  value={key}
                                  label={"Select All"}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Container>
                        {permissions[key].map((per) => (
                          <Col xs={"4"}>
                            <Form.Group id="formGridCheckbox">
                              <Form.Check
                                type="checkbox"
                                onChange={this.handleSelectedChange}
                                value={per.key}
                                label={per.title}
                                checked={
                                  selectedPermissions.indexOf(per.key) !== -1
                                }
                              />
                            </Form.Group>
                          </Col>
                        ))}
                      </Row>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Form>
            <Col>
              <span className={"pl-3 txt-orange"}>{msg}</span>
            </Col>
            <Col className={"text-right"}>
              <Button
                onClick={() => {
                  this.setState({ visible: false, msg: "" });
                }}
                size="sm"
                className={
                  "bg-light border mb-0 d-sm-none mr-1 btn-shaped px-3"
                }
              >
                Cancel
              </Button>
              <Button
                disabled={roleName.length < 3}
                size="sm"
                onClick={this.handleCreateNewRole}
                className={"bg-light border mb-3 mt-3 btn-shaped px-5"}
              >
                {loading ? (
                  <Spinner size={"sm"} animation="border" variant="light" />
                ) : (
                  "Apply Role"
                )}
              </Button>
            </Col>
          </Container>
        </Rodal>
      </div>
    );
  }
}
