import React, { Component } from "react";
import { totalOnlineUsersApi } from "../../utils";
import { Spinner } from "react-bootstrap";
import { Col } from "react-bootstrap";

export default class TotalOnlineUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalOnlineUsers: 0,
      loading: true,
    };
  }
  componentDidMount() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 10000);
  }
  getData() {
    totalOnlineUsersApi().then((res) => {
      this.setState({ totalOnlineUsers: res.total.count, loading: false });
    });
  }
  render() {
    const { totalOnlineUsers, loading } = this.state;
    if (loading)
      return (
        <div
          style={{
            height: 150,
            width: "33%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Spinner animation="grow" variant="light" />
        </div>
      );
    return (
      <Col xs={12} sm={6} md={4}>
        <h5 className={"no-margin"}>Total Online Users</h5>
        <div
          style={{ height: "85%" }}
          className=" bg-light rounded mt-2 d-flex justify-content-center align-items-center"
        >
          <h4 className={"no-margin txt-orange "}>{totalOnlineUsers}</h4>
        </div>
      </Col>
    );
  }
}
