import React, { Component } from 'react'
import { Row } from 'react-bootstrap'
import TotalBalance from './totalBalance'
import TotalReveneueEarnedBySend from './totalReveneueEarnedBySend'
import TotalReveneueEarnedByExchange from './totalRevenueEarnedByExchange'

export default class BalanceStatistics extends Component {
    render() {
        const { permissions } = this.props
        return (
            <Row className={'panel-rows'}>
                {permissions.indexOf('ADMIN_TRANSACTION_BALANCES') !== -1 ? (
                    <TotalBalance />
                ) : null}
                {permissions.indexOf('ADMIN_TRANSACTION_SENT') !== -1 ? (
                    <TotalReveneueEarnedBySend />
                ) : null}
                {permissions.indexOf('ADMIN_TRANSACTION_EXCHANGE') !== -1 ? (
                    <TotalReveneueEarnedByExchange />
                ) : null}
            </Row>
        )
    }
}
