import { Container, Button } from "react-bootstrap";
import React, { useState } from "react";

import Rodal from "rodal";
const WalletAddress = (props) => {
  const [visible, setShow] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setShow(!visible);
        }}
        disabled={props.walletAddresses.length === 0}
        style={
          props.walletAddresses.length === 0
            ? {
                cursor: "default",
              }
            : {}
        }
        className={"bg-dark-1 btn-dark rounded"}
      >
        ({props.walletAddresses.length}){" "}
        {props.walletAddresses.length < 2 ? "Address" : "Addresses"}
      </Button>
      <Rodal
        width={650}
        duration={500}
        animation={"door"}
        visible={visible}
        onClose={() => {
          setShow(!visible);
        }}
      >
        <Container className={"bg-dark p-4"}>
          <h6
            className={
              "text-light mb-0 border-bottom border-secondary pb-1 mb-3"
            }
          >
            Wallet Address
          </h6>
          <div style={{ overflow: "scroll", maxHeight: "80vh" }}>
            {props.walletAddresses.map((address, index) => (
              <h6 className={"text-left text-light pl-4"} key={index}>
                {address.address}
              </h6>
            ))}
          </div>
        </Container>
      </Rodal>
    </>
  );
};

export default WalletAddress;
