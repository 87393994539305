import React, { Component } from "react";
import Tree from "react-d3-tree";
import { invitedUsersApi } from "../../../utils";
import { Spinner } from "react-bootstrap";
const style = {
  links: { stroke: "#fff" },
  nodes: {
    node: {
      stroke: "#fff",
      circle: { color: "#ffa616", fill: "#ffa616" },
      name: { fill: "#fff", stroke: "#fff", fontSize: ".7em" },
      attributes: { color: "#fff", fill: "#fff" },
    },
    leafNode: {
      circle: { stroke: "#ffa616", fill: "#282e41" },
      name: {
        fill: "#fff",
        stroke: "#fff",
        textAlign: "center",
        fontSize: ".65em",
      },
    },
  },
};
class UserInvited extends Component {
  state = {
    loading: true,
    data: [],
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    invitedUsersApi(this.props.id).then((res) => {
      this.setState({ data: res.invites, loading: false });
    });
  };
  render() {
    const { data, loading } = this.state;
    if (loading)
      return (
        <div
          className={
            "my-5 py-5 d-flex justify-content-center align-content-center"
          }
        >
          <Spinner size={"lg"} animation="grow" variant="light" />
        </div>
      );
    return (
      <div style={{ width: "100%", height: "40em" }}>
        <Tree
          textLayout={{
            textAnchor: "middle",
            y: 20,
            transform:"rotate(-15)"
          }}
          data={{ name: "", children: data }}
          orientation={"vertical"}
          translate={{ x: 500, y: 100 }}
          styles={style}
        />
      </div>
    );
  }
}

export default UserInvited;
