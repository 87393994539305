import React, { Component } from "react";
import moment from "moment";
import { Row, Col, Dropdown, Form, FormControl } from "react-bootstrap";
import Pagination from "react-js-pagination";
import DataTable from "../../components/dataTable";
import {
  transactionsListApi,
  coinsApi,
  transactionsSearchApi,
  trCSVApi,
} from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";

export default class History extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    )
      .add(1, "days")
      .subtract(1, "seconds");
    this.state = {
      page: 1,
      maxPerPage: 25,
      totalPages: 1,
      totalItems: 1,
      startDate: start,
      endDate: end,
      coinType: "",
      data: [],
      type: "",
      search: "",
      pItems: [],
      loading: true,
      coins: [],
      message: "",
      network: "main",
      time:()=>{}
    };
  }
  componentDidMount() {
    this.getData();
    let time = setInterval(() => {
      this.getDataMain();
    }, 10 * 1000);
    this.setState({time})
  }
  componentWillUnmount() {
    clearInterval(this.state.time)
  }
  getData = () => {
    this.setState({ loading: true });
    this.getDataMain();
  };
  getDataMain = () => {
    const { maxPerPage, page, coinType, startDate, endDate, type, network } = this.state;
    transactionsListApi(
      page,
      maxPerPage,
      coinType,
      moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      type,
      network
    ).then((res) => {
      this.setState({
        data: res.transactionHistory,
        totalPages: res.total_pages,
        totalItems: res.total_items,
        maxPerPage: res.items_per_page,
        page: res.page,
        loading: false,
        message: "",
      });
    });
    coinsApi("all").then((res) => {
      this.setState({ coins: res });
    });
  };
  applyCallback = (startDate, endDate) => {
    this.setState(
      {
        startDate,
        endDate,
        page: 1,
      },
      () => {
        this.getData();
      }
    );
  };
  csvDownload = () => {
    const { startDate, endDate, coinType, type } = this.state;
    trCSVApi(
      moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      coinType,
      type
    ).then();
  };
  getSearchData = () => {
    this.setState({ loading: true });
    transactionsSearchApi(this.state.search).then((res) => {
      if (res.code === 200) {
        this.setState({
          data: res.res.transactionHistory,
          totalPages: res.res.total_pages,
          totalItems: res.res.total_items,
          maxPerPage: res.res.items_per_page,
          page: res.res.page,
          loading: false,
          message: "",
        });
      } else {
        this.setState({ message: res.res.error.message });
      }
      clearInterval(this.state.time)
    });
  };
  handleMaxPerPageChange = (event) => {
    this.setState({ maxPerPage: event.target.value, page: 1 }, () => {
      this.getData();
    });
  };
  handleSearchChange = (event) => {
    this.setState({ search: event.target.value }, () => {
      !this.state.search.length ? this.getData() : this.getSearchData();
    });
  };
  handlePageChange = (pageNumber) => {
    console.log(pageNumber)
    this.setState({ page: pageNumber }, () => {
      this.getData();
    });
  };
  handlePageChangeTop = (e) => {
    console.log(e.target.value)
    this.setState({ page: e.target.value }, () => {
      this.getData();
    });
  };
  pagination = () => {
    const { totalPages } = this.state;
    let pagination = [];
    for (let i = 1; i <= totalPages; i++) {
      pagination.push(
        <option key={i} value={i}>
          Page {i} of {totalPages}
        </option>
      );
    }
    return pagination;
  };

  render() {
    const {
      startDate,
      endDate,
      data,
      loading,
      totalItems,
      maxPerPage,
      page,
      coinType,
      coins,
      type,
      message,
      network,
    } = this.state;
    let ranges = {
      Daily: [moment(Date.now()), moment(endDate)],
      Weekly: [moment(Date.now()).subtract(1, "weeks"), moment(endDate)],
      Monthly: [moment(Date.now()).subtract(1, "months"), moment(endDate)],
      Yearly: [moment(Date.now()).subtract(1, "years"), moment(endDate)],
    };
    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };
    return (
      <div>
        <Col xs={12}>
          <Row className={"panel-rows history"}>
            <h5 className={"no-margin"}>History</h5>
          </Row>
          <Row className={"panel-rows"}>
            <Col className={"mt-2"} xs={6} lg={2}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="wallet"
                  className={"bg-light text-light"}
                  id="dropdown-basic"
                >
                  {coinType === "" ? "All Coins" : coinType}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      this.setState({ coinType: "" }, () => {
                        this.getData();
                      })
                    }
                  >
                    All Coins
                  </Dropdown.Item>
                  {coins.length > 0
                    ? coins.map((coin, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            this.setState(
                              {
                                coinType: coin.symbol,
                                page: 1
                              },
                              () => {
                                this.getData();
                              }
                            )
                          }
                        >
                          <img
                            width={20}
                            className={"mr-1"}
                            src={coin.icon}
                            alt={"coin"}
                          />{" "}
                          {coin.name}
                        </Dropdown.Item>
                      ))
                    : null}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className={"mt-2"} xs={6} lg={2}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="wallet"
                  className={"bg-light text-light"}
                  id="dropdown-type"
                >
                  {type.length === 0
                    ? "All Types"
                    : type.charAt(0).toUpperCase() + type.slice(1)}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ minWidth: 130, width: 140 }}>
                  <Dropdown.Item
                    onClick={() =>
                      this.setState({ type: "" }, () => {
                        this.getData();
                      })
                    }
                  >
                    All Types
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() =>
                      this.setState({ type: "received",page: 1}, () => {
                        this.getData();
                      })
                    }
                  >
                    Received
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      this.setState({ type: "sent", page: 1}, () => {
                        this.getData();
                      })
                    }
                  >
                    Sent
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className={"mt-2"} xs={6} lg={2}>
              <Dropdown>
                <Dropdown.Toggle
                  variant="wallet"
                  className={"bg-light text-light"}
                  id="dropdown-type"
                >
                  {network}
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ minWidth: 130, width: 140 }}>

                  <Dropdown.Item
                    onClick={() =>
                      this.setState({ network: "main",page: 1 }, () => {
                        this.getData();
                      })
                    }
                  >
                    Main
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      this.setState({ network: "testnet",page: 1 }, () => {
                        this.getData();
                      })
                    }
                  >
                    Testnet
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className={"mt-2"} lg={3} xs={12}>
              <DateTimeRangeContainer
                ranges={ranges}
                start={startDate}
                end={endDate}
                local={local}
                applyCallback={this.applyCallback}
              >
                <FormControl
                  id="formControlsTextB"
                  type="text"
                  label="Text"
                  className={"text-center"}
                  value={
                    moment(startDate).format("YYYY-MM-DD") +
                    " -> " +
                    moment(endDate).format("YYYY-MM-DD")
                  }
                />
              </DateTimeRangeContainer>
            </Col>
            <Col className={"mt-2"}>
              <Form.Control
                onChange={this.handleSearchChange}
                className={"ml-1 rounded-0"}
                type={"text"}
                placeholder="Transaction Hash	"
              />
            </Col>
            <div className={"ml-2 mt-2"}>
              <FontAwesomeIcon
                onClick={this.csvDownload}
                style={{
                  fontSize: "2.4em",
                  color: "green",
                  cursor: "pointer",
                }}
                icon={faFileCsv}
              />
              ;
            </div>
          </Row>
        </Col>
        <div className={"member-main"}>
          <Row className={"table-head-bar  mt-4"}>
            <Col lg={3} xs={12} className={"panel-rows p-0 pl-3 "}>
              <h5 className={"no-margin"}>All Transactions</h5>
            </Col>
            <Col xs={12} lg={9}>
              <Row>
                <Col xs={12} lg={5} />
                <Col xs={7} lg={3} className={"text-right"}>
                  <span className={"p-0 px-1 text-light"}>
                    {totalItems} Records Total
                  </span>
                  <span className={"text-light ml-1 d-none d-sm-inline"}>
                    {" "}
                    |{" "}
                  </span>
                </Col>
                <Col xs={6} lg={2} className={"px-1"}>
                  <Form.Control
                    value={maxPerPage}
                    onChange={this.handleMaxPerPageChange}
                    size="sm"
                    as="select"
                  >
                    <option value={25}>View 25 Per Page</option>
                    <option value={50}>View 50 Per Page</option>
                    <option value={100}>View 100 Per Page</option>
                  </Form.Control>
                  <span className={"text-light ml-1"}> | </span>
                </Col>
                <Col xs={6} lg={2} className={"px-1"}>
                  <Form.Control
                    value={page}
                    onChange={this.handlePageChangeTop}
                    size="sm"
                    as="select"
                  >
                    {this.pagination()}
                  </Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {!message.length ? (
          !loading ? (
            <DataTable
              userProfile={
                this.props.user.permissions.indexOf("ADMIN_SHOW_USER") !== -1
              }
              data={data}
            />
          ) : (
            <span />
          )
        ) : (
          <h6 className={"text-center txt-red"}>{message}</h6>
        )}
        <Row className={"d-flex justify-content-center align-items-center"}>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            onChange={this.handlePageChange}
            activePage={page}
            itemsCountPerPage={maxPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={10}
          />
        </Row>
      </div>
    );
  }
}
