import { Col, Dropdown, Form, Row, Spinner } from "react-bootstrap";
import DataTableComponent from "./dataTableComponent";
import Pagination from "react-js-pagination";
import React from "react";
import { coinsApi, userWalletsApi, userWalletsCSVApi } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

class UserWallet extends React.Component {
  state = {
    coinType: "",
    conins: [],
    loading: true,
    page: 1,
    maxPerPage: 25,
    totalPages: 1,
    totalItems: 1,
    data: [],
    walletName: "",
    tableLoading: false,
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      this.getData();
    });
  };
  handleMaxPerPageChange = (event) => {
    this.setState({ maxPerPage: event.target.value }, () => {
      this.getData();
    });
  };
  handleMaxWalletNameChange = (event) => {
    this.setState({ walletName: event.target.value }, () => {
      this.getData();
    });
  };
  componentDidMount() {
    coinsApi("all").then((res) => {
      this.setState({ coins: res }, () => {
        this.getData();
      });
    });
  }
  getData = () => {
    const { coinType, page, maxPerPage, walletName } = this.state;
    this.setState({ tableLoading: true, page: 1 }, () => {
      userWalletsApi(
        this.props.id,
        page,
        maxPerPage,
        coinType,
        walletName
      ).then((res) => {
        this.setState({
          loading: false,
          data: res.wallets,
          maxPerPage: res.items_per_page,
          page: res.page,
          totalPages: res.total_pages,
          totalItems: res.total_items,
          tableLoading: false,
        });
      });
    });
  };
  downloadCsv = () => {
    const { coinType, page, maxPerPage, walletName } = this.state;
    userWalletsCSVApi(
      this.props.id,
      page,
      maxPerPage,
      coinType,
      walletName
    ).then();
  };
  render() {
    const {
      coins,
      coinType,
      loading,
      page,
      maxPerPage,
      totalItems,
      data,
      tableLoading,
    } = this.state;
    if (loading)
      return (
        <div
          className={
            "my-5 py-5 d-flex justify-content-center align-content-center"
          }
        >
          <Spinner size={"lg"} animation="grow" variant="light" />
        </div>
      );
    return (
      <div>
        <Row>
          <Col className={"mt-2"} lg={3} xs={6}>
            <Form.Group>
              <Form.Control
                onChange={this.handleMaxWalletNameChange}
                type={"text"}
                placeholder={"Wallet Name"}
              />
            </Form.Group>
          </Col>
          <Col className={"mt-2"} lg={3} xs={6}>
            <Dropdown>
              <Dropdown.Toggle
                variant="wallet"
                className={"bg-light text-light"}
                id="dropdown-basic"
              >
                {coinType !== "" ? (
                  coins.length > 0 ? (
                    <>
                      <img
                        width={20}
                        className={"mr-1"}
                        src={
                          coins[
                            coins.find(({ symbol }) => symbol === coinType).id -
                              1
                          ].icon
                        }
                        alt={"coin"}
                      />{" "}
                      {
                        coins[
                          coins.find(({ symbol }) => symbol === coinType).id - 1
                        ].name
                      }
                    </>
                  ) : null
                ) : (
                  "All Coins"
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ coinType: "" }, () => {
                      this.getData();
                    })
                  }
                >
                  All Coins
                </Dropdown.Item>
                {!loading
                  ? coins.length > 0
                    ? coins.map((coin, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            this.setState(
                              {
                                coinType: coin.symbol,
                              },
                              () => {
                                this.getData();
                              }
                            )
                          }
                        >
                          <img
                            width={20}
                            className={"mr-1"}
                            src={coin.icon}
                            alt={"coin"}
                          />{" "}
                          {coin.name}
                        </Dropdown.Item>
                      ))
                    : null
                  : null}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col className={"mt-2"} lg={3} xs={6}>
            <Form.Group>
              <Form.Control
                value={maxPerPage}
                onChange={this.handleMaxPerPageChange}
                as="select"
              >
                <option value={25}>View 25 Per Page</option>
                <option value={50}>View 50 Per Page</option>
                <option value={100}>View 100 Per Page</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <span onClick={this.downloadCsv}>
              <FontAwesomeIcon
                style={{
                  cursor: "pointer",
                  fontSize: "2.4em",
                  color: "green",
                }}
                icon={faFileCsv}
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            {!tableLoading ? (
              <DataTableComponent
                headers={[
                  "Wallet Name",
                  "Balance",
                  "Coin Type",
                  "Address List",
                  "Created At",
                ]}
                data={data}
              />
            ) : (
              <div
                className={
                  "my-5 py-5 d-flex justify-content-center align-content-center"
                }
              >
                <Spinner size={"lg"} animation="grow" variant="light" />
              </div>
            )}
          </Col>
        </Row>
        <Row className={"d-flex justify-content-center align-items-center"}>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={page}
            itemsCountPerPage={maxPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange}
          />
        </Row>
      </div>
    );
  }
}
export default UserWallet;
