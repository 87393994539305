import React, { Component } from 'react'
import { totalUserRegisterApi } from '../../utils'
import { Spinner } from 'react-bootstrap'
import BarChart from '../../components/barChart'
export default class TotalUserRegisterVerified extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalUserRegisterVerifiedCount: 0,
            totalUserRegisterVerifiedData: [],
            total: [],
            loading: true,
        }
    }
    componentDidMount() {
        this.getData()
        setInterval(() => {
            this.getData()
        }, 10000)
    }
    getData() {
        totalUserRegisterApi(true).then((res) => {
            let totalUserRegisterVerifiedData = []
            let totalUserRegisterVerifiedCount = 0
            for (let i = 0; i < 7; i++) {
                totalUserRegisterVerifiedData.push({
                    x: i,
                    y: Number(res.total[i].count),
                })
                totalUserRegisterVerifiedCount =
                    totalUserRegisterVerifiedCount + Number(res.total[i].count)
            }
            this.setState({
                totalUserRegisterVerifiedCount,
                totalUserRegisterVerifiedData,
                total: res.total,
                loading: false,
            })
        })
    }
    render() {
        const {
            total,
            totalUserRegisterVerifiedCount,
            totalUserRegisterVerifiedData,
            loading,
        } = this.state
        if (loading)
            return (
                <div
                    style={{
                        height: 150,
                        width: '33%',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Spinner animation="grow" variant="light" />
                </div>
            )
        return (
            <BarChart
                title={'Total Verified Users'}
                days={total}
                count={totalUserRegisterVerifiedCount}
                data={totalUserRegisterVerifiedData}
            />
        )
    }
}
