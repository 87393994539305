import { Col, Form, Row, Spinner } from "react-bootstrap";
import Pagination from "react-js-pagination";
import React from "react";
import DataTableUserSession from "./dataTableUserSession";
import { userSessionApi } from "../../../utils";

class UserSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      maxPerPage: 25,
      totalPages: 1,
      totalItems: 1,
      data: [],
    };
  }
  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      this.getData();
    });
  };
  handleMaxPerPageChange = (event) => {
    this.setState({ maxPerPage: event.target.value, page: 1 }, () => {
      this.getData();
    });
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, maxPerPage } = this.state;
    this.setState({ loading: true });
    userSessionApi(this.props.id, page, maxPerPage).then((res) => {
      this.setState({
        loading: false,
        data: res.sessions,
        maxPerPage: res.items_per_page,
        page: res.page,
        totalPages: res.total_pages,
        totalItems: res.total_items,
      });
    });
  };
  render() {
    const { data, loading, page, maxPerPage, totalItems } = this.state;

    if (loading)
      return (
        <div
          className={
            "my-5 py-5 d-flex justify-content-center align-content-center"
          }
        >
          <Spinner size={"lg"} animation="grow" variant="light" />
        </div>
      );
    return (
      <div>
        <Row>
          <Col className={"mt-2"} lg={3} xs={6}>
            <Form.Group>
              <Form.Control
                value={maxPerPage}
                onChange={this.handleMaxPerPageChange}
                as="select"
              >
                <option value={25}>View 25 Per Page</option>
                <option value={50}>View 50 Per Page</option>
                <option value={100}>View 100 Per Page</option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTableUserSession
              headers={[
                "Platform",
                "Device Model",
                "IP",
                "Created At",
                "Updated At",
                "Revoked At",
                "First Device",
              ]}
              data={data}
            />
          </Col>
        </Row>
        <Row className={"d-flex justify-content-center align-items-center"}>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            onChange={this.handlePageChange}
            activePage={page}
            itemsCountPerPage={maxPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={10}
          />
        </Row>
      </div>
    );
  }
}
export default UserSessions;
