import React, { Component } from "react";
import { VictoryBar } from "victory";
import { Col } from "react-bootstrap";

export default class BarChart extends Component {
  render() {
    const { days, title, data, count } = this.props;
    return (
      <Col xs={12} sm={6} md={4}>
        <h5 className={"no-margin"}>{title}</h5>
        <div id={"barchart"} className="bg-light rounded mt-2">
          <div className={"pt-1 pl-3 mb-3 d-flex justify-content-between"}>
            <h4 className={"no-margin txt-orange"}>
              <span>{this.props.price ? "$" : ""}</span>
              {this.props.price ? Number(count).toFixed(2) : count}
            </h4>
            <h6 className={"no-margin text-light pt-2 pr-5 font-weight-light"}>
              Weekly
            </h6>
          </div>
          <div>
            <VictoryBar
              padding={{
                top: 28,
                bottom: 10,
                right: 32,
                left: 32,
              }}
              labels={({ datum }) =>
                `${
                  this.props.price ? "$" + parseInt(datum.y) : parseInt(datum.y)
                }`
              }
              height={100}
              cornerRadius={{ top: ({ datum }) => 2 }}
              barWidth={({ index }) => 5}
              style={{
                data: {
                  fill: "#f7931a",
                },
                labels: {
                  fill: "#f7931a",
                },
              }}
              animate={{
                duration: 2000,
                onLoad: { duration: 2000 },
              }}
              data={data}
            />
            <div className={"d-flex justify-content-around chart-label"}>
              {days
                ? days.map((total, index) => (
                    <label key={index} className={"mb-0 txt-light text-center"}>
                      {total.day}
                    </label>
                  ))
                : null}
            </div>
          </div>
        </div>
      </Col>
    );
  }
}
