const appVersion = 90000;
const host = process.env.REACT_APP_API_URL;
const gateWay =process.env.REACT_APP_GATEWAY_TOKEN;
function Token(){ return "Bearer " + localStorage.getItem("token")}

function headers(){
  return gateWay!==undefined?
       {
        "X-Gateway-Api-Key":gateWay,
        "X-App-Version": appVersion,
        Accept: "application/json",
        Authorization: Token(),
        "Content-Type": "application/json",
      }
  :
   {
    "X-App-Version": appVersion,
    Accept: "application/json",
    Authorization: Token(),
    "Content-Type": "application/json",
  }
}
export function isLogin() {
  return localStorage.getItem("token");
}
export async function userApi() {
  let response = await fetch(host + "/api/user", {
    method: "GET",
    headers: headers(),
  });
  let res = await response.json();
  if (res.id) {
    return res;
  } else {
    localStorage.removeItem("token");
    return false;
  }
}
export async function LoginApi(username, password,captcha_token) {
  let response = await fetch(host + "/api/login", {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ username, password,captcha_token }),
  });
  let res = await response.json();
  if (response.status === 200) {
    localStorage.setItem("token", res.token);
    return res;
  } else if (response.status === 401) {
    return res;
  } else {
    return res;
  }
}
export async function TwoFaLoginApi(code,password) {
  let response = await fetch(host + "/api/2fa/googleAuthenticator", {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ code,password }),
  });
  let res = await response.json();
  if (response.status === 200) {
    localStorage.setItem("token", res.token);
    return res;
  } else if (response.status === 400) {
    return 400;
  } else {
    return res.error;
  }
}
export async function RegisterApi(
  email,
  username,
  full_name,
  invited_by,
  first,
  second
) {
  let response = await fetch(host + "/api/register", {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({
      email,
      username,
      full_name,
      invited_by,
      device_type: "Web",
      plainPassword: { first, second },
    }),
  });
  let res = await response.json();
  if (response.status === 201) {
    localStorage.setItem("token", res.token);
    localStorage.setItem("name", res.data.user.full_name);

    return res;
  } else {
    return res.msg;
  }
}
export async function userListApi(page, maxPerPage, role, q) {
  let response = await fetch(
    host +
      encodeURI(
        `/api/admin/users?page=${page}&maxPerPage=${maxPerPage}&q=${q}&role=${role}`
      ),
    {
      method: "GET",
      headers: headers(),
    }
  );
  let res = await response.json();
  return res;
}
export async function rolesListApi(page, maxPerPage) {
  let response = await fetch(
    host + `/api/admin/roles?page=${page}&maxPerPage=${maxPerPage}`,
    {
      method: "GET",
      headers: headers(),
    }
  );
  let res = await response.json();
  return res;
}

export async function addAdminRole(users) {
  let response = await fetch(host + `/api/admin/roles/addMember`, {
    method: "PATCH",
    headers: headers(),
    body: JSON.stringify({ users }),
  });
  let res = await response.json();
  return res;
}
export async function removeAdminRoleApi(users) {
  let response = await fetch(host + `/api/admin/roles/removeMember`, {
    method: "PATCH",
    headers: headers(),
    body: JSON.stringify({ users }),
  });
  let res = await response.json();
  return res;
}

export async function userEditApi(user) {
  let response = await fetch(host + `/api/admin/users/${user.id}/edit`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(user),
  });
  console.log(user);
  let res = await response.json();

  return res;
}
export async function TwoFaSendCodeApi(password, status) {
  let response = await fetch(
    host + `/api/user/2fa/googleAuthenticator/status`,
    {
      method: "POST",
      headers: headers(),
      body: JSON.stringify({ password, status }),
    }
  );
  let res = await response.json();

  return { res, code: response.status };
}
export async function createUserApi(user) {
  console.log(user);
  let response = await fetch(host + `/api/admin/users/new`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(user),
  });
  let res = await response.json();

  return { res, code: response.status };
}
export async function permissionsApi() {
  let response = await fetch(host + `/api/admin/roles/permissions`, {
    method: "GET",
    headers: headers(),
  });
  let res = await response.json();
  return res;
}
export async function createNewRoleApi(role, permissions, description) {
  let response = await fetch(host + `/api/admin/roles/new`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({
      role,
      description,
      permissions,
    }),
  });
  let res = await response.json();
  return res;
}
export async function setRoleApi(users, role) {
  let response = await fetch(host + `/api/admin/roles/${role}/adduser`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({ users }),
  });
  let res = await response.json();
  return res;
}
export async function modifyRoleApi(id, role, permissions, description) {
  let response = await fetch(host + `/api/admin/roles/${id}/edit`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({
      role,
      permissions,
      description,
    }),
  });
  let res = await response.json();
  return res;
}
export async function coinsApi(type) {
  let response = await fetch(
    host + `/api/cointypes${type != null ? "?type=" + type : ""}`,
    {
      method: "GET",
      headers: headers(),
    }
  );
  let res = await response.json();
  return res;
}

export async function coinsChartApi(coin) {
  let response = await fetch(host + `/api/coins/markets/${coin}`, {
    method: "GET",
    headers: headers(),
  });
  let res = await response.json();
  return res;
}
export async function totalUserRegisterApi(verified) {
  let response = await fetch(
    host + `/api/admin/users/total_registers${verified ? "?verified=1" : ""}`,
    {
      method: "GET",
      headers: headers(),
    }
  );
  let res = await response.json();
  return res;
}
export async function totalOnlineUsersApi() {
  let response = await fetch(host + `/api/admin/users/total_onlines`, {
    method: "GET",
    headers: headers(),
  });
  let res = await response.json();
  return res;
}
export async function transactionsListApi(
  page,
  maxPerPage,
  coinType,
  minDate,
  maxDate,
  type,
  network
) {
  let response = await fetch(
    host +
      `/api/admin/transactions/history?page=${page}&maxPerPage=${maxPerPage}&minDate=${minDate}&maxDate=${maxDate}&coinType=${coinType}&type=${type}&network=${network}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function transactionsSearchApi(q) {
  let response = await fetch(
    host + `/api/admin/transactions/history/search?q=${q}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return { res, code: response.status };
}
export async function totalBalanceApi() {
  let response = await fetch(host + `/api/admin/transactions/total/balances`, {
    method: "GET",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
export async function totalReveneueEarnedBySendApi() {
  let response = await fetch(
    host + `/api/admin/transactions/total/revenue/send`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function totalReveneueEarnedByExchangeApi() {
  let response = await fetch(
    host + `/api/admin/transactions/total/revenue/exchange`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function customerBalanceApi() {
  let response = await fetch(host + `/api/admin/wallets/customer_balance`, {
    method: "GET",
    headers: headers(),
  });

  let res = await response.json();
  return {res, code: response.status };
}

export async function xapaBalanceApi() {
  let response = await fetch(host + `/api/admin/wallets/xapa_balance`, {
    method: "GET",
    headers: headers(),
  });
  if (response.status !== 200) {
    return false;
  }
  let res = await response.json();
  return res;
}
export async function roleDeleteApi(id) {
  let response = await fetch(host + `/api/admin/roles/${id}`, {
    method: "DELETE",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
export async function appVersionList(page, maxPerPage) {
  let response = await fetch(
    host + `/api/admin/app_versions?page=${page}&maxPerPage=${maxPerPage}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function deleteAppVersionApi(id) {
  let response = await fetch(host + `/api/admin/app_versions/${id}`, {
    method: "DELETE",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
export async function appVersionGroupList(page, maxPerPage) {
  let response = await fetch(host + `/api/admin/app_versions/groups`, {
    method: "GET",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
export async function appVersionAddApi(
  name,
  releaseDate,
  platform,
  changeLog,
  forceUpdateRequired
) {
  let response = await fetch(host + `/api/admin/app_versions/new`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({
      name,
      releaseDate,
      changeLog,
      platform,
      forceUpdateRequired,
    }),
  });

  let res = await response.json();
  return res;
}
export async function promotionApi() {
  let response = await fetch(host + `/api/admin/promotion`, {
    method: "GET",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
export async function postPromotionApi(data) {
  let response = await fetch(host + `/api/admin/promotion`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return res;
}
export async function userAttributeApi(id) {
  let response = await fetch(host + `/api/admin/users/${id}`, {
    method: "GET",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
export async function financialBrief(id) {
  let response = await fetch(host + `/api/admin/users/brief/${id}`, {
    method: "GET",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
export async function userReport(
  id,
  page,
  maxPerPage,
  minDate,
  maxDate,
  coinType,
  type
) {
  let response = await fetch(
    host +
      `/api/admin/users/report/${id}?page=${page}&maxPerPage=${maxPerPage}&coinType=${coinType}&minDate=${minDate}&maxDate=${maxDate}&type=${type}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function bonusReportApi(page, maxPerPage, minDate, maxDate, user) {
  let response = await fetch(
    host +
      `/api/admin/wallets/report?page=${page}&maxPerPage=${maxPerPage}&minDate=${minDate}&maxDate=${maxDate}&user=${user}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function userTrCSVApi(id, start, end, coinType, type) {
  let response = await fetch(
    host +
      `/api/admin/users/report/${id}/CSV?coinType=${coinType}&minDate=${start}&maxDate=${end}&type=${type}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `UserReport(id: ${id}).CSV`;
    a.click();
  });
}
export async function exportReportApi(
  page,
  maxPerPage,
  minDate,
  maxDate,
  user
) {
  let response = await fetch(
    host +
      `/api/admin/exchange/history?page=${page}&maxPerPage=${maxPerPage}&minDate=${minDate}&maxDate=${maxDate}&user=${user}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function exchangeCSVApi(minDate, maxDate, user) {
  let response = await fetch(
    host +
      `/api/admin/exchange/history/CSV?minDate=${minDate}&maxDate=${maxDate}&user=${user}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `Exchange.CSV`;
    a.click();
  });
}
export async function trCSVApi(start, end, coinType, type) {
  let response = await fetch(
    host +
      `/api/admin/transactions/history/CSV?minDate=${start}&maxDate=${end}&coinType=${coinType}&type=${type}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `transactions.CSV`;
    a.click();
  });
}
export async function userWalletsApi(
  id,
  page,
  maxPerPage,
  coinType,
  walletName
) {
  let response = await fetch(
    host +
      `/api/admin/users/wallets/${id}?page=${page}&maxPerPage=${maxPerPage}&coinType=${coinType}&wallet_name=${walletName}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function userWalletsCSVApi(
  id,
  page,
  maxPerPage,
  coinType,
  walletName
) {
  let response = await fetch(
    host +
      `/api/admin/users/wallets/${id}/CSV?coinType=${coinType}&wallet_name=${walletName}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `UserWallet(id: ${id}).CSV`;
    a.click();
  });
}
export async function bonusCSVApi(start, end, user) {
  let response = await fetch(
    host +
      `/api/admin/wallets/report/CSV?minDate=${start}&maxDate=${end}&user=${user}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = `BonusReport(${end} -> ${end} ${user}).CSV`;
    a.click();
  });
}
export async function accountingUserApi(start, end) {
  let response = await fetch(
    encodeURI(
      host + `/api/admin/users/accounting?minDate=${start}&maxDate=${end}`
    ),
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function accountingFinancialApi(start, end) {
  let response = await fetch(
    host + `/api/admin/transactions/accounting?minDate=${start}&maxDate=${end}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function invitedUsersApi(id) {
  let response = await fetch(host + `/api/admin/users/invites/${id}`, {
    method: "GET",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
export async function userSessionApi(id, page, maxPerPage) {
  let response = await fetch(
    host +
      `/api/admin/users/sessions/${id}?page=${page}&maxPerPage=1${maxPerPage}`,
    {
      method: "GET",
      headers: headers(),
    }
  );

  let res = await response.json();
  return res;
}
export async function sendEmailApi(id) {
  let response = await fetch(host + `/api/admin/email/${id}/sendmail`, {
    method: "GET",
    headers: headers(),
  });

  return response;
}
export async function addTokenApi(data) {
  let response = await fetch(host + `/api/admin/coins/new`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return res;
}
export async function editTokenApi(data) {
  let response = await fetch(host + `/api/admin/coins/${data.id}/edit`, {
    method: "POST",
    headers: headers(),
    body: JSON.stringify(data),
  });

  let res = await response.json();
  return res;
}
export async function getBlackList() {
  let response = await fetch(host + `/api/admin/exchange/blackList`, {
    method: "GET",
    headers: headers(),
  });
  let res = await response.json();
  return { res, code: response.status };
}
export async function deleteBlackList(id) {
  let response = await fetch(host + `/api/admin​/exchange​/blackList​/${id}`, {
    method: "DELETE",
    headers: headers(),
  });

  let res = await response.json();
  return res;
}
