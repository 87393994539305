import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import Logo from './../../assets/png/logo.png'
import LoginForm from './login'
import RegisterForm from './register'
import ResetForm from './reset'
export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            display: 'd-login',
        }
        this.loginHandler = this.loginHandler.bind(this)
        this.registerHandler = this.registerHandler.bind(this)
        this.resetHandler = this.resetHandler.bind(this)
    }
    loginHandler() {
        this.setState({ display: 'd-login' })
    }
    registerHandler() {
        this.setState({ display: 'd-register' })
    }
    resetHandler() {
        this.setState({ display: 'd-reset' })
    }
    render() {
        return (
            <Container>
                <div className={'login-logo text-center mt-3'}>
                    <img alt="logo" src={Logo} width={80} />
                </div>
                <div className={'d-flex justify-content-center mt-3'}>
                    <div
                        className={`login-form bg-light text-light rounded p-4 ${this.state.display}`}
                    >
                        <LoginForm
                            registerHandler={this.registerHandler}
                            resetHandler={this.resetHandler}
                        />
                        <RegisterForm loginHandler={this.loginHandler} />
                        <ResetForm loginHandler={this.loginHandler} />
                    </div>
                </div>
            </Container>
        )
    }
}
