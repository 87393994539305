import React, { Component } from "react";
import { Col, Container, FormControl, Row, Spinner } from "react-bootstrap";
import AccountingUser from "./accountingUser";
import AccountingFinancial from "./accountingFinancial";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import { accountingFinancialApi, accountingUserApi } from "../../../utils";

class XapaReport extends Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(Date.now()).subtract(1, "weeks");
    let end = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    )
      .add(1, "days")
      .subtract(1, "seconds");
    this.state = {
      start: start,
      end: end,
      accountingUser: {},
      accountingFinancial: {},
      loading: true,
    };
  }
  applyCallback = (startDate, endDate) => {
    this.setState(
      {
        start: startDate,
        end: endDate,
      },
      () => {
        this.getData();
      }
    );
  };
  getData = () => {
    const { start, end } = this.state;
    this.setState({ loading: true });
    accountingUserApi(
      moment(start).format("YYYY-MM-DD HH:mm:ss"),
      moment(end).format("YYYY-MM-DD HH:mm:ss")
    ).then((res) => {
      this.setState({ accountingUser: res.accounting }, () => {
        accountingFinancialApi(
          moment(start).format("YYYY-MM-DD HH:mm:ss"),
          moment(end).format("YYYY-MM-DD HH:mm:ss")
        ).then((res) => {
          this.setState({
            accountingFinancial: res.accounting,
            loading: false,
          });
        });
      });
    });
  };
  componentDidMount() {
    this.getData();
  }

  render() {
    const {
      start,
      end,
      accountingUser,
      accountingFinancial,
      loading,
    } = this.state;
    let ranges = {
      Daily: [moment(Date.now()), moment(end)],
      Weekly: [moment(Date.now()).subtract(1, "weeks"), moment(end)],
      Monthly: [moment(Date.now()).subtract(1, "months"), moment(end)],
      Yearly: [moment(Date.now()).subtract(1, "years"), moment(end)],
    };
    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };
    if (loading)
      return (
        <div
          className={
            "my-5 py-5 d-flex justify-content-center align-content-center"
          }
        >
          <Spinner size={"lg"} animation="grow" variant="light" />
        </div>
      );
    return (
      <Container className={"mt-4 mb-5"}>
        <Row>
          <Container className={"d-flex justify-content-center"}>
            <Col lg={4} xs={10}>
              <div className={"mb-4"}>
                <DateTimeRangeContainer
                  ranges={ranges}
                  start={start}
                  end={end}
                  local={local}
                  applyCallback={this.applyCallback}
                >
                  <FormControl
                    className={"text-center"}
                    value={
                      moment(start).format("YYYY-MM-DD") +
                      " -> " +
                      moment(end).format("YYYY-MM-DD")
                    }
                  />
                </DateTimeRangeContainer>
              </div>
            </Col>
          </Container>
        </Row>
        <Row>
          <AccountingUser accountingUser={accountingUser} />
          <AccountingFinancial accountingFinancial={accountingFinancial} />
        </Row>
      </Container>
    );
  }
}

export default XapaReport;
