import { Badge, Table } from "react-bootstrap";
import React from "react";

const DataTableUserReport = (props) => {
  return (
    <>
      <Table
        className={"data-table  table-responsive-xl"}
        responsive={true}
        striped
        hover
      >
        <thead className={"txt-light"}>
          <tr>
            {props.headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className={"bg-light txt-light rounded"}>
          {props.data.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.receiverAddress}</td>
              <td>{item.type}</td>
              <td>{item.coinType}</td>
              <td>{item.formatedAmount}</td>

              <td>
                {item.formattedNetworkFee} {item.coinType}
              </td>
              <td>
                {item.commission} {item.coinType}
              </td>
              <td>{item.formatedFee}</td>

              <td>
                <a
                  target={"_blank"}
                  href={item.explorerUrl}
                  className={"text-light"}
                  title={item.hash}
                  rel="noopener noreferrer"
                >
                  {item.hash.slice(0, 5)}...
                </a>
              </td>
              <td>{item.wallet.name}</td>
              <td>{item.created_at}</td>
              <td>
                <Badge
                  pill
                  variant={
                    item.confirmations === 0
                      ? "danger"
                      : item.confirmations < item.maxConfirmation
                      ? "warning"
                      : "success"
                  }
                  className={"px-4 py-2"}
                >
                  {item.status!=="failed"?item.confirmations === 0
                  ? "Pending"
                  : item.confirmations < item.maxConfirmation
                  ? "Confirmation: " +
                    item.confirmations +
                    "/" +
                    item.maxConfirmation
                  : item.confirmations >= item.maxConfirmation
                  ? "Confirmed"
                  : null:"Failed"}
                </Badge>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default DataTableUserReport;
