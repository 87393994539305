import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Accordion,
  Card,
  Table,
  Spinner,
} from "react-bootstrap";
import Rodal from "rodal";
import _ from "lodash";

import { rolesListApi, permissionsApi, modifyRoleApi } from "../../utils";
import RolesListItem from "./rolesListItem";
import CreateRole from "./createRole";
export default class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      roles: [],
      permissions: [],
      editRoles: [],
      loading: false,
      msg: "",
      modifyLoading: false,
    };
    this.showModify = this.showModify.bind(this);
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
    this.handleModifyRole = this.handleModifyRole.bind(this);
    this.handleRoleDescription = this.handleRoleDescription.bind(this);
    this.handleRoleName = this.handleRoleName.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.getDeta = this.getDeta.bind(this);
  }
  showModify(editRoles) {
    this.setState({ visible: true, editRoles });
  }
  componentDidMount() {
    this.getDeta();
    this.getPermissions();
  }
  getDeta() {
    this.setState({ loading: true });
    rolesListApi(1, 100).then((res) => {
      this.setState({ loading: false, roles: res.roles });
    });
  }
  getPermissions() {
    permissionsApi().then((res) => {
      this.setState({ permissions: res.permissions });
    });
  }
  handleSelectedChange(event) {
    const { editRoles } = this.state;
    let selectedPermissions = editRoles.permissions;
    const target = event.target;
    if (target.checked) {
      selectedPermissions.push(target.value);
    } else {
      selectedPermissions.splice(selectedPermissions.indexOf(target.value), 1);
    }
    this.setState((prevState) => {
      let editRoles = Object.assign({}, prevState.editRoles);
      editRoles.permissions = selectedPermissions;
      return { editRoles };
    });
  }
  handleRoleName(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let editRoles = Object.assign({}, prevState.editRoles);
      editRoles.role = value;
      return { editRoles };
    });
  }
  handleRoleDescription(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let editRoles = Object.assign({}, prevState.editRoles);
      editRoles.description = value;
      return { editRoles };
    });
  }
  handleModifyRole() {
    this.setState({ modifyLoading: true });
    const { editRoles } = this.state;
    modifyRoleApi(
      editRoles.id,
      editRoles.role,
      editRoles.permissions,
      editRoles.description
    ).then((res) => {
      if (res.id != null) {
        this.setState({
          modifyLoading: false,
          msg: "Role Modifyed Successfully",
        });
        this.getDeta();
      } else {
        this.setState({ modifyLoading: false, msg: res.message });
      }
    });
  }
  selectAll(event) {
    const { editRoles, permissions } = this.state;
    const target = event.target;
    let edited = permissions[target.value].map((k) => k.key);
    let per = editRoles.permissions;
    if (target.checked) {
      per = per.concat(_.difference(edited, per));
    } else {
      per = _.pullAll(per, edited);
    }
    this.setState((prevState) => {
      let editRoles = Object.assign({}, prevState.editRoles);
      editRoles.permissions = per;
      return { editRoles };
    });
  }
  render() {
    const { permissions: Per } = this.props.user;
    const {
      roles,
      editRoles,
      permissions,
      visible,
      msg,
      modifyLoading,
      loading,
    } = this.state;
    return (
      <Container className={"pt-5 member-main"}>
        <Rodal
          width={800}
          duration={500}
          animation={"zoom"}
          visible={visible}
          onClose={() => {
            this.setState({ visible: false, msg: "" });
          }}
        >
          <Container className={"bg-dark p-4"}>
            <div className={"border-bottom border-secondary pb-1"}>
              <h5 className={"text-light mb-0"}>
                Modify Permissions - {editRoles.role}{" "}
              </h5>
              <span className={"txt-light"}>
                Review and modify the permissions for the roles
              </span>
            </div>
            <Form className={"mt-3 mb-0"}>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Role's Display Name :
                </Form.Label>
                <Col sm="5">
                  <Form.Control
                    value={editRoles.role}
                    onChange={this.handleRoleName}
                    className={"rounded-0"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  Description :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    value={editRoles.description}
                    onChange={this.handleRoleDescription}
                    className={"rounded-0"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>

              <Accordion>
                {visible
                  ? Object.keys(permissions).map((key, index) => (
                      <Card
                        key={index}
                        className={"bg-dark border-0 txt-light"}
                      >
                        <Accordion.Toggle
                          className={"bg-light border-rounded mb-1"}
                          as={Card.Header}
                          eventKey={key}
                        >
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                          Permissions
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={key}>
                          <Row>
                            <Container>
                              <Row>
                                <Col>
                                  <Form.Group controlId={"Select" + index}>
                                    <Form.Check
                                      className={"small-text-1"}
                                      title={"Select All"}
                                      type="checkbox"
                                      onChange={this.selectAll}
                                      value={key}
                                      label={"Select All"}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Container>
                            {permissions[key].map((per) => (
                              <Col xs={"4"}>
                                <Form.Group controlId={per.key}>
                                  <Form.Check
                                    className={"small-text-1"}
                                    title={per.key}
                                    type="checkbox"
                                    checked={
                                      editRoles.permissions.indexOf(per.key) !==
                                      -1
                                    }
                                    onChange={this.handleSelectedChange}
                                    value={per.key}
                                    label={per.title}
                                  />
                                </Form.Group>
                              </Col>
                            ))}
                          </Row>
                        </Accordion.Collapse>
                      </Card>
                    ))
                  : null}
              </Accordion>
            </Form>
            <Col>
              <span className={"pl-3 txt-orange"}>{msg}</span>
            </Col>

            <Col className={"text-right"}>
              <Button
                onClick={() => {
                  this.setState({ visible: false, msg: "" });
                }}
                size="sm"
                className={
                  "bg-light border mb-0 d-sm-none mr-1 btn-shaped px-3"
                }
              >
                Cancel
              </Button>
              <Button
                size="sm"
                onClick={this.handleModifyRole}
                className={"bg-light border mb-3 mt-3 btn-shaped px-5"}
              >
                {modifyLoading ? (
                  <Spinner size={"sm"} animation="border" variant="light" />
                ) : (
                  "Modify Role"
                )}
              </Button>
            </Col>
          </Container>
        </Rodal>
        {Per.indexOf("ADMIN_CREATE_ROLE") !== -1 ? (
          <CreateRole getData={this.getDeta} />
        ) : null}
        {!loading ? (
          <Table
            striped
            bordered
            className={"admin-table table-responsive-xl mt-2"}
          >
            <thead className={"text-light bg-light"}>
              <tr>
                <th>Roles</th>
                <th>Description</th>
                {Per.indexOf("ADMIN_EDIT_ROLE") !== -1 ? <th>Modify</th> : null}
                {Per.indexOf("ADMIN_REMOVE_ROLE") !== -1 ? (
                  <th>Remove</th>
                ) : null}
              </tr>
            </thead>
            <tbody className={"text-light rounded"}>
              {roles.map((roles, index) => (
                <RolesListItem
                  editRole={Per.indexOf("ADMIN_EDIT_ROLE") !== -1}
                  removeRole={Per.indexOf("ADMIN_REMOVE_ROLE") !== -1}
                  key={index}
                  showModify={this.showModify}
                  reloadData={this.getDeta}
                  roles={roles}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <Row className={"t-center"}>
            {" "}
            <Spinner className={"align"} animation="border" variant="light" />
          </Row>
        )}
      </Container>
    );
  }
}
