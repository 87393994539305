import { Col } from "react-bootstrap";
import React from "react";
import UserTransactions from "./userTransactions";
import UserWallet from "./userWallet";
import UserInvited from "./userInvited";
import UserSessions from "./userSessions";
const TabBody = (props) => (
  <Col xs={12} className={"bg-dark-3 py-4"}>
    {props.active === 1 ? (
      <UserTransactions id={props.id} />
    ) : props.active === 2 ? (
      <UserWallet id={props.id} />
    ) : props.active === 3 ? (
      <UserSessions id={props.id} />
    ) : (
      <UserInvited id={props.id} />
    )}
  </Col>
);
export default TabBody;
