import React, { Component } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default class UserListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      selectedUsers: [],
    };
    this.cheange = this.cheange.bind(this);
    this.cheangeChecked = this.cheangeChecked.bind(this);
  }
  cheangeChecked(event) {
    let selectedUsers = this.state.selectedUsers;
    const target = event.target;
    selectedUsers.indexOf(target.value) === -1
      ? selectedUsers.push(target.value)
      : selectedUsers.splice(selectedUsers.indexOf(target.value), 1);

    this.setState({
      selectedUsers,
      checked: !this.state.checked,
    });
    this.props.handleSelectedChange(selectedUsers);
  }
  selectAllUsers(selectedUsers) {
    this.setState({ selectedUsers });
  }
  unSelectAllUsers() {
    this.setState({ selectedUsers: [] });
  }
  cheange(user) {
    this.props.rodalHandler(user);
  }

  render() {
    const { userlist, isUser, users, edit } = this.props;
    const { selectedUsers } = this.state;
    return users.map((user, index) => (
      <tr>
        <td key={index}>
          <Form.Check
            checked={selectedUsers.indexOf(user.id.toString()) !== -1}
            name={"selectedUsers"}
            value={user.id}
            onChange={this.cheangeChecked}
            type={isUser ? "radio" : "checkbox"}
          />
        </td>
        <td>{user.full_name}</td>
        <td>
          <Link className={"txt-orange"} to={`/userprofile/${user.id}`}>
            {user.email}
          </Link>
        </td>
        {isUser ? null : (
          <td className={isUser ? "d-none" : ""}>
            {user.userRoles.length > 1 ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip">
                    {user.userRoles.map((role, index) => (
                      <p className={"m-0 border-bottom"}>{role.role}</p>
                    ))}
                  </Tooltip>
                }
              >
                <span>{user.userRoles[0].role},...</span>
              </OverlayTrigger>
            ) : user.userRoles.length === 1 ? (
              user.userRoles[0].role
            ) : null}
          </td>
        )}
        <td className={userlist ? "d-none" : ""}>
          {user.email_verified ? "Yes" : "No"}
        </td>
        <td className={userlist ? "d-none" : ""}>
          {user.isGoogleAuthenticatorEnabled ? "Enabled" : "Disabled"}
        </td>
        <td className={userlist ? "d-none" : ""}>
          {user.enabled ? "Active" : "Inactive"}
        </td>
        <td className={userlist ? "d-none" : ""}>{user.lastLogin}</td>
        {edit ? (
          <td className={userlist ? "d-none" : ""}>
            <Button
              onClick={() => this.cheange(user)}
              className={"border-0 p-0 px-1"}
              variant="outline-light"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </td>
        ) : null}
      </tr>
    ));
  }
}
