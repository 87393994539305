import React from 'react'
import { Table, Badge } from 'react-bootstrap'
export default function DataTable2() {
    return (
        <Table striped className={'data-table table-responsive-xl'}>
            <thead className={'txt-light'}>
                <tr>
                    <th>Transaction ID</th>
                    <th>Time Stamp</th>
                    <th>Exchanged Amount</th>
                    <th>Received Amount</th>
                    <th>Source Wallet</th>
                    <th>Destination Wallet</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody className={'bg-light txt-light rounded'}>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge pill variant="success" className={'px-4 py-2'}>
                            Complete
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge
                            pill
                            variant="warning"
                            className={'px-4 py-2 text-light'}
                        >
                            Pending
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge pill variant="success" className={'px-4 py-2'}>
                            Complete
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge
                            pill
                            variant="danger"
                            className={'px-4 py-2 text-light'}
                        >
                            Cancelled
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge pill variant="success" className={'px-4 py-2'}>
                            Complete
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge
                            pill
                            variant="danger"
                            className={'px-4 py-2 text-light'}
                        >
                            Cancelled
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge
                            pill
                            variant="warning"
                            className={'px-4 py-2 text-light'}
                        >
                            Pending
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge pill variant="success" className={'px-4 py-2'}>
                            Complete
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge pill variant="success" className={'px-4 py-2'}>
                            Complete
                        </Badge>
                    </td>
                </tr>
                <tr>
                    <td>7D1-587-BB1-FCB</td>
                    <td>2019-12-01 16:25:59</td>
                    <td>0.20522881 BTC</td>
                    <td>0.002 ETH</td>
                    <td>My Wallet</td>
                    <td>EXT Wallet</td>
                    <td>
                        <Badge
                            pill
                            variant="warning"
                            className={'px-4 py-2 text-light'}
                        >
                            Pending
                        </Badge>
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}
