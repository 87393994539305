import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Row, Col, Button, Form, Dropdown } from 'react-bootstrap'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import Bitcoin from '../../assets/png/bitcoin.png'
import Etherrum from '../../assets/png/ETHEREUM.png'
import Litecoin from '../../assets/png/Litecoin.png'
import Ripple from '../../assets/png/ripple.png'
import SmallBitcoin from '../../assets/png/SmallBitcoin.png'
import Barcode from '../../assets/png/barcde.jpg'
import DataTable from '../../components/dataTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Rodal from 'rodal'

export default class MyWallet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuToggle: false,
            visible: false,
            visible1: false,
            mNavVis: false,
        }
    }
    show() {
        this.setState({ visible: true })
    }

    hide() {
        this.setState({ visible: false })
    }
    show1() {
        this.setState({ visible1: true })
    }

    hide1() {
        this.setState({ visible1: false })
    }
    render() {
        return (
            <div>
                <Row className={'panel-rows'}>
                    <Col xs={12} className={'no-padding'}>
                        <h5 className={'no-margin text-light pl-3'}>
                            Xapa Balance
                        </h5>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <div
                            style={{
                                backgroundImage:
                                    'linear-gradient(to right, #f7931b , #f7c419)',
                            }}
                            className={'rounded gradient-card p-2 mt-2'}
                        >
                            <Row>
                                <Col>
                                    <img alt="bitcoin" src={SmallBitcoin} />
                                    <h6
                                        className={
                                            'no-margin text-light d-inline-block pl-1'
                                        }
                                    >
                                        Bitcoin
                                    </h6>
                                </Col>
                                <Col>
                                    <h6
                                        className={
                                            'no-margin text-light pl-3  mt-1'
                                        }
                                    >
                                        24,924 BTC
                                    </h6>
                                    <h6
                                        className={'no-margin text-light pl-3 '}
                                    >
                                        24,924 $
                                    </h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'mt-4'}>
                                    <span className={'text-light'}>
                                        1M3thurePVGcjkpu3nBpW1GXjgro
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <div
                            style={{
                                backgroundImage:
                                    'linear-gradient(to right, #a4a8f1 , #8bc9f2)',
                            }}
                            className={'rounded gradient-card p-2 mt-2'}
                        >
                            <Row>
                                <Col>
                                    <img alt="bitcoin" src={Etherrum} />
                                    <h6
                                        className={
                                            'no-margin text-light d-inline-block pl-1'
                                        }
                                    >
                                        Etherrum
                                    </h6>
                                </Col>
                                <Col>
                                    <h6
                                        className={
                                            'no-margin text-light pl-3  mt-1'
                                        }
                                    >
                                        24,924 BTC
                                    </h6>
                                    <h6
                                        className={'no-margin text-light pl-3 '}
                                    >
                                        24,924 $
                                    </h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'mt-4'}>
                                    <span className={'text-light'}>
                                        1M3thurePVGcjkpu3nBpW1GXjgro
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <div
                            style={{
                                backgroundImage:
                                    'linear-gradient(to right, #8bc9f2 , #00aae5)',
                            }}
                            className={'rounded gradient-card p-2 mt-2'}
                        >
                            <Row>
                                <Col>
                                    <img alt="bitcoin" src={Ripple} />
                                    <h6
                                        className={
                                            'no-margin text-light d-inline-block pl-1'
                                        }
                                    >
                                        Ripple
                                    </h6>
                                </Col>
                                <Col>
                                    <h6
                                        className={
                                            'no-margin text-light pl-3  mt-1'
                                        }
                                    >
                                        24,924 BTC
                                    </h6>
                                    <h6
                                        className={'no-margin text-light pl-3 '}
                                    >
                                        24,924 $
                                    </h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={'mt-4'}>
                                    <span className={'text-light'}>
                                        1M3thurePVGcjkpu3nBpW1GXjgro
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={6}>
                        <div
                            onClick={this.show.bind(this)}
                            className={
                                'add-new-wallet d-flex justify-content-center align-items-center mt-2 rounded animated'
                            }
                        >
                            <label className={'no-margin txt-light'}>
                                + Add New Wallet
                            </label>
                        </div>
                    </Col>
                    <Rodal
                        width={300}
                        duration={500}
                        animation={'door'}
                        visible={this.state.visible}
                        onClose={this.hide.bind(this)}
                    >
                        <Col className={'px-4 pb-5 pt-5 bg-dark'}>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="wallet"
                                    className={'bg-light text-light'}
                                    id="dropdown-basic"
                                >
                                    <img alt="coin" src={Bitcoin} width={25} />{' '}
                                    Wallet Address
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">
                                        Action
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">
                                        Another action
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">
                                        Something else
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Rodal>
                </Row>
                <Row className={'panel-rows cwa'}>
                    <Col xs={12} className={'no-padding'}>
                        <h5 className={'no-margin text-light pl-3'}>
                            Crypto Wallet addresses
                        </h5>

                        <Col xs={12}>
                            <Row className={'mt-4'}>
                                <Col xs={2} className={'group-btn'}>
                                    <Button
                                        className={
                                            'rounded-0 bg-light border-0'
                                        }
                                        variant="primary"
                                        size="lg"
                                        block
                                    >
                                        <Row>
                                            <Col xs={3}>
                                                <img
                                                    alt="coin"
                                                    src={Bitcoin}
                                                    width={25}
                                                />
                                            </Col>
                                            <Col xs={9} className={'text-left'}>
                                                Bitcoin
                                            </Col>
                                        </Row>
                                    </Button>
                                    <Button
                                        className={
                                            'rounded-0 bg-light border-0'
                                        }
                                        variant="primary"
                                        size="lg"
                                        block
                                    >
                                        <Row>
                                            <Col xs={3}>
                                                <img
                                                    alt="coin"
                                                    src={Etherrum}
                                                    width={25}
                                                />
                                            </Col>
                                            <Col xs={9} className={'text-left'}>
                                                Etherrum
                                            </Col>
                                        </Row>
                                    </Button>
                                    <Button
                                        className={
                                            'rounded-0 bg-light border-0'
                                        }
                                        variant="primary"
                                        size="lg"
                                        block
                                    >
                                        <Row>
                                            <Col xs={3}>
                                                <img
                                                    alt="coin"
                                                    src={Litecoin}
                                                    width={25}
                                                />
                                            </Col>
                                            <Col xs={9} className={'text-left'}>
                                                Litecoin
                                            </Col>
                                        </Row>
                                    </Button>
                                    <Button
                                        className={
                                            'rounded-0 bg-light border-0'
                                        }
                                        variant="primary"
                                        size="lg"
                                        block
                                    >
                                        <Row>
                                            <Col xs={3}>
                                                <img
                                                    alt="coin"
                                                    src={Ripple}
                                                    width={25}
                                                />
                                            </Col>
                                            <Col xs={9} className={'text-left'}>
                                                Ripple
                                            </Col>
                                        </Row>
                                    </Button>
                                </Col>
                                <Col xs={10}>
                                    <div
                                        className={
                                            'border border-light p-1 right-cwa'
                                        }
                                    >
                                        <div
                                            className={
                                                'border border-light p-1 pb-3'
                                            }
                                        >
                                            <h5 className={'pl-2'}>
                                                BTC Wallet Address
                                            </h5>
                                            <p
                                                className={
                                                    'pl-2 font-weight-light'
                                                }
                                            >
                                                Wallet Address (You can share it
                                                with traders to get Paid)
                                            </p>
                                            <Row className={'pl-2 mr-5'}>
                                                <Col xs={9}>
                                                    <Form.Control
                                                        disabled
                                                        className={'rounded-0'}
                                                        type="text"
                                                        value={
                                                            '1M3thurePVGcjkpu3nBpW1GXjgro1M3thurePVGcjkpu3nBpW1GXjgro'
                                                        }
                                                        placeholder=""
                                                    />
                                                </Col>
                                                <Col xs={3}>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Button
                                                                className={
                                                                    'bg-light border'
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCopy
                                                                    }
                                                                />
                                                            </Button>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Button
                                                                size="sm"
                                                                className={
                                                                    'bg-light border'
                                                                }
                                                            >
                                                                Generate New
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div
                                            className={
                                                'border border-light p-1 mt-1'
                                            }
                                        >
                                            <Row>
                                                <Col xs={2}>
                                                    <img
                                                        alt="coin"
                                                        onClick={this.show1.bind(
                                                            this
                                                        )}
                                                        className={
                                                            'img-fluid m-2'
                                                        }
                                                        src={Barcode}
                                                    />
                                                    <Rodal
                                                        width={400}
                                                        duration={500}
                                                        animation={'door'}
                                                        visible={
                                                            this.state.visible1
                                                        }
                                                        onClose={this.hide1.bind(
                                                            this
                                                        )}
                                                    >
                                                        <div
                                                            className={
                                                                'bg-light p-4 rounded'
                                                            }
                                                        >
                                                            <img
                                                                alt="Barcode"
                                                                style={{
                                                                    cursor:
                                                                        'pointer',
                                                                }}
                                                                className={
                                                                    'img-fluid m-2'
                                                                }
                                                                src={Barcode}
                                                            />
                                                        </div>
                                                    </Rodal>
                                                </Col>
                                                <Col
                                                    xs={8}
                                                    className={
                                                        'd-flex align-items-center justify-content-center'
                                                    }
                                                >
                                                    <div>
                                                        <h3
                                                            className={
                                                                'text-light text-center'
                                                            }
                                                        >
                                                            Just scan the wallet
                                                            address
                                                        </h3>
                                                        <h6
                                                            className={
                                                                'text-center txt-dark font-weight-light'
                                                            }
                                                        >
                                                            Share your address
                                                            to scan and transfer
                                                            money
                                                        </h6>
                                                    </div>
                                                </Col>
                                                <Col
                                                    xs={2}
                                                    className={
                                                        'd-flex align-items-center justify-content-center'
                                                    }
                                                >
                                                    <div>
                                                        <Button
                                                            size="sm"
                                                            className={
                                                                'bg-light border'
                                                            }
                                                            block
                                                        >
                                                            Send
                                                        </Button>
                                                        <Button
                                                            size="sm"
                                                            className={
                                                                'bg-light border mt-2'
                                                            }
                                                        >
                                                            Receive
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
                <Row className={'panel-rows'}>
                    <Col>
                        <h5 className={'no-margin'}>Recent Transactions</h5>
                    </Col>
                </Row>
                <DataTable />
            </div>
        )
    }
}
