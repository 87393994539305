import React from "react";
import { Button, Col, Spinner } from "react-bootstrap";
import profilePic from "../../../assets/png/avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { sendEmailApi, userAttributeApi, userEditApi } from "../../../utils";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Switch from "react-switch";

class UserAttribute extends React.Component {
  state = {
    data: {},
    loading: true,
    emailLoading: false,
    faLoading: false,
    isGoogleAuthenticatorEnabled: true,
  };
  componentDidMount() {
    this.getData();
  }
  handleChange = () => {
    const { isGoogleAuthenticatorEnabled } = this.state;
    this.setState({ faLoading: true });
    userEditApi({
      id: this.props.id,
      isGoogleAuthenticatorEnabled: !isGoogleAuthenticatorEnabled,
    }).then(() => {
      this.setState({ faLoading: false }, () => {
        this.getData();
      });
    });
  };
  getData = () => {
    userAttributeApi(this.props.id).then((res) => {
      let online = false;
      if (Date.now() - new Date(res.user.lastLogin) < 120000) {
        online = true;
      } else {
        online = false;
      }
      this.setState({
        loading: false,
        data: res.user,
        online,
        isGoogleAuthenticatorEnabled: res.user.isGoogleAuthenticatorEnabled,
      });
    });
  };

  sendEmail = () => {
    this.setState({ emailLoading: true });
    sendEmailApi(this.props.id).then((res) => {
      this.setState({ emailLoading: false }, () => {
        res.json().then((r) => {
          if (res.status === 200) {
            toast.success(`${r.status}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.getData();
          } else {
            toast.error(`${r.error}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      });
    });
  };
  render() {
    const {
      data,
      loading,
      online,
      isGoogleAuthenticatorEnabled,
      emailLoading,
      faLoading,
    } = this.state;
    if (loading) return <div />;
    return (
      <Col className={"bg-dark-3 rounded-left p-3"} lg={6} xs={12}>
        <h6 className={"text-light text-center"}>User Attribute</h6>
        <ToastContainer />

        <Col className={"d-flex border-top pt-3"}>
          {data.roles.length > 0 ? (
            <span className={"txt-orange position-absolute admin-label"}>
              Admin
            </span>
          ) : null}
          <div className={"d-inline-block"}>
            <img
              alt={"profile pic"}
              src={profilePic}
              width={80}
              className={"rounded-circle"}
            />
          </div>
          <div className={"col-12 d-inline-block text-light align-self-center"}>
            <ul className={"non-list-style m-0 pl-3"}>
              <li className={"d-none d-sm-inline-block"}>{data.email}</li>
              <li>{data.phone_number}</li>
              <li>
                <FontAwesomeIcon
                  size={"sm"}
                  className={online ? "txt-green mr-1" : "txt-red mr-1"}
                  icon={faCircle}
                />
                {online ? "Online" : "Offline"}
              </li>
            </ul>
          </div>
        </Col>
        <Col className={"d-flex pt-3"}>
          <ul className={"non-list-style text-light m-0 pl-3"}>
            <li>User Name : {data.username}</li>
            <li>
              SMS Verified :{" "}
              {data.smsVerified ? (
                <span className={"txt-green"}> Yes</span>
              ) : (
                <span className={"txt-red"}> No</span>
              )}
            </li>
            <li>
              2FA Status :
              {data.isGoogleAuthenticatorEnabled ? (
                <>
                  {" "}
                  <span className={"txt-green mr-5"}> Enable</span>
                  {!faLoading ? (
                    <Switch
                      onChange={this.handleChange}
                      checked={isGoogleAuthenticatorEnabled}
                      height={18}
                      width={40}
                      disabled={!isGoogleAuthenticatorEnabled}
                    />
                  ) : (
                    <Spinner size={"sm"} animation="liner" variant="light" />
                  )}
                </>
              ) : (
                <span className={"txt-red"}> Disable</span>
              )}{" "}
            </li>
            <li>
              Invitation Code :{" "}
              <span className={"txt-orange"}>{data.invitationCode}</span>
            </li>
            <li>
              Total Invite :{" "}
              <span className={"txt-orange"}>{data.totalInvites}</span>
            </li>
            <li>
              Email Verified :{" "}
              {data.email_verified ? (
                <span className={"txt-green"}> Yes</span>
              ) : (
                <>
                  {" "}
                  <span className={"txt-red"}> No</span>
                  <Button
                    disabled={emailLoading}
                    onClick={this.sendEmail}
                    size="sm"
                    syyle={{ height: 30 }}
                    className={"bg-light border ml-5 btn-shaped px-2 py-0"}
                  >
                    Resend
                  </Button>
                </>
              )}{" "}
            </li>
            <li>
              Email Verified Date :{" "}
              <span className={"txt-orange"}>{data.verified_at}</span>{" "}
            </li>
            <li>
              Last Login :{" "}
              <span className={"txt-orange"}>
                {moment(data.lastLogin).format("YYYY-MM-DD HH:mm:ss ( Z )")}
              </span>
            </li>
          </ul>
        </Col>
      </Col>
    );
  }
}
export default UserAttribute;
