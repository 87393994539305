import React from 'react'
import AvatarImg from './../assets/png/avatar.png'
export default function Avatar(props) {
    return (
        <div className={'avatar flex'}>
            <img alt="avatar" src={AvatarImg} />
            <h6 className={'no-margin txt-light'}>{props.user.full_name}</h6>
        </div>
    )
}
