import {
  Col,
  Dropdown,
  Form,
  FormControl,
  Row,
  Spinner,
} from "react-bootstrap";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import Pagination from "react-js-pagination";
import React from "react";
import { coinsApi, userReport, userTrCSVApi } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import DataTableUserReport from "./dataTableUserReport";

class UserTransactions extends React.Component {
  constructor(props) {
    super(props);
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    )
      .add(1, "days")
      .subtract(1, "seconds");
    this.state = {
      start: start,
      end: end,
      coinType: "",
      conins: [],
      loading: true,
      page: 1,
      maxPerPage: 25,
      totalPages: 1,
      totalItems: 1,
      data: [],
      type: "",
    };
  }
  applyCallback = (startDate, endDate) => {
    this.setState(
      {
        page: 1,
        start: startDate,
        end: endDate,
      },
      () => {
        this.getData();
      }
    );
  };
  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      this.getData();
    });
  };
  handleMaxPerPageChange = (event) => {
    this.setState({ maxPerPage: event.target.value, page: 1 }, () => {
      this.getData();
    });
  };
  componentDidMount() {
    coinsApi("all").then((res) => {
      this.setState({ coins: res, loading: false }, () => {
        this.getData();
      });
    });
  }
  csvDownload = () => {
    const { start, end, coinType, type } = this.state;
    userTrCSVApi(
      this.props.id,
      moment(start).format("YYYY-MM-DD HH:mm:ss"),
      moment(end).format("YYYY-MM-DD HH:mm:ss"),
      coinType,
      type
    ).then();
  };
  getData = () => {
    const { start, end, coinType, page, maxPerPage, type } = this.state;
    this.setState({ loading: true });
    userReport(
      this.props.id,
      page,
      maxPerPage,
      moment(start).format("YYYY-MM-DD HH:mm:ss"),
      moment(end).format("YYYY-MM-DD HH:mm:ss"),
      coinType,
      type
    ).then((res) => {
      this.setState({
        loading: false,
        data: res.reports,
        maxPerPage: res.items_per_page,
        page: res.page,
        totalPages: res.total_pages,
        totalItems: res.total_items,
      });
    });
  };
  render() {
    const {
      data,
      coins,
      coinType,
      loading,
      start,
      end,
      type,
      page,
      maxPerPage,
      totalItems,
    } = this.state;
    let ranges = {
      Daily: [moment(Date.now()), moment(end)],
      Weekly: [moment(Date.now()).subtract(1, "weeks"), moment(end)],
      Monthly: [moment(Date.now()).subtract(1, "months"), moment(end)],
      Yearly: [moment(Date.now()).subtract(1, "years"), moment(end)],
    };
    let local = {
      format: "DD-MM-YYYY HH:mm",
      sundayFirst: false,
    };
    if (loading)
      return (
        <div
          className={
            "my-5 py-5 d-flex justify-content-center align-content-center"
          }
        >
          <Spinner size={"lg"} animation="grow" variant="light" />
        </div>
      );
    return (
      <div>
        <Row>
          <Col className={"mt-2"} lg={3} xs={12}>
            <DateTimeRangeContainer
              ranges={ranges}
              start={start}
              end={end}
              local={local}
              applyCallback={this.applyCallback}
            >
              <FormControl
                id="formControlsTextB"
                type="text"
                label="Text"
                value={
                  moment(start).format("YYYY-MM-DD") +
                  " -> " +
                  moment(end).format("YYYY-MM-DD")
                }
              />
            </DateTimeRangeContainer>
          </Col>
          <Col className={"mt-2"} lg={2} xs={6}>
            <Dropdown>
              <Dropdown.Toggle
                variant="wallet"
                className={"bg-light text-light"}
                id="dropdown-basic"
              >
                {coinType !== "" ? (
                  coins.length > 0 ? (
                    <>
                      <img
                        width={20}
                        className={"mr-1"}
                        src={
                          coins[
                            coins.find(({ symbol }) => symbol === coinType).id -
                              1
                          ].icon
                        }
                        alt={"coin"}
                      />{" "}
                      {
                        coins[
                          coins.find(({ symbol }) => symbol === coinType).id - 1
                        ].name
                      }
                    </>
                  ) : null
                ) : (
                  "All Coins"
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ coinType: "" }, () => {
                      this.getData();
                    })
                  }
                >
                  All Coins
                </Dropdown.Item>
                {!loading
                  ? coins.length > 0
                    ? coins.map((coin, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            this.setState(
                              {
                                coinType: coin.symbol,
                                page:1
                              },
                              () => {
                                this.getData();
                              }
                            )
                          }
                        >
                          <img
                            width={20}
                            className={"mr-1"}
                            src={coin.icon}
                            alt={"coin"}
                          />{" "}
                          {coin.name}
                        </Dropdown.Item>
                      ))
                    : null
                  : null}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col className={"mt-2"} lg={2} xs={6}>
            <Dropdown>
              <Dropdown.Toggle
                variant="wallet"
                className={"bg-light text-light"}
                id="dropdown-type"
              >
                {type.length === 0
                  ? "All Types"
                  : type.charAt(0).toUpperCase() + type.slice(1)}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "100%" }}>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ type: "" }, () => {
                      this.getData();
                    })
                  }
                >
                  All Types
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() =>
                    this.setState({ type: "received",page:1 }, () => {
                      this.getData();
                    })
                  }
                >
                  Received
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ type: "sent",page:1 }, () => {
                      this.getData();
                    })
                  }
                >
                  Sent
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col className={"mt-2"} lg={3} xs={6}>
            <Form.Group>
              <Form.Control
                value={maxPerPage}
                onChange={this.handleMaxPerPageChange}
                as="select"
              >
                <option value={25}>View 25 Per Page</option>
                <option value={50}>View 50 Per Page</option>
                <option value={100}>View 100 Per Page</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <FontAwesomeIcon
              className={"mt-2"}
              style={{
                fontSize: "2.4em",
                color: "green",
                cursor: "pointer",
              }}
              onClick={this.csvDownload}
              icon={faFileCsv}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataTableUserReport
              headers={[
                "TR-ID",
                "Receiver Wallet",
                "TR-Type",
                "Coin Type",
                "Amount",
                "Net Fee",
                "Xapa Commission",
                "Total Fee",
                "Transaction Hash",
                "Wallet Name",
                "Time Stamp",
                "Status",
              ]}
              data={data}
            />
          </Col>
        </Row>
        <Row className={"d-flex justify-content-center align-items-center"}>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            onChange={this.handlePageChange}
            activePage={page}
            itemsCountPerPage={maxPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={10}
          />
        </Row>
      </div>
    );
  }
}
export default UserTransactions;
