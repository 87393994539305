import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { xapaBalanceApi } from "../../utils";

export default class XapaBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      customerBalanceData: props.customerBalanceData,
    };
  }
  componentDidMount() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 10000);
  }
  getData() {
    xapaBalanceApi().then((res) => {
      res !== false
        ? this.setState({ data: res.balances, loading: false })
        : this.setState({ loading: true });
    });
  }
  render() {
    const { loading, data, customerBalanceData } = this.state;
    return (
      <Row className={"panel-rows"}>
        <Col xs={12} className={"no-padding"}>
          <h5 className={"no-margin text-light pl-3"}>Xapa Balance</h5>
        </Col>
        {!loading
          ? data.map((balance, index) => (
              <Col key={index} xs={12} sm={6} md={4} xl={4}>
                <div
                  style={
                    Number(balance.formatted_balance) * 1.1 >=
                    Number(
                      customerBalanceData.find(
                        ({ symbol }) => symbol === balance.symbol
                      ).formatted_balance
                    )
                      ? {
                          backgroundColor: "#00aae5",
                        }
                      : Number(balance.formatted_balance) * 1.15 >=
                      Number(
                        customerBalanceData.find(
                          ({ symbol }) => symbol === balance.symbol
                        ).formatted_balance
                      )
                        ?  { backgroundColor: "#ff5722" }
                        :{ backgroundColor: "#cc4263" }
                  }
                  className={"rounded balance gradient-card p-2 mt-2"}
                >
                  <Row>
                    <Col>
                      <img alt="bitcoin" src={balance.icon} />
                      <h6
                        className={"no-margin text-light d-inline-block pl-1"}
                      >
                        {balance.name}
                      </h6>
                    </Col>
                    <Col>
                      <h6 className={"no-margin text-light text-right  mt-1"}>
                        {Number(balance.formatted_balance).toFixed(6)}{" "}
                        {balance.symbol}
                      </h6>
                      <h6 className={"no-margin text-light text-right "}>
                        {Number(balance.price).toFixed(2)} $
                      </h6>
                      <hr />
                      <h6
                        style={{ fontSize: "0.7em" }}
                        className={"no-margin text-light text-right "}
                      >
                        {Number(balance.network_fee).toFixed(6)}{" "}
                        {balance.symbol}
                      </h6>
                      <h6
                        style={{ fontSize: "0.7em" }}
                        className={"no-margin text-light text-right "}
                      >
                        {Number(
                          (balance.price / balance.formatted_balance) *
                            balance.network_fee
                        ).toFixed(6)}{" "}
                        $
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"mt-4"}>
                      <span className={"text-light"}>
                        {balance.wallet_address}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))
          : null}
      </Row>
    );
  }
}
