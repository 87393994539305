import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import NavSideBar from "../navSideBar";
import AccessDenied from "../accessDenied";
import TwoFa from "../../pages/TwoFa";

const PrivateRoute = (props) => {
  const { component: Component, auth, user, restricted, ...rest } = props;
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          !user["2fa"].googleAuthenticator ? (
            <TwoFa />
          ) : user.permissions.length ? (
            <NavSideBar user={user} path={location.pathname}>
              <Component user={user} {...props} />
            </NavSideBar>
          ) : (
            <AccessDenied />
          )
        ) : (
          <Redirect to="/admin/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
