import React from "react";
import ChangeLogList from "./changeLogList";
import CreateAppVersion from "./createAppVersion";

export default class ChangeLog extends React.Component {
 refreshPage = () => {
    this.refs.refresh.getData();
  };
  render() {
    return (
      <div>
        <CreateAppVersion refreshPage={this.refreshPage} />
        <ChangeLogList ref={"refresh"} />
      </div>
    );
  }
}
