import React, { Component } from 'react'
import { Row, Col, Button, Dropdown, Form } from 'react-bootstrap'
import Bitcoin from '../../assets/png/bitcoin.png'
import Etherrum from '../../assets/png/ETHEREUM.png'
import DataTable2 from '../../components/dataTable/dataTable2'
import CoinCharts from '../../components/coinChart'
export default class Exchange extends Component {
    render() {
        return (
            <div className="main-panel animated">
                <CoinCharts />
                <Row className={'excheange mt-4'}>
                    <Col xs={12}>
                        <h1 className={'text-center text-light mb-4'}>
                            <span className={'txt-orange'}>Buy</span> And{' '}
                            <span className={'txt-orange'}>Sell</span>
                        </h1>
                    </Col>
                    <Col xs={5}>
                        <Row>
                            <Col xs={4} className={'pr-0'}>
                                <Form.Control
                                    className={'rounded-0'}
                                    type="text"
                                    placeholder=" "
                                />
                            </Col>
                            <Col xs={8} className={'pl-1'}>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="wallet"
                                        className={'bg-light text-light'}
                                        id="dropdown-basic"
                                    >
                                        <img
                                            alt="coin"
                                            src={Bitcoin}
                                            width={25}
                                        />{' '}
                                        My Wallet Address
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">
                                            Action
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                            Another action
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            Something else
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2}>
                        <h1 className={'text-light text-center'}>=</h1>
                        <Row>
                            <Col>
                                <Button
                                    size="sm"
                                    className={'bg-light border mt-4'}
                                    block
                                >
                                    Buy
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    size="sm"
                                    className={'bg-light border mt-4'}
                                    block
                                >
                                    Sell
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={5}>
                        <Row>
                            <Col xs={4} className={'pr-0'}>
                                <Form.Control
                                    className={'rounded-0'}
                                    type="text"
                                    placeholder=" "
                                />
                            </Col>
                            <Col xs={8} className={'pl-1'}>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="wallet"
                                        className={'bg-light text-light'}
                                        id="dropdown-basic"
                                    >
                                        <img
                                            alt="coin"
                                            src={Etherrum}
                                            width={25}
                                        />{' '}
                                        My Wallet Address
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">
                                            Action
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">
                                            Another action
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">
                                            Something else
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={'panel-rows mt-4'}>
                    <Col>
                        <h5 className={'no-margin'}>Recent Activities</h5>
                    </Col>
                </Row>
                <DataTable2 />
            </div>
        )
    }
}
