import React from "react";

import { Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function DataTable(props) {
  return (
    <Table striped className={"data-table table-responsive"}>
      <thead className={"txt-light"}>
        <tr>
          <th>T-ID</th>
          <th>User</th>
          <th>Amount</th>
          <th>Network Fee</th>
          <th>Xapa Commission</th>
          <th>Type</th>
          <th>Transaction Hash</th>
          <th>Time Stamp</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody className={"bg-light txt-light rounded"}>
        {props.data.map((item, index) => (
          <tr key={index}>
            <td>{item.id}</td>
            <td>
              {" "}
              {props.userProfile ? (
                <Link
                  className={"txt-orange"}
                  to={`/userprofile/${item.user.id}`}
                >
                  {item.user.email}
                </Link>
              ) : (
                item.user.email
              )}
            </td>
            <td>{item.formatedAmount}</td>
            <td>{item.formattedNetworkFee}</td>
            <td>
              {item.commission}
            </td>

            <td className={item.exchange?"txt-orange":""} title={item.exchange?"Exchange":"Transaction"} >{item.type}</td>

            <td>
              <a
                rel="noreferrer noopener"
                target={"_blank"}
                className={"txt-light"}
                href={item.explorerUrl}
              >
                {item.hash}
              </a>
            </td>
            <td>{item.created_at.replace("+00:00", "").replace("T", " ")}</td>

            <td>
              <Badge
                pill
                variant={
                  item.confirmations === 0
                    ? "danger"
                    : item.confirmations < item.maxConfirmation
                    ? "warning"
                    : "success"
                }
                className={"px-4 py-2"}
              >
                {item.status!=="failed"?item.confirmations === 0
                  ? "Pending"
                  : item.confirmations < item.maxConfirmation
                  ? "Confirmation: " +
                    item.confirmations +
                    "/" +
                    item.maxConfirmation
                  : item.confirmations >= item.maxConfirmation
                  ? "Confirmed"
                  : null:"Failed"}
              </Badge>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
