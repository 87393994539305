import React, { Component } from "react";
import Logo from "../../assets/png/logo.png";
import { LoginApi, TwoFaLoginApi } from "../../utils";
import { Alert, Spinner } from "react-bootstrap";
import SliderCaptcha from '@slider-captcha/react';
const host = process.env.REACT_APP_API_URL;
export default class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      redirect: false,
      err: false,
      message: "",
      twoFa: false,
      code: "",
      captcha_token:""
    };
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleEmail(event) {
    this.setState({ email: event.target.value, err: false });
  }
  handleCode = (event) => {
    this.setState({ code: event.target.value });
  };
  handlePassword(event) {
    this.setState({ password: event.target.value, err: false });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    LoginApi(this.state.email, this.state.password,this.state.captcha_token).then((res) => {
      if (res.token) {
        this.setState({ loading: false }, () => {
          if (res.data["2FaStatus"].googleAuthenticator) {
            this.setState({ twoFa: true });
          } else {
            this.props.getUserInfo();
          }
        });
      } else {
        this.setState({
          err: true,
          loading: false,
          message: res.error.message,
        });
      }
    });
  }
  handle2FaSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    TwoFaLoginApi(this.state.code).then((res) => {
      if (res.token) {
        this.setState({ loading: false }, () => {
          this.props.getUserInfo();
        });
      } else {
        this.setState({ err: true, loading: false, message: "invalid Code" });
      }
    });
  };
  render() {
    const { twoFa, code } = this.state;
    return (
      <div>
        {!twoFa ? (
          <form className={"adminlogin"} onSubmit={this.handleSubmit}>
            <div className={"login-logo text-center mt-3 mb-5"}>
              <img alt="logo" src={Logo} width={80} />
              <h2 className={"text-light"}>Admin Login</h2>
            </div>
            <div className="question">
              <input
                type="text"
                value={this.state.email}
                onChange={this.handleEmail}
                required="required"
              />
              <label>Username</label>
            </div>
            <div className="question">
              <input
                type="password"
                value={this.state.password}
                onChange={this.handlePassword}
                required="required"
              />
              <label>Password</label>
            </div>
            {this.state.captcha_token.length===0?<SliderCaptcha
                    variant="dark"
                    create={host+"/captcha/create"}
                    verify={host+"/captcha/verify"}
                  callback={(captcha_token)=>this.setState({captcha_token})}
               />:<button
              disabled={this.state.loading}
              className={"bg-light text-light l-btn"}
            >
              {this.state.loading ? (
                <Spinner size={"sm"} animation="border" variant="light" />
              ) : (
                "Login"
              )}
            </button>}
            {this.state.err ? (
              <Alert className={"mt-2"} variant="danger">
                {this.state.message}
              </Alert>
            ) : null}
          </form>
        ) : (
          <form className={"adminlogin"} onSubmit={this.handle2FaSubmit}>
            <div className={"login-logo text-center mt-3 mb-5"}>
              <img alt="logo" src={Logo} width={80} />
              <h2 className={"text-light"}> Google Authenticator</h2>
            </div>

            <div className="question">
              <input
                type="text"
                value={code}
                onChange={this.handleCode}
                required="required"
                maxLength={6}
              />
              <label>Enter 2Fa Code</label>
            </div>
            <button
              disabled={this.state.loading}
              className={"bg-light text-light l-btn"}
              onClick={this.handle2FaSubmit}
            >
              {this.state.loading ? (
                <Spinner size={"sm"} animation="border" variant="light" />
              ) : (
                "Verify"
              )}
            </button>
            {this.state.err ? (
              <Alert className={"mt-2"} variant="danger">
                {this.state.message}
              </Alert>
            ) : null}
          </form>
        )}
      </div>
    );
  }
}
