import React, { Component } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import { addTokenApi, coinsApi, editTokenApi } from "../../utils";
import Rodal from "rodal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";

class Tokens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokens: [],
      loading: true,
      tokenName: "",
      symbol: "",
      decimals: null,
      mainContractAddress: "",
      testContractAddress: "",
      maxConfirmation: "",
      minSpendable: "",
      blockchain: null,
      tokenName1: "",
      symbol1: "",
      decimals1: 1,
      mainContractAddress1: "",
      testContractAddress1: "",
      maxConfirmation1: "",
      minSpendable1: "",
      blockchain1: 2,
      coins: [],
      tokenId: 1,
      disabled: false,
      visible: false,
      visible1: false,
    };
  }
  componentDidMount() {
    this.getData();
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  addToken = () => {
    this.setState({ disabled: true });
    const {
      tokenName,
      blockchain,
      symbol,
      decimals,
      mainContractAddress,
      testContractAddress,
      maxConfirmation,
      minSpendable,
    } = this.state;
    addTokenApi({
      mainContract: mainContractAddress,
      testContract: testContractAddress,
      name: tokenName,
      symbol: symbol,
      decimals: decimals,
      blockchain: blockchain,
      maxConfirmation: maxConfirmation,
      minSpendable: minSpendable,
    }).then((r) => {
      this.setState({ disabled: false, visible: false }, () => {
        this.getData();
      });
    });
  };
  editTokenForm = (token) => {
    let blockchain1 = this.state.coins.find(
      ({ symbol }) => symbol === token.blockchain
    ).id;
    this.setState({
      tokenId: token.id,
      tokenName1: token.name,
      symbol1: token.symbol,
      decimals1: token.decimals,
      mainContractAddress1: token.mainContract,
      testContractAddress1: token.testContract,
      maxConfirmation1: token.maxConfirmation,
      minSpendable1: token.minSpendable,
      blockchain1,
      visible1: true,
    });
  };
  editToken = () => {
    const {
      tokenId,
      tokenName1,
      symbol1,
      decimals1,
      mainContractAddress1,
      testContractAddress1,
      maxConfirmation1,
      minSpendable1,
      blockchain1,
    } = this.state;
    let data = {
      id: tokenId,
      name: tokenName1,
      symbol: symbol1,
      decimals: decimals1,
      mainContract: mainContractAddress1,
      testContract: testContractAddress1,
      maxConfirmation: maxConfirmation1,
      minSpendable: minSpendable1,
      blockchain: blockchain1,
    };
    editTokenApi(data).then((res) => {
      this.setState({ visible1: false }, () => this.getData());
    });
  };
  getData = () => {
    coinsApi("token").then((tokens) => {
      this.setState({ tokens, loading: false }, () => {
        coinsApi().then((coins) => {
          this.setState({ coins });
        });
      });
    });
  };
  render() {
    const {
      loading,
      tokens,
      tokenName,
      coins,
      blockchain,
      symbol,
      decimals,
      mainContractAddress,
      testContractAddress,
      maxConfirmation,
      minSpendable,
      tokenName1,
      symbol1,
      decimals1,
      blockchain1,
      mainContractAddress1,
      testContractAddress1,
      maxConfirmation1,
      minSpendable1,
      disabled,
    } = this.state;
    return (
      <Container fluid>
        <Rodal
          width={800}
          duration={500}
          animation={"door"}
          visible={this.state.visible}
          onClose={() => {
            this.setState({ visible: false, blockchain: null });
          }}
        >
          {blockchain !== null ? (
            <Container className={"bg-dark p-4"} fluid>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label className={"text-light"}>Token Name :</Form.Label>
                  <Col>
                    <Form.Control
                      value={tokenName}
                      onChange={this.handleChange}
                      name={"tokenName"}
                      type={"text"}
                      placeholder=" "
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className={"text-light"}>Symbol :</Form.Label>
                  <Col>
                    <Form.Control
                      value={symbol}
                      name={"symbol"}
                      onChange={this.handleChange}
                      type={"text"}
                      placeholder=" "
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className={"text-light"}>Decimals :</Form.Label>
                  <Col>
                    <Form.Control
                      value={decimals}
                      onChange={this.handleChange}
                      type={"number"}
                      name={"decimals"}
                      placeholder=" "
                    />
                  </Col>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label className={"text-light"}>
                    Main Contract Address :
                  </Form.Label>
                  <Col>
                    <Form.Control
                      value={mainContractAddress}
                      onChange={this.handleChange}
                      name={"mainContractAddress"}
                      type={"text"}
                      placeholder=" "
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className={"text-light"}>
                    Test Contract Address :
                  </Form.Label>
                  <Col>
                    <Form.Control
                      value={testContractAddress}
                      name={"testContractAddress"}
                      onChange={this.handleChange}
                      type={"text"}
                      placeholder=" "
                    />
                  </Col>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label className={"text-light"}>
                    Max Confirmation :
                  </Form.Label>
                  <Col>
                    <Form.Control
                      value={maxConfirmation}
                      name={"maxConfirmation"}
                      onChange={this.handleChange}
                      type={"text"}
                      placeholder=" "
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className={"text-light"}>
                    Min Spendable :
                  </Form.Label>
                  <Col>
                    <Form.Control
                      value={minSpendable}
                      name={"minSpendable"}
                      onChange={this.handleChange}
                      type={"text"}
                      placeholder=" "
                    />
                  </Col>
                </Form.Group>
              </Row>
              <Row>
                <Col className={"text-center"}>
                  <Button
                    onClick={this.addToken}
                    className={"bg-light border mb-4 btn-shaped px-5"}
                    disabled={
                      disabled ||
                      symbol.length < 3 ||
                      decimals < 1 ||
                      mainContractAddress.length < 10 ||
                      testContractAddress.length < 10 ||
                      maxConfirmation < 1
                    }
                  >
                    Add Token
                  </Button>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container className={"bg-dark p-4"} fluid>
              <Row>
                {coins.map((item, index) => (
                  <Col sm={4} key={index}>
                    <Button
                      onClick={() => {
                        this.setState({ blockchain: item.id });
                      }}
                      variant="dark"
                      block
                      className={"bg-dark-2 my-2"}
                    >
                      <img src={item.icon} alt={item.name} /> {item.name}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Container>
          )}
        </Rodal>
        <Rodal
          width={800}
          duration={500}
          animation={"door"}
          visible={this.state.visible1}
          onClose={() => {
            this.setState({ visible1: false });
          }}
        >
          <Container className={"bg-dark p-4"} fluid>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className={"text-light"}>Token Name :</Form.Label>
                <Col>
                  <Form.Control
                    value={tokenName1}
                    onChange={this.handleChange}
                    name={"tokenName1"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={"text-light"}>Symbol :</Form.Label>
                <Col>
                  <Form.Control
                    value={symbol1}
                    name={"symbol1"}
                    onChange={this.handleChange}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className={"text-light"}>Decimals :</Form.Label>
                <Col>
                  <Form.Control
                    value={decimals1}
                    onChange={this.handleChange}
                    type={"number"}
                    name={"decimals1"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={"text-light"}>Blockchain :</Form.Label>

                <Dropdown disabled={false} className={"px-3"}>
                  <Dropdown.Toggle
                    variant="wallet"
                    className={"bg-white "}
                    id="dropdown-type"
                  >
                    {coins.length > 0 ? (
                      <>
                        <img
                          width={20}
                          className={"mr-1"}
                          src={coins[blockchain1 - 1].icon}
                          alt={"coin"}
                        />{" "}
                        {coins[blockchain1 - 1].name}
                      </>
                    ) : null}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ minWidth: 130, width: 140 }}>
                    {coins.length > 0
                      ? coins.map((coin, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              this.setState({
                                blockchain1: coin.id,
                              })
                            }
                          >
                            <img
                              width={20}
                              className={"mr-1"}
                              src={coin.icon}
                              alt={"coin"}
                            />
                            {coin.name}
                          </Dropdown.Item>
                        ))
                      : null}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className={"text-light"}>
                  Main Contract Address :
                </Form.Label>
                <Col>
                  <Form.Control
                    value={mainContractAddress1}
                    onChange={this.handleChange}
                    name={"mainContractAddress1"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={"text-light"}>
                  Test Contract Address :
                </Form.Label>
                <Col>
                  <Form.Control
                    value={testContractAddress1}
                    name={"testContractAddress1"}
                    onChange={this.handleChange}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className={"text-light"}>
                  Max Confirmation :
                </Form.Label>
                <Col>
                  <Form.Control
                    value={maxConfirmation1}
                    name={"maxConfirmation1"}
                    onChange={this.handleChange}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className={"text-light"}>
                  Min Spendable :
                </Form.Label>
                <Col>
                  <Form.Control
                    value={minSpendable1}
                    name={"minSpendable1"}
                    onChange={this.handleChange}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Col className={"text-center"}>
                <Button
                  onClick={this.editToken}
                  disabled={
                    tokenName1.length < 4 ||
                    symbol1.length < 3 ||
                    decimals1.length < 1 ||
                    mainContractAddress1.length < 10 ||
                    testContractAddress1.length < 10 ||
                    maxConfirmation1.length < 1
                  }
                  className={"bg-light border mb-4 btn-shaped px-5"}
                >
                  Modify Token
                </Button>
              </Col>
            </Row>
          </Container>
        </Rodal>
        <Row className={"text-right"}>
          <Button
            onClick={() => {
              this.setState({ visible: true });
            }}
            size="sm"
            className={"bg-light border mt-4 btn-shaped px-2"}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Row>
        <Row>
          <Table
            className={"data-table  table-responsive-xl"}
            responsive={true}
            striped
            hover
          >
            <thead className={"txt-light"}>
              <tr>
                <th>ID</th>
                <th>Icon</th>
                <th>Name</th>
                <th>Symbol</th>
                <th>Blockchain</th>
                <th>Max Confirmation</th>
                <th>Main Contract</th>
                <th>Decimals</th>
                <th>Test Contract</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody className={"bg-light txt-light rounded"}>
              {!loading
                ? tokens.map((token, index) => (
                    <tr key={index}>
                      <td>{token.id}</td>
                      <td>
                        <img width={30} src={token.icon} alt={"icon"} />
                      </td>
                      <td>{token.name}</td>
                      <td>{token.symbol}</td>
                      <td>{token.blockchain}</td>
                      <td>{token.maxConfirmation}</td>
                      <td>{token.mainContract}</td>
                      <td>{token.decimals}</td>
                      <td>{token.testContract}</td>
                      <td>
                        {" "}
                        <Button
                          className={"border-0 p-0 px-1"}
                          variant="outline-light"
                          onClick={() => {
                            this.editTokenForm(token);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Row>
      </Container>
    );
  }
}

export default Tokens;
