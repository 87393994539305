import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "./style.css";
import "react-toastify/dist/ReactToastify.css";
import "rodal/lib/rodal.css";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from "./pages/login";
import Home from "./pages/dashboard";
import Exchange from "./pages/exchange";
import MyWallet from "./pages/myWallet";
import History from "./pages/history";
import Security from "./pages/security";
import Members from "./pages/members";
import Roles from "./pages/roles";
import PublicRoute from "./components/publicRoute";
import PrivateRoute from "./components/privateRoute";
import AdminLogin from "./pages/login/adminLogin";
import UserManagement from "./pages/members/userManagement";
import { userApi } from "./utils";
import Loading from "./components/loading";
import ChangeLog from "./pages/changeLog";
import Promotion from "./pages/promation";
import UserProfile from "./pages/userProfile";
import XapaReport from "./pages/reports/xapaReport";
import BonusReport from "./pages/reports/BonusReport";
import ExchangeReport from "./pages/reports/ExchangeReport";
import Tokens from "./pages/tokens";
import BlackList from "./pages/blackList";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      auth: false,
      loading: true,
    };
    this.getUserInfo = this.getUserInfo.bind(this);
  }
  componentDidMount() {
    this.getUserInfo();
  }
  getUserInfo() {
    userApi().then((res) => {
      !res
        ? this.setState({ auth: false, loading: false })
        : this.setState({ user: res, auth: true, loading: false });
    });
  }
  render() {
    const { user, auth, loading } = this.state;
    if (loading) {
      return <Loading />;
    } else {
      return (
        <Router>
          <Switch>
            <PublicRoute
              auth={auth}
              user={user}
              restricted={true}
              getUserInfo={this.getUserInfo}
              component={AdminLogin}
              path="/admin/login"
              exact
            />
            <PublicRoute
              auth={auth}
              user={user}
              restricted={true}
              getUserInfo={this.getUserInfo}
              component={Login}
              path="/login"
              exact
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={Home}
              path="/"
              exact
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={Exchange}
              path="/exchange"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={MyWallet}
              path="/mywallet"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={History}
              path="/history"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={Security}
              path="/security"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={Members}
              path="/members"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={Roles}
              path="/roles"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={UserManagement}
              path="/usermanagement"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={ChangeLog}
              path="/changelog"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={Promotion}
              path="/promotion"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={UserProfile}
              path="/userprofile/:id"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={XapaReport}
              path="/xapareport"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={BonusReport}
              path="/bonusreport"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={ExchangeReport}
              path="/exchangereport"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={BlackList}
              path="/blacklist"
            />
            <PrivateRoute
              auth={auth}
              user={user}
              component={Tokens}
              path="/tokens"
            />
          </Switch>
        </Router>
      );
    }
  }
}
