import React, { Component } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  userListApi,
  userEditApi,
  rolesListApi,
  removeAdminRoleApi,
  setRoleApi,
} from "../../utils";
import {
  Row,
  Col,
  Container,
  Table,
  InputGroup,
  FormControl,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import Rodal from "rodal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserListItem from "./userlistItem";

export default class UserListTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visible2: false,
      page: 1,
      maxPerPage: 25,
      q: "",
      setRole: "",
      totalPages: 1,
      totalItems: 1,
      users: [],
      userRoles: [],
      selectedUsers: [],
      allUsersId: [],
      removeLoading: false,
      modifyUser: {},
      modifyLoading: false,
      loading: true,
      err: false,
      setRoleLoading: false,
      msg: {
        message: "",
        errors: [
          {
            message: "",
          },
        ],
      },
    };
    this.handleMaxPerPageChange = this.handleMaxPerPageChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
    this.selectAllUsers = this.selectAllUsers.bind(this);
    this.unSelectAllUsers = this.unSelectAllUsers.bind(this);
    this.rodalHandler = this.rodalHandler.bind(this);
    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEditUser = this.handleEditUser.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.removeAdminRole = this.removeAdminRole.bind(this);
    this.handleSetRoleChange = this.handleSetRoleChange.bind(this);
    this.setRole = this.setRole.bind(this);
    this.handleGoogleChange = this.handleGoogleChange.bind(this);
    this.handleSMS2FaChange = this.handleSMS2FaChange.bind(this);
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.getData();
  }

  handleSelectedChange(selectedUsers) {
    this.setState({
      selectedUsers,
    });
  }
  getData() {
    this.setState({ loading: true, selectedUsers: [] });
    const { page, maxPerPage, q } = this.state;
    let allUsersId = [];
    userListApi(
      page,
      maxPerPage,
      !this.props.user ? "ROLE_ADMIN" : "a:0:{}",
      q
    ).then((res) => {
      for (let i = 0; i < res.users.length; i++) {
        allUsersId.push(res.users[i].id.toString());
      }
      this.setState({
        allUsersId,
        totalItems: res.total_items,
        totalPages: res.total_pages,
        users: res.users,
      });
    });
    rolesListApi(1, 100).then((res) => {
      this.setState({ loading: false, userRoles: res.roles });
    });
  }
  rodalHandler(user) {
    this.setState({ visible: true, modifyUser: user });
  }

  handleMaxPerPageChange(event) {
    this.setState({ maxPerPage: event.target.value, page: 1 }, () => {
      this.getData();
    });
  }
  handlePageChange(event) {
    this.setState({ page: event.target.value }, () => {
      this.getData();
    });
  }
  handleSearchChange(event) {
    this.setState({ q: event.target.value }, () => {
      this.getData();
    });
  }
  handleFullNameChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let modifyUser = Object.assign({}, prevState.modifyUser);
      modifyUser.full_name = value;
      return { modifyUser };
    });
  }
  handleEmailChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let modifyUser = Object.assign({}, prevState.modifyUser);
      modifyUser.email = value;
      return { modifyUser };
    });
  }
  handleStatusChange(event) {
    const { value } = event.target;

    this.setState((prevState) => {
      let modifyUser = Object.assign({}, prevState.modifyUser);
      modifyUser.enabled = value;
      return { modifyUser };
    });
  }
  handleRoleChange(event) {
    const { value } = event.target;

    this.setState((prevState) => {
      let modifyUser = Object.assign({}, prevState.modifyUser);
      modifyUser.userRoles = [value];
      return { modifyUser };
    });
  }
  handlePasswordChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let modifyUser = Object.assign({}, prevState.modifyUser);
      modifyUser.password = value;
      return { modifyUser };
    });
  }
  selectAllUsers() {
    this.refs.select.selectAllUsers(this.state.allUsersId);
    this.setState({ selectedUsers: this.state.allUsersId });
  }
  unSelectAllUsers() {
    this.refs.select.unSelectAllUsers();
    this.setState({ selectedUsers: [] });
  }
  removeAdminRole() {
    this.setState({ removeLoading: true });
    removeAdminRoleApi(this.state.selectedUsers).then((res) => {
      this.setState({ removeLoading: false });
      this.getData();
    });
  }
  handleEditUser() {
    this.setState({ modifyLoading: true });
    userEditApi(this.state.modifyUser).then((res) => {
      res.code !== 400
        ? this.setState({
            msg: {
              message: "User Has Been Edited",
              errors: [{ message: "" }],
            },
            modifyLoading: false,
          })
        : this.setState({ msg: res, modifyLoading: false });
      this.getData();
    });
  }
  pagination = () => {
    const { totalPages } = this.state;
    let pagination = [];
    for (let i = 1; i <= totalPages; i++) {
      pagination.push(
        <option value={i}>
          Page {i} of {totalPages}
        </option>
      );
    }
    return pagination;
  };
  handleSetRoleChange(event) {
    this.setState({ setRole: event.target.value });
  }
  setRole() {
    this.setState({ setRoleLoading: true });
    setRoleApi(this.state.selectedUsers, this.state.setRole).then((res) => {
      this.setState({ setRoleLoading: false }, () => {
        this.getData();
      });
    });
  }
  handleGoogleChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let modifyUser = Object.assign({}, prevState.modifyUser);
      modifyUser.isGoogleAuthenticatorEnabled = value;
      return { modifyUser };
    });
  }
  handleSMS2FaChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      let modifyUser = Object.assign({}, prevState.modifyUser);
      modifyUser.isSMS2FaEnabled = value;
      return { modifyUser };
    });
  }
  render() {
    const {
      page,
      maxPerPage,
      q,
      totalItems,
      visible2,
      users,
      loading,
      selectedUsers,
      modifyUser,
      userRoles,
      visible,
      modifyLoading,
      removeLoading,
      setRoleLoading,
    } = this.state;
    const { user, edit, removeRoleAdmin, addRole } = this.props;
    return (
      <div>
        <Rodal
          duration={500}
          animation={"door"}
          visible={visible}
          onClose={() => {
            this.setState({
              visible: false,
              msg: { message: "", errors: [{ message: "" }] },
            });
          }}
        >
          <Container className={"bg-dark p-4"}>
            <h6
              className={"text-light mb-0 border-bottom border-secondary pb-1"}
            >
              User Information
            </h6>
            <Form autocomplete="off" className={"mt-3 mb-0"}>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  Owner's Display Name :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    value={modifyUser.full_name}
                    onChange={this.handleFullNameChange}
                    className={"rounded-0"}
                    type={"text"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Owner's Email :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    value={modifyUser.email}
                    onChange={this.handleEmailChange}
                    className={"rounded-0"}
                    type={"email"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  Password :
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    autocomplete="new-password"
                    value={modifyUser.password}
                    onChange={this.handlePasswordChange}
                    className={"rounded-0"}
                    type={"password"}
                    placeholder=" "
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Status :
                </Form.Label>
                <Col sm="3">
                  <Form.Control
                    onChange={this.handleStatusChange}
                    size="sm"
                    as="select"
                  >
                    <option
                      selected={modifyUser.enabled ? "selected" : null}
                      value={1}
                    >
                      Active
                    </option>
                    <option
                      selected={!modifyUser.enabled ? "selected" : null}
                      value={0}
                    >
                      Inactive
                    </option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * Google Authenticator :
                </Form.Label>
                <Col sm="3">
                  <Form.Control
                    disabled={!modifyUser.isGoogleAuthenticatorEnabled}
                    onChange={this.handleGoogleChange}
                    size="sm"
                    as="select"
                  >
                    <option
                      selected={
                        modifyUser.isGoogleAuthenticatorEnabled
                          ? "selected"
                          : null
                      }
                      value={1}
                    >
                      Active
                    </option>
                    <option
                      selected={
                        !modifyUser.isGoogleAuthenticatorEnabled
                          ? "selected"
                          : null
                      }
                      value={0}
                    >
                      Inactive
                    </option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="4" className={"text-light"}>
                  * SMS2Fa :
                </Form.Label>
                <Col sm="3">
                  <Form.Control
                    disabled={!modifyUser.isSMS2FaEnabled}
                    onChange={this.handleSMS2FaChange}
                    size="sm"
                    as="select"
                  >
                    <option
                      selected={modifyUser.isSMS2FaEnabled ? "selected" : null}
                      value={1}
                    >
                      Active
                    </option>
                    <option
                      selected={!modifyUser.isSMS2FaEnabled ? "selected" : null}
                      value={0}
                    >
                      Inactive
                    </option>
                  </Form.Control>
                </Col>
              </Form.Group>
            </Form>
            <Row>
              <Col xs={12} className={"text-right"}>
                <Button
                  onClick={() => {
                    this.setState({
                      visible: false,
                      msg: { message: "", errors: [{ message: "" }] },
                    });
                  }}
                  size="sm"
                  className={
                    "bg-light border mb-4 d-sm-none mr-5 btn-shaped px-3"
                  }
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  className={"bg-light border mb-4 btn-shaped px-5"}
                  onClick={this.handleEditUser}
                >
                  {modifyLoading ? (
                    <Spinner size={"sm"} animation="border" variant="light" />
                  ) : (
                    "Modify"
                  )}
                </Button>
              </Col>
              <div>
                <span className={"pl-3 txt-orange"}>
                  {this.state.msg.message}
                </span>
                <h6 style={{ fontSize: ".8em" }} className={"pl-3 txt-orange"}>
                  {this.state.msg.errors[0].message}
                </h6>
              </div>
            </Row>
          </Container>
        </Rodal>
        <Rodal
          width={250}
          duration={500}
          animation={"door"}
          visible={visible2}
          onClose={() => {
            this.setState({ visible2: false });
          }}
        >
          <div className={"bg-dark p-5"}>
            {visible2 ? (
              <Form.Control
                onChange={this.handleSetRoleChange}
                size="sm"
                as="select"
              >
                <option>Select Role</option>
                {userRoles.map((role, index) => (
                  <option key={index} value={role.id}>
                    {role.role}
                  </option>
                ))}
              </Form.Control>
            ) : null}
            <Button
              onClick={this.setRole}
              size="sm"
              className={"bg-light border btn-shaped px-3 btn-block mt-4"}
            >
              {setRoleLoading ? (
                <Spinner size={"sm"} animation="border" variant="light" />
              ) : (
                "Set Role"
              )}
            </Button>
          </div>
        </Rodal>

        <Row className={"table-head-bar"}>
          {!user ? (
            <Col lg={3} xs={12}>
              <Button
                className={"border-0 p-0 px-1 text-light"}
                onClick={this.selectAllUsers}
                variant="outline-light"
              >
                Select All
              </Button>
              <span className={"text-light"}> | </span>
              <Button
                onClick={this.unSelectAllUsers}
                className={"border-0 p-0 px-1 text-light"}
                variant="outline-light"
              >
                Unselect All
              </Button>
              <span className={"text-light"}> | </span>
              <span className={"p-0 px-1 text-light"}>
                {selectedUsers.length} Selected
              </span>
            </Col>
          ) : (
            <Col lg={3} xs={12} />
          )}
          <Col lg={9} xs={12}>
            <Row>
              <Col xs={7} lg={3} className={"text-right mt-2"}>
                <span className={"p-0 px-1 text-light"}>
                  {totalItems} Records Total
                </span>
                <span className={"text-light ml-1 d-none d-sm-inline mt-2"}>
                  {" "}
                  |{" "}
                </span>
              </Col>
              <Col lg={3} xs={6} className={"px-1 mt-2 "}>
                <Form.Control
                  value={maxPerPage}
                  onChange={this.handleMaxPerPageChange}
                  size="sm"
                  as="select"
                >
                  <option value={25}>View 25 Per Page</option>
                  <option value={50}>View 50 Per Page</option>
                  <option value={100}>View 100 Per Page</option>
                </Form.Control>
                <span className={"text-light ml-2 d-none d-sm-inline mt-2 "}>
                  {" "}
                  |{" "}
                </span>
              </Col>
              <Col lg={3} xs={6} className={"px-1 mt-2 "}>
                <Form.Control
                  value={page}
                  onChange={this.handlePageChange}
                  size="sm"
                  as="select"
                >
                  {this.pagination()}
                </Form.Control>
                <span className={"text-light ml-2 d-none d-sm-inline mt-2 "}>
                  {" "}
                  |{" "}
                </span>
              </Col>
              <Col lg={3} xs={12} className={"pl-1 mt-2 "}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className={"p-0 px-2"} id="basic-addon1">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    placeholder="Search"
                    className={"rounded-1"}
                    value={q}
                    onChange={this.handleSearchChange}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        {!loading ? (
          <Table striped className={"data-table table-responsive-xl mt-2"}>
            <thead className={"text-light"}>
              <tr>
                <th> </th>
                <th>Full Name</th>
                <th>Email</th>
                {!user ? <th>Roles</th> : null}
                <th>Email Verified</th>
                <th>2FA</th>
                <th>Status</th>
                <th>Last Login</th>
                {edit ? <th> </th> : null}
              </tr>
            </thead>
            <tbody className={"bg-light txt-light rounded"}>
              <UserListItem
                ref={"select"}
                edit={edit}
                isUser={this.props.user}
                userRoles={userRoles}
                rodalHandler={this.rodalHandler}
                handleSelectedChange={this.handleSelectedChange}
                users={users}
              />
            </tbody>
          </Table>
        ) : (
          <Row className={"t-center"}>
            {" "}
            <Spinner className={"align"} animation="border" variant="light" />
          </Row>
        )}

        {!user && removeRoleAdmin ? (
          <Button
            disabled={!selectedUsers.length}
            onClick={this.removeAdminRole}
            size="sm"
            className={"bg-light border mb-4 btn-shaped px-3"}
          >
            {removeLoading ? (
              <Spinner size={"sm"} animation="border" variant="light" />
            ) : (
              "Remove Selected"
            )}
          </Button>
        ) : null}
        {!user && addRole ? (
          <Button
            disabled={!selectedUsers.length}
            onClick={() => this.setState({ visible2: true })}
            size="sm"
            className={"bg-light border ml-2 mb-4 btn-shaped px-3"}
          >
            Modify Users Role
          </Button>
        ) : null}
      </div>
    );
  }
}
