import React, { Component } from "react";
import { totalUserRegisterApi } from "../../utils";
import { Spinner } from "react-bootstrap";
import BarChart from "../../components/barChart";
export default class TotalUserRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalUserRegisterCount: 0,
      totalUserRegisterData: [],
      total: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 10000);
  }
  getData() {
    totalUserRegisterApi(false).then((res) => {
      let totalUserRegisterData = [];
      let totalUserRegisterCount = 0;
      for (let i = 0; i < 7; i++) {
        totalUserRegisterData.push({
          x: i,
          y: Number(res.total[i].count),
        });
        totalUserRegisterCount =
          totalUserRegisterCount + Number(res.total[i].count);
      }
      this.setState({
        totalUserRegisterData,
        totalUserRegisterCount,
        total: res.total,
        loading: false,
      });
    });
  }
  render() {
    const {
      total,
      totalUserRegisterCount,
      totalUserRegisterData,
      loading,
    } = this.state;
    if (loading)
      return (
        <div
          style={{
            height: 150,
            width: "33%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Spinner animation="grow" variant="light" />
        </div>
      );
    return (
      <BarChart
        title={"Total Registration Users"}
        days={total}
        count={totalUserRegisterCount}
        data={totalUserRegisterData}
      />
    );
  }
}
