import { Table } from "react-bootstrap";
import React from "react";

import WalletAddress from "./walletAddress";
const DataTableComponent = (props) => {
  return (
    <div className={"d-flex justify-content-center"}>
      <Table
        style={{ width: "auto" }}
        className={"data-table  table-responsive"}
        striped
        hover
      >
        <thead className={"txt-light"}>
          <tr>
            {props.headers.map((item, index) => (
              <th key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className={"bg-light txt-light rounded"}>
          {props.data.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.formatedBalance}</td>
              <td>{item.coinType.name}</td>
              <td>
                <WalletAddress walletAddresses={item.walletAddresses} />
              </td>
              <td>{item.createdAt.replace("+00:00", "").replace("T", " ")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DataTableComponent;
